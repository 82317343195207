import styled from "styled-components";
import { colors, FLEX, FONT } from "../../common";

export const Container = styled.div``;

export const Header = styled.div`
  ${FLEX({justify: 'space-between'})}
`;

export const TitleBlock = styled.div`
  display: flex;
  align-items: center;
`;
export const FlexStyledContainer = styled.div`

  display: flex;
  justify-content: space-between;
`;

export const Image = styled.img`
  cursor: pointer;
`;

export const ButtonBlock = styled.div`
  margin-left: 30px;
`;

export const Title = styled.h1`
  ${FONT({weight: '700', size: '24px', color: colors.black})};

  margin-left: 14px;
`;

export const DateBlock = styled.div`
  display: flex;
  justify-content: space-between;

  margin-left: 60px;

  ${FONT({weight: '400', size: '12px', color: colors.gray})};
`;


export const MainContainer = styled.main`
  max-width: 1609px;
  height: 925px;
  
  display: flex;

  margin-top: 26px;
	
	background: white;
	
  box-shadow: 0 14px 28px rgba(135, 135, 135, 0.16);
  overflow-y: auto;
	
  border-radius: 22px;
`;

export const PermissionWrapper = styled.div`
  margin-top: 30px;
  padding-left: 120px;
  width: 800px;
  height: 400px;
`;

export const PermissionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const PermissionBlock = styled.div`
  margin-top: 28px;
  width: 300px;
  display: flex;
  align-items: center;
`;

export const Name = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${colors.black};
  margin-left: 12px;
`;

export const PermissionTitle = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #222222;
`;