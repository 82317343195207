import { call, put, takeLatest } from 'redux-saga/effects'
import { TDataWrapper } from '../types'
import { categoryActions } from './actions'
import { ApiCategoryService } from './api.service'
import {
   TCreateCategoryPayload,
   TEditCategoryPayload,
   TGetCategoriesPayload,
   TGetCategoryBySectionPayload,
   TGetDescriptionPayload,
   TRemoveCategoriesPayload
} from './types'

import { TResponse } from '../types'
import { CATEGORY_RESPONSE } from './consts'
import { getAccessToken } from '../user'
import { showToastAction } from "../toasts";
import { generateErrorToast, generateSuccessToast } from "../../common";
import { i18n } from "../../common/config";

function* getCategoriesWorker({ payload }: TDataWrapper<TGetCategoriesPayload>) {
   yield put(categoryActions.setCategoriesState({ loading: true }))

   const token: string = yield call(getAccessToken)

   try {
      const response: TResponse = yield call([ApiCategoryService, ApiCategoryService.getCategories], {
         ...payload,
         token
      })
      if (response.data.data) yield put(categoryActions.setCategories(response.data))
   } catch (e) {
      yield put(showToastAction.request(generateErrorToast(i18n.t("error.getting.categories") as string)));
   }

   yield put(categoryActions.setCategoriesState({ loading: false }))
}

function* getDescriptionWorker({payload}: TDataWrapper<TGetDescriptionPayload>) {
   yield put(categoryActions.setCategoriesState({loading: true}))

   const {_id} = payload
   const token: string = yield call(getAccessToken)

   try {
      const response: TResponse = yield call([ApiCategoryService, ApiCategoryService.getDescription], {_id, token})
      if (response.data) {
         yield put(categoryActions.setDescriptionCategory(response.data))
         yield put(categoryActions.setCategoriesState({loading: false}))
      } else {
         yield put(categoryActions.setCategoriesState({loading: false }))
      }
   } catch (e) {
      yield put(categoryActions.setCategoriesState({loading: false }))
   }
}

function* removeCategoriesWorker({ payload }: TDataWrapper<TRemoveCategoriesPayload>) {
   yield put(categoryActions.setCategoriesState({ loading: true }))
   const token: string = yield call(getAccessToken)

   const { _ids } = payload

   try {
      for (let _id of _ids) {
         yield call([ApiCategoryService, ApiCategoryService.removeCategory], { token, _id })
      }
      yield put(showToastAction.request(generateSuccessToast(i18n.t("category.removed.successfully") as string)));
   } catch (e) {
      yield put(showToastAction.request(generateErrorToast(i18n.t("failed.to.remove.category") as string)));
   }

   yield put(categoryActions.setCategoriesState({ loading: false, response: CATEGORY_RESPONSE.REMOVED }))
}

function* createCategoryWorker({ payload }: TDataWrapper<TCreateCategoryPayload>) {
   yield put(categoryActions.setCategoriesState({ loading: true }))
   const token: string = yield call(getAccessToken)
   const { data } = payload

   try {
      yield call([ApiCategoryService, ApiCategoryService.createCategory], { token, data })
      yield put(
        showToastAction.request(
          generateSuccessToast(i18n.t("category.was.created.successfully") as string),
        ),
      )
   } catch (e) {
      yield put(
        showToastAction.request(
          generateErrorToast(i18n.t("category.creating.error") as string),
        ),
      )
   }

   yield put(categoryActions.setCategoriesState({ loading: false, response: CATEGORY_RESPONSE.CREATED }))
}

function* editCategoryWorker({ payload }: TDataWrapper<TEditCategoryPayload>) {
   yield put(categoryActions.setCategoriesState({ loading: true }))
   const token: string = yield call(getAccessToken)

   const { _id, data } = payload
   try {
      yield call([ApiCategoryService, ApiCategoryService.editCategory], { token, _id, data })
      yield put(showToastAction.request(generateSuccessToast(i18n.t("category.updated.successfully") as string)));
   } catch (e) {
      yield put(showToastAction.request(generateErrorToast(i18n.t("failed.to.update.category") as string)));
   }

   yield put(categoryActions.setCategoriesState({ loading: false, response: CATEGORY_RESPONSE.EDITED }))
}

function* getCategoryBySectionWorker({ payload }: TDataWrapper<TGetCategoryBySectionPayload>) {
   yield put(categoryActions.setCategoriesState({ loading: true }))

   const { _id } = payload
   const token: string = yield call(getAccessToken)

   try {
      const response: TResponse = yield call([ApiCategoryService, ApiCategoryService.getCategoryBySection], { _id, token })
      if (response.data.data) yield put(categoryActions.setCategoryBySection(response.data))
   } catch (e) {
      yield put(categoryActions.setCategoryBySection({ data: [] }))
   }
}

export function* categoryWatcher() {
   yield takeLatest(categoryActions.getCategories, getCategoriesWorker)
   yield takeLatest(categoryActions.removeCategories, removeCategoriesWorker)
   yield takeLatest(categoryActions.createCategory, createCategoryWorker)
   yield takeLatest(categoryActions.editCategory, editCategoryWorker)
   yield takeLatest(categoryActions.getCategoryBySection, getCategoryBySectionWorker)
   yield takeLatest(categoryActions.getDescriptionCategory, getDescriptionWorker)
}
