import React, {
  FC,
  SyntheticEvent,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import moment from "moment";
import {
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom';

import {
  Assets,
  FlexContainer,
  HoverList,
  Pagination,
  SubPagesBar,
  Table,
  TSort,
  ButtonNew,
  useTypedSelector
} from "../../../common";
import {
  CLIENT_RESPONSE,
  clientActions,
  getMainClientSelector,
  orderActions,
  getMainOrderSelector
} from "../../../store";
import {
  Container,
  Header,
  Title,
  TitleBlock,
  Image,
  DateBlock,
  DateText,
  MainContainer,
  FlexStyledContainer
} from './styled';
import { ITEM_PER_PAGE } from './consts';
import {getLocale} from "../../../types";

const OrderHistory: FC = () => {
   const { t } = useTranslation()
   const dispatch = useDispatch()
   const navigate = useNavigate()
   const local = getLocale()

   const { orders } = useTypedSelector(getMainOrderSelector)
   const { client, response } = useTypedSelector(getMainClientSelector)

   const { id } = useParams()
   const [searchParams, setSearchParams] = useSearchParams({})

   const [sortParamsForm, setSortParamsForm] = useState({
      sortBy: '',
      order: '' as TSort
   })

   const tableSortBy = useMemo(() => {{
         if (!sortParamsForm.order) {
            return { id: null, desc: false }
         }
         return { id: sortParamsForm.sortBy, desc: sortParamsForm.order > 0 }}
   }, [sortParamsForm])

   const [page, setPage] = useState<number>(0)

   const Requests = {
      getClient: () => {
         dispatch(clientActions.getClient({ _id: id as string }))
      },
      getOrders: () => {
         dispatch(
            orderActions.getOrders({
               value: id as string,
               field: 'customer',
               page,
               lang: local,
               limit: ITEM_PER_PAGE,
               ...sortParamsForm
            })
         )
      }
   }

   const Events = {
      backButtonClickHandler: () => {
         navigate('../../customers')
      },
      onPageChangeHandler: ({ selected }: { selected: number }) => {
         setPage(selected)
      },
      sortToggleHandler: (sortBy: string, order: TSort) => {
         setSortParamsForm({ sortBy, order })
      },
      editClickHandler: (e: SyntheticEvent, index: number) => {
         navigate(`../orders/profile/${orders.data[index]._id}`)
      }
   }

   const data = useMemo(
      () =>
         orders.data.map((order) => {
            return {
               id: order.id,
               createdAt: moment(order.createdAt).format("DD.MM.YYYY HH:mm"),
               order: (
                  <FlexContainer align="center" justify="center">
                     <FlexContainer direction="column" align="center" gap="4px">
                        <p>{order.items[0]?.product?.description?.title}</p>
                        {!!order.items.slice(1).length && (
                           <HoverList items={order.items.slice(1).map((item) => item.product?.description?.title)} />
                        )}
                     </FlexContainer>
                  </FlexContainer>
               ),
               paymentStatus: t(order.paymentStatus),
               orderStatus: t(order.orderStatus)
            }
         }),
      [orders]
   )

   const columns = useMemo(
      () => [
         {
            Header: t('id'),
            accessor: 'id', // accessor is the "key" in the data
            width: 250,
            sortToggleHandler: Events.sortToggleHandler
         },
         {
            Header: t('date'),
            accessor: 'createdAt',
            width: 250,
            sortToggleHandler: Events.sortToggleHandler
         },
         {
            Header: t('order'),
            accessor: 'order',
            width: 250
         },
         {
            Header: t('payment'),
            accessor: 'paymentStatus',
            width: 350,
            sortToggleHandler: Events.sortToggleHandler
         },
         {
            Header: t('order.status'),
            accessor: 'orderStatus',
            width: 250,
            sortToggleHandler: Events.sortToggleHandler
         }
      ],
      []
   )

   const Sections = useMemo(
      () => [
         {
            title: t('main.data'),
            onClickHandler: () => {
               navigate(`../customer/profile/${id}`)
            }
         },
         {
            title: t('orders.history'),
            active: true
         }
      ],
      []
   )

   useEffect(() => {
      Requests.getClient()
   }, [])

   useEffect(() => {
      id && Requests.getOrders()
      setSearchParams({ ...searchParams, page: '' + page })
   }, [page, client, tableSortBy])

   useEffect(() => {
      if (response === CLIENT_RESPONSE.NOT_FOUND) navigate('../../customer')
   }, [response])

   // Set default page uri
   useEffect(() => {
      setPage(!isNaN(parseInt(searchParams.get('page') as string)) ? parseInt(searchParams.get('page') as string) : 0)
   }, [])

   return (
     <Container>
       <Header>
         <TitleBlock>
           <Image onClick={Events.backButtonClickHandler} src={Assets.ARROW_LEFT}/>
           <Title>{t('edit')}</Title>
         </TitleBlock>
         <DateBlock>
           <DateText>{t('createdAt.date')}{moment(client?.createdAt).format('YYYY-MM-DD HH:mm')}</DateText>
           <DateText>{t('updatedAt.date')}{moment(client?.updatedAt).format('YYYY-MM-DD HH:mm')}</DateText>
         </DateBlock>
         <FlexStyledContainer>
           <ButtonNew
             onClick={() => ''}
             theme={'red'}
           >{t('delete')}</ButtonNew>
           <ButtonNew theme="green" height={46} onClick={() =>''}>
             {t('save')}
           </ButtonNew>
         </FlexStyledContainer>
       </Header>
       <MainContainer>
         <SubPagesBar sections={Sections} />

         <Table
           columns={columns}
           data={data}
           sortBy={tableSortBy}
           editable
           editClickHandler={Events.editClickHandler}
         />

         <Pagination
           page={page}
           pageCount={orders.meta ? Math.ceil(orders.meta.totalCount / ITEM_PER_PAGE) : 1}
           onPageChange={Events.onPageChangeHandler}
         />
       </MainContainer>
     </Container>
   )
}

export default OrderHistory
