import styled from "styled-components";
import { colors, FLEX, FONT } from "../../common";

export const Container = styled.div``;

export const Header = styled.div`
  ${FLEX({ justify: "space-between" })}
`;

export const Title = styled.h1`
  ${FONT({ weight: "700", size: "24px", color: colors.black })}
`;

export const Span = styled.div`
  width: 300px;
  margin-top: 5px;

  ${FONT({ weight: "400", size: "14px", color: colors.red })};
`;

export const InputBonusBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MainContainer = styled.main`
  max-width: 1609px;
  height: 925px;

  padding: 30px 30px;
  margin-top: 26px;

  background: white;

  overflow-y: auto;

  box-shadow: 0 14px 28px rgba(135, 135, 135, 0.16);

  border-radius: 22px;
`;

export const FormContainer = styled.div`
  ${FLEX({ justify: "flex-start" })}
`;

export const Image = styled.img`
  margin-right: 9px;
`;

export const Name = styled.span`
  ${FONT({ weight: "600", size: "16px", color: colors.white })}
`;

export const DeleteButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 36px;
  height: 36px;

  border: none;
  outline: none;

  background: #dc3333;
  border-radius: 6px;

  cursor: pointer;
`;

export const StyledImage = styled.img``;

export const Line = styled.div`
  display: none;

  @media (max-width: 1600px) {
    display: flex;
    width: 100%;
    border: 1px solid gray;
  }
`;
