import {
   FC,
   SyntheticEvent,
   useEffect,
   useState
} from 'react';

import { colors } from "../../styles";
import { useTypedSelector } from "../../hooks";
import { getMainSettingSelector } from "../../../store";

import { Styles } from './styled';
import { TCheckbox } from './types';

const Checkbox: FC<TCheckbox> = ({ checked, onChange, size = '19px', className }) => {
   const [isChecked, setIsChecked] = useState<boolean>(false);
   const { logo } = useTypedSelector(getMainSettingSelector);

   const Events = {
      onChange: (e: SyntheticEvent) => {
         setIsChecked(!isChecked)
         onChange && onChange(e)
      }
   };

   useEffect(() => {
      checked != null && setIsChecked(checked)
   }, [checked]);

   return (
      <Styles color={logo?.data?.mainColor} size={size}>
         <label>
            <input className={className} type="checkbox" onChange={Events.onChange} checked={checked} />

            <svg
               className={`checkbox ${isChecked ? 'checkbox--active' : ''}`}
               aria-hidden="true"
               viewBox="0 0 15 11"
               fill="none">
               <path d="M1 4.5L5 9L14 1" strokeWidth="2" stroke={isChecked ? colors.white : 'none'} />
            </svg>
         </label>
      </Styles>
   )
}

export default Checkbox
