import styled from "styled-components";
import { colors, FLEX, FONT } from "../../common";

export const Container = styled.div``;

export const Header = styled.div`
  ${FLEX({justify: 'space-between'})}
`;

export const Title = styled.h1`
  ${FONT({ weight: '700', size: '24px', color: colors.black })}
  
  margin-bottom: 35px;
`;

export const MainContainer = styled.main`
  max-width: 1609px;
  height: 925px;

  padding: 30px 30px;

  display: grid;
  grid-template-columns: 1fr 1fr;
	
	background: white;
	
  box-shadow: 0 14px 28px rgba(135, 135, 135, 0.16);
	
  border-radius: 22px;
`;

export const InputBlock = styled.div`
  margin-bottom: 30px;
  
  width: 318px;
	&:not(:last-child){
    margin-right: 108px;	
	}
`;

export const TableContainer = styled.div`
   overflow-y: auto;
   border-right: 1px solid ${colors.lines};
`

export const FormContainer = styled.div`
   display: flex;
   flex-direction: column;
   padding: 30px;
`

export const DateField = styled.span`
   ${FONT({ size: '14px' })}
`

export const Message = styled.div`
   text-overflow: ellipsis;
   overflow: hidden;
   height: 1.2em;
   white-space: nowrap;

   width: 100%;
   max-width: 356px;
   margin: 0 auto;
`