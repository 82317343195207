import { AxiosResponse } from 'axios';
import { TResponse } from '../types';
import { TGetAvailabilitysRequestPayload, TRemoveAvailabilityRequestPayload } from './types';
import { AVAILABILITY_URL } from "./config";
import { HttpService } from '../http.service';

export class ApiAvailabilityService extends HttpService {
   static getAvailabilitys({
      token,
      auth='admin',
      limit = 10,
      page = 0,
      lang = 'ua',
      value = '',
      field = '',
      start = '',
      end = '',
      regex = '',
      sortBy = '',
      order = ''
   }: TGetAvailabilitysRequestPayload): Promise<AxiosResponse<TResponse>> {
      return this.request({
         url: `${AVAILABILITY_URL.getAvailabilitys}`,
         method: 'GET',
         headers: {
            Authorization: token
         },
         params: {
            limit,
            skip: limit * page,
            auth,
            lang,
            regex,
            value,
            field,
            start,
            end,
            sortBy,
            order
         }
      })
   };
   static removeAvailability({ token, _id }: TRemoveAvailabilityRequestPayload): Promise<AxiosResponse<TResponse>> {
      return this.request({
         url: `${AVAILABILITY_URL.removeAvailability}/${_id}`,
         method: 'DELETE',
         headers: {
            Authorization: token
         }
      })
   }
}
