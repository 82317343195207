import Sidebar from './Sidebar'
import { LayoutWrapper, ContentWrapper, ContentInnerWrapper } from './styled'

const Layout = ({ children }: any) => {
	return (
		<>
			<LayoutWrapper>
				<Sidebar />
				<ContentWrapper>
					<ContentInnerWrapper>{children}</ContentInnerWrapper>
				</ContentWrapper>
			</LayoutWrapper>
		</>
	)
}

export default Layout