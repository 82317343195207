import { createAction } from '@reduxjs/toolkit'
import {
   GET_PRODUCTS,
   REMOVE_PRODUCT,
   SET_PRODUCTS,
   SET_PRODUCT_STATE,
   CREATE_PRODUCT,
   SET_PRODUCT,
   GET_PRODUCT,
   GET_GROUPS,
   SET_GROUPS,
   CREATE_GROUP,
   GET_GROUP,
   SET_GROUP,
   EDIT_PRODUCT,
   EDIT_GROUP,
   CREATE_VARIATION_BULK,
   SET_VARIATIONS_BULK,
   EDIT_VARIATION,
   EDIT_VARIATION_VALUE,
   CREATE_VARIATION_VALUE,
   SET_VARIATIONS_VALUE,
   REMOVE_VARIATION,
   CREATE_PHOTO_PRODUCT,
   GET_PHOTO_PRODUCT,
   SET_PHOTO_PRODUCT,
   GET_PRODUCTS_GROUP,
   SET_PRODUCTS_GROUP,
   SET_DESCRIPTION,
   GET_DESCRIPTION,
   SET_VARIATION_VALUE,
   GET_VARIATION_VALUE
} from './consts'
import {
   TGetProductsPayload,
   TRemoveProductPayload,
   TSetProductStatePayload
} from './types'

export const productActions = {
   createProduct: createAction(CREATE_PRODUCT, (payload) => ({payload})),
   setProduct: createAction(SET_PRODUCT, (payload) => ({payload})),
   getGroups: createAction(GET_GROUPS, (payload) => ({payload})),
   setGroups: createAction(SET_GROUPS, (payload) => ({payload})),
   getGroup: createAction(GET_GROUP, (payload) => ({payload})),
   setGroup: createAction(SET_GROUP, (payload) => ({payload})),
   createGroup: createAction(CREATE_GROUP, (payload) => ({payload})),
   getProduct: createAction(GET_PRODUCT, (payload) => ({ payload })),
   getProducts: createAction(GET_PRODUCTS, (payload: TGetProductsPayload) => ({ payload })),
   getProductsGroup: createAction(GET_PRODUCTS_GROUP, (payload) => ({payload})),
   setProductsGroup: createAction(SET_PRODUCTS_GROUP, (payload) => ({payload})),
   setProducts: createAction(SET_PRODUCTS, (payload) => ({ payload })),
   setProductState: createAction(SET_PRODUCT_STATE, (payload: TSetProductStatePayload) => ({ payload })),
   removeProduct: createAction(REMOVE_PRODUCT, (payload: TRemoveProductPayload) => ({ payload })),
   editProduct: createAction(EDIT_PRODUCT, (payload) => ({ payload })),
   editGroup: createAction(EDIT_GROUP, (payload) => ({payload})),
   createVariationBulk: createAction(CREATE_VARIATION_BULK, (payload) => ({payload})),
   setVariationsBulk: createAction(SET_VARIATIONS_BULK, (payload) => ({payload})),
   editVariation: createAction(EDIT_VARIATION, (payload) => ({payload})),
   editVariationValue: createAction(EDIT_VARIATION_VALUE, (payload) => ({payload})),
   createVariationValue: createAction(CREATE_VARIATION_VALUE, (payload) => ({payload})),
   setVariationsValue: createAction(SET_VARIATIONS_VALUE, (payload) => ({payload})),
   removeVariation: createAction(REMOVE_VARIATION, (payload) => ({payload})),
   createPhotoProduct: createAction(CREATE_PHOTO_PRODUCT, (payload) => ({payload})),
   getPhotoProduct: createAction(GET_PHOTO_PRODUCT, (payload) => ({payload})),
   setPhotoProduct: createAction(SET_PHOTO_PRODUCT, (payload) => ({payload})),
   getDescription: createAction(GET_DESCRIPTION, (payload) => ({payload})),
   setDescription: createAction(SET_DESCRIPTION, (payload) => ({payload})),
   setVariationValue: createAction(SET_VARIATION_VALUE, (payload) => ({payload})),
   getVariationValue: createAction(GET_VARIATION_VALUE, (payload) => ({payload}))
}
