import styled from "styled-components";
import {colors, FLEX, FONT} from "../../common";

export const Container = styled.div``;

export const Header = styled.div`
  ${FLEX({justify: 'space-between'})}
`;

export const TitleBlock = styled.div`
  display: flex;
  align-items: center;
`;
export const FlexStyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ArrowImage = styled.img`
  cursor: pointer;
`;

export const ButtonBlock = styled.div`
  margin-left: 30px;
`;

export const Title = styled.h1`
  ${FONT({weight: '700', size: '24px', color: colors.black})};

  margin-left: 14px;
`;

export const DateBlock = styled.div`
  display: flex;
  justify-content: space-between;

  margin-left: 60px;

  ${FONT({weight: '400', size: '12px', color: colors.gray})};
`;

export const MainContainer = styled.main`
  max-width: 1609px;
  height: 925px;

  margin-top: 26px;

  padding: 30px 30px;

  overflow-y: auto;

  background: white;

  box-shadow: 0 14px 28px rgba(135, 135, 135, 0.16);

  border-radius: 22px;
`;

export const Name = styled.div`
  width: 230px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #222222;
`;

export const Label = styled.label`
  margin-left: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  color: #222222;
`;

export const StarBlock = styled.div`
  margin-left: 68px;
`;

export const ProductBlock = styled.div``;

export const ProductContainer = styled.div`
  margin-bottom: 30px;
  display: flex;
`;

export const Wrapper = styled.div`
  margin-top: 30px;
`;

export const FeedbacksContainer = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const P = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #818181;
`;

export const Image = styled.img`
  margin-right: 16px;
  width: 72px;
  height: 72px;
`;

export const Headline = styled.div`
  margin-right: 250px;
  max-width: 178px;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #222222;
`;

export const NameInput = styled.div`
  width: 318px;
  
  padding: 13px 14px;

  outline: none;

  border: 2px solid #D1D1D1;
  border-radius: 8px;
`;

export const InputContainer = styled.div``;