import styled from "styled-components";

import { colors, FLEX, FONT } from "../../common";

export const Container = styled.div``;

export const Header = styled.div`
  ${FLEX({ justify: "space-between" })}
`;

export const Title = styled.h1`
  ${FONT({ weight: "700", size: "24px", color: colors.black })}
`;

export const MainContainer = styled.main`
  max-width: 1609px;
  height: auto;

  margin-top: 26px;

  padding: 20px 30px;

  background: white;

  box-shadow: 0 14px 28px rgba(135, 135, 135, 0.16);

  border-radius: 22px;
`;

export const Wrapper = styled.div`
  margin-top: 20px;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
`;

export const FormBlock = styled.div`
  margin-right: 20px;
`;
export const ErrorP = styled.p`
  color: ${colors.red};
`;
export const AddReferral = styled.button`
  width: 80px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  background: ${colors.green};
  margin-top: 22px;
  &[disabled] {
    background: ${colors.greyBg};
    cursor: not-allowed;
  }
`;

export const ReferralList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 20px;
  list-style: none;
  li {
    display: flex;
    gap: 20px;
    div {
      border: 2px solid ${colors.strokeInput};
      padding: 13px 14px;
      border-radius: 8px;
      &:nth-child(1) {
        width: 230px;
      }
      &:nth-child(2) {
        width: 150px;
      }
    }
    button {
      width: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none;
      border: none;
      border-radius: 8px;
      cursor: pointer;
      background: ${colors.orange};
    }
  }
`;
