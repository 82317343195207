import { FC, SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";

import { CountBar } from "../CountBar";
import { FlexContainer } from "../Styled";

import {
  RemoveButton,
  CardContainer,
  Price,
  ProductImg,
  ProductName,
  ProductNumber,
  PromotionalPrice,
} from "./styles";
import { TCard } from "./types";

const Card: FC<TCard> = ({
  name,
  src,
  productName,
  productNumber,
  promotionalPrice,
  price,
  amount,
  onChange,
  removeButtonClickHandler,
  readonly,
  _id,
}) => {
  const { t } = useTranslation();

  const Events = {
    removeButtonClickHandler: (e: SyntheticEvent) => {
      removeButtonClickHandler && removeButtonClickHandler(e, _id as string);
    },
  };

  return (
    <CardContainer>
      <FlexContainer width="auto">
        <ProductImg src={src} />
        <FlexContainer
          direction="column"
          gap="6px"
          style={{ marginLeft: "16px" }}
          width="auto"
          justify="center"
        >
          <ProductName>{productName}</ProductName>
          <ProductNumber>{productNumber}</ProductNumber>
        </FlexContainer>
      </FlexContainer>
      <FlexContainer width="auto" gap="111px">
        {!readonly ? (
          <CountBar name={name} onChange={onChange} value={amount} />
        ) : (
          <p>{`${t("total")}: ${amount}`}</p>
        )}
        <FlexContainer width="auto" gap="31px" align="center" justify="center">
          <PromotionalPrice>{promotionalPrice}</PromotionalPrice>
          <Price>{price}</Price>
        </FlexContainer>
      </FlexContainer>
      <FlexContainer width="auto" style={{ padding: "35px" }}>
        <RemoveButton onClick={Events.removeButtonClickHandler} />
      </FlexContainer>
    </CardContainer>
  );
};

export default Card;
