import { createAction } from '@reduxjs/toolkit'
import {
  GET_SUBCATEGORIES,
  SET_SUBCATEGORIES,
  SET_SUBCATEGORY_STATE,
  REMOVE_SUBCATEGORIES,
  EDIT_SUBCATEGORY,
  CREATE_SUBCATEGORY,
  SET_SUBCATEGORIES_BY_CATEGORY,
  GET_SUBCATEGORIES_BY_CATEGORY,
  GET_SUBCATEGORY_DESCRIPTION,
  SET_SUBCATEGORY_DESCRIPTION
} from './consts'
import {
   TCreateSubcategoryPayload,
   TEditSubcategoryPayload,
   TGetSubcategoriesPayload,
   TRemoveSubcategoriesPayload,
   TSetSubcategoryStatePayload
} from './types'

export const subcategoryActions = {
   getSubcategories: createAction(GET_SUBCATEGORIES, (payload: TGetSubcategoriesPayload) => ({ payload })),
   setSubcategories: createAction(SET_SUBCATEGORIES, (payload) => ({ payload })),
   setSubcategoriesByCategory: createAction(SET_SUBCATEGORIES_BY_CATEGORY, (payload) => ({ payload })),
   getSubcategoriesByCategory: createAction(GET_SUBCATEGORIES_BY_CATEGORY, (payload) => ({ payload })),
   setSubcategoryState: createAction(SET_SUBCATEGORY_STATE, (payload: TSetSubcategoryStatePayload) => ({ payload })),
   removeSubcategories: createAction(REMOVE_SUBCATEGORIES, (payload: TRemoveSubcategoriesPayload) => ({ payload })),
   editSubcategory: createAction(EDIT_SUBCATEGORY, (payload: TEditSubcategoryPayload) => ({ payload })),
   createSubcategory: createAction(CREATE_SUBCATEGORY, (payload: TCreateSubcategoryPayload) => ({ payload })),
   getSubcategoryDescription: createAction(GET_SUBCATEGORY_DESCRIPTION, (payload) => ({payload})),
   setSubcategoryDescription: createAction(SET_SUBCATEGORY_DESCRIPTION, (payload) => ({payload}))
}

export enum SUBCATEGORY_RESPONSE {
   CREATED = 'CREATED',
   EDITED = 'EDITED',
   REMOVED = 'REMOVED'
}
