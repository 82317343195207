import { FC, SyntheticEvent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { nanoid } from "@reduxjs/toolkit";
import {
  Assets,
  ButtonNew,
  colors,
  FlexContainer,
  generateErrorToast,
  H2,
  Input,
  Pagination,
  PhotoPicker,
  Popup,
  SubPagesBar,
  Table,
  TDataTable,
  TOption,
  TSort,
  useTypedSelector,
  useValidation,
} from "../../../../common";
import { api } from "../../../../common/config/api";
import { ApiSectionService } from "../../../../store/section/api.service";
import { PAGE_LIMIT } from "../../consts";

import {
  CATEGORY_RESPONSE,
  categoryActions,
  getMainCategorySelector,
  getUserSelector,
  showToastAction,
} from "../../../../store";
import { getLocale } from "../../../../types";

import {
  ButtonBlock,
  Container,
  FlexStyledContainer,
  Header,
  Image,
  InputWrap,
  MainContainer,
  Title,
} from "../../styled";

import {
  TCategoryValidationSchema,
  TForm,
  TFormTypes,
  TSortParamsForm,
} from "./types";

const IndexPage: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(0);

  const { categories, response, description } = useTypedSelector(
    getMainCategorySelector
  );
  const { accessToken, permissions } = useTypedSelector(getUserSelector);

  const local = getLocale();

  const [removeButtonState, setRemoveButtonState] = useState<boolean>(false);

  const [popupVisiablity, setPopupVisiablity] = useState<boolean>(false);

  const [formType, setFormType] = useState<TFormTypes>("create");

  const [selectedItems, setSelectedItems] = useState<number[]>([]);

  const [editingIndex, setEditingIndex] = useState<number | null>(null);

  const [form, setForm] = useState<TForm>({
    nameUa: "",
    nameEn: "",
    nameRu: "",
    photo: null,
    characteristict: [],
    _id: "",
  });

  const [searchParams, setSearchParams] = useSearchParams({});

  const [sortParamsForm, setSortParamsForm] = useState<TSortParamsForm>({
    sortBy: "",
    order: "" as TSort,
  });

  const tableSortBy = useMemo(() => {
    {
      if (!sortParamsForm.order) {
        return { id: null, desc: false };
      }
      return { id: sortParamsForm.sortBy, desc: sortParamsForm.order > 0 };
    }
  }, [sortParamsForm]);

  const data: TDataTable[] = useMemo(
    () =>
      categories.data.map((category: any) => {
        return {
          photo: (
            <Image src={`${api.withImageAPI}/category/${category.image}`} />
          ),
          title: category.title,
          productsTotalCount: <>{category.productsTotalCount}</>,
          subCategoriesTotalCount: <>{category.subCategoriesTotalCount}</>,
        };
      }),
    [categories]
  );

  const Requests = {
    getCategories: () => {
      dispatch(
        categoryActions.getCategories({
          limit: PAGE_LIMIT,
          page: page,
          lang: local,
          ...sortParamsForm,
        })
      );
    },

    createCategory: () => {
      const data = new FormData();

      data.append(
        "description[]",
        JSON.stringify({
          title: form.nameEn,
          lang: "en",
          attributes: form.characteristict.map((item) => ({
            id: item.id,
            attribute: item.en,
          })),
        })
      );
      data.append(
        "description[]",
        JSON.stringify({
          title: form.nameUa,
          lang: "ua",
          attributes: form.characteristict.map((item) => ({
            id: item.id,
            attribute: item.ua,
          })),
        })
      );
      data.append(
        "description[]",
        JSON.stringify({
          title: form.nameRu,
          lang: "ru",
          attributes: form.characteristict.map((item) => ({
            id: item.id,
            attribute: item.ru,
          })),
        })
      );
      data.append("img", form.photo as File);
      // console.log(
      //   form.characteristict.map((item) => ({
      //     id: item.id,
      //     attributes: item.ua,
      //   }))
      // );

      dispatch(categoryActions.createCategory({ data }));
    },
    removeCategories: (_ids: string[]) => {
      dispatch(categoryActions.removeCategories({ _ids }));
    },
    editCategory: () => {
      const data = new FormData();

      data.append(
        "description[]",
        JSON.stringify({
          title: form.nameEn,
          lang: "en",
          attributes: form.characteristict.map((item) => ({
            id: item.id,
            attribute: item.en,
          })),
        })
      );
      data.append(
        "description[]",
        JSON.stringify({
          title: form.nameUa,
          lang: "ua",
          attributes: form.characteristict.map((item) => ({
            id: item.id,
            attribute: item.ua,
          })),
        })
      );
      data.append(
        "description[]",
        JSON.stringify({
          title: form.nameRu,
          lang: "ru",
          attributes: form.characteristict.map((item) => ({
            id: item.id,
            attribute: item.ru,
          })),
        })
      );
      form.photo && data.append("img", form.photo as File);

      dispatch(categoryActions.editCategory({ data, _id: form._id as string }));
    },

    getSection: async (_id: string) => {
      return await ApiSectionService.getSection({
        token: accessToken as string,
        _id,
        lang: local,
      });
    },
  };
  useEffect(() => {
    if (description && formType === "edit") {
      setForm({
        nameUa: description?.data[1]?.title,
        nameEn: description?.data[0]?.title,
        nameRu: description?.data[2]?.title,
        photo:
          typeof editingIndex === "number"
            ? `${api.withImageAPI}/category/${categories.data[editingIndex].image}`
            : "",
        _id: description.data[0]?.category,
        characteristict:
          description.data[0]?.attributes?.map(
            (attrEn: { id: string; attribute: string }, attrIndex: number) => ({
              en: attrEn.attribute,
              ua: description.data[1]?.attributes[attrIndex].attribute || "",
              ru: description.data[2]?.attributes[attrIndex].attribute || "",
              id: nanoid(),
            })
          ) || [],
      });
    }
  }, [description, formType, editingIndex, categories.data]);
  const schema = useMemo<TCategoryValidationSchema>(
    () => ({
      name: {
        condition: !!form.nameRu && !!form.nameEn && !!form.nameUa,
        error: t("enter.the.category.name.in.all.languages"),
      },
      // section: {
      //   condition: !!form.section.value,
      //   error: t("select.section"),
      // },
      photo: {
        condition: form.photo !== null,
        error: t("enter.photo"),
      },
      characteristic: {
        condition: form.characteristict.every(
          (item) => item.en && item.ru && item.ua
        ),
        error: t("fill.all.characteristic.fields"),
      },
    }),
    [form]
  );

  const {
    validation,
    enableValidation,
    disableValidation,
    //eslint-disable-next-line
  } = useValidation(schema);

  const Events = {
    onPageChangeHandler: ({ selected }: { selected: number }) => {
      setPage(selected);
    },
    sortToggleHandler: (sortBy: string, order: TSort) => {
      setSortParamsForm({ sortBy, order });
    },
    closePopupClickHandler: () => {
      setPopupVisiablity(false);
    },
    inputChangeHandler: (e: SyntheticEvent) => {
      const { name, value } = e.target as HTMLInputElement;

      setForm((props) => ({ ...props, [name]: value }));
    },
    inputCharacteristicChangeHandler: (
      e: React.SyntheticEvent<Element, Event>,
      index: number,
      lang: "ua" | "ru" | "en"
    ) => {
      const target = e.target as HTMLInputElement;
      const value = target.value;

      const newCharacteristics = [...form.characteristict];
      newCharacteristics[index][lang] = value;

      setForm({
        ...form,
        characteristict: newCharacteristics,
      });
    },
    onPhotoChange: (file: File | null) => {
      setForm({ ...form, photo: file as File });
    },
    createButtonClickHandler: async () => {
      try {
        enableValidation();
        await validation();
        Requests.createCategory();
        // setPopupVisiablity(false);
        disableValidation();
      } catch (error: any) {
        error?.map((err: string) => {
          dispatch(showToastAction.request(generateErrorToast(err)));
        });
      }
    },
    editButtonClickHandler: async () => {
      try {
        enableValidation();
        await validation();
        Requests.editCategory();
        setPopupVisiablity(false);
        disableValidation();
      } catch (error: any) {
        error?.map((err: string) => {
          dispatch(showToastAction.request(generateErrorToast(err)));
        });
      }
    },
    addButtonClickListner: () => {
      setForm({
        nameUa: "",
        nameRu: "",
        nameEn: "",
        photo: null,
        characteristict: [],
      });
      setFormType("create");
      setPopupVisiablity(true);
    },
    removeCategories: () => {
      Requests.removeCategories(
        selectedItems.map((index) => categories.data[index]._id)
      );
      setRemoveButtonState(false);
      setSelectedItems([]);
    },

    paginateDropDownChangeHandler: (value: TOption) => {
      setForm((form) => ({ ...form, section: value }));
    },
    removeCategoryHandler: (e: SyntheticEvent, index: number) => {
      Requests.removeCategories([categories.data[index]._id]);
    },
    checkboxClickHandler: (
      e: SyntheticEvent,
      hasCheckboxesActiveState: boolean,
      ckeckedItemsIndex: number[]
    ) => {
      setRemoveButtonState(hasCheckboxesActiveState);
      setSelectedItems(ckeckedItemsIndex);
    },
    openEditButtonClickHandler: (e: SyntheticEvent, index: number) => {
      setEditingIndex(index);
      dispatch(
        categoryActions.getDescriptionCategory({
          _id: categories.data[index]._id,
        })
      );
      setFormType("edit");
      setPopupVisiablity(true);
    },
    onChange: (e: SyntheticEvent) => {
      const input = e.target as HTMLInputElement;
      setForm({ ...form, [input.name]: input.value });
    },
  };

  const columns = useMemo(
    () => [
      {
        Header: t("photo"),
        accessor: "photo",
        width: 200,
      },
      {
        Header: t("label"),
        accessor: "title",
        width: 350,
        sortToggleHandler: Events.sortToggleHandler,
      },
      {
        Header: t("total.product"),
        accessor: "productsTotalCount",
        width: 350,
        sortToggleHandler: Events.sortToggleHandler,
      },
      {
        Header: t("total.subcategories"),
        accessor: "subCategoriesTotalCount",
        width: 350,
        sortToggleHandler: Events.sortToggleHandler,
      },
    ],
    []
  );

  const paginationSections = useMemo(
    () => [
      // {
      //   title: t("sections"),
      //   onClickHandler: () => {
      //     navigate("/catalog/sections");
      //   },
      // },
      {
        title: t("categories"),
        active: true,
      },
      {
        title: t("subcategories"),
        onClickHandler: () => {
          navigate("/catalog/subcategories");
        },
      },
      {
        title: t("brands"),
        onClickHandler: () => {
          navigate("/catalog/brand");
        },
      },
    ],
    []
  );

  // Request to receive data
  useEffect(() => {
    Requests.getCategories();
    setSearchParams({ ...searchParams, page: "" + page });
  }, [page, sortParamsForm]);

  // Set default page uri
  useEffect(() => {
    setPage(
      !isNaN(parseInt(searchParams.get("page") as string))
        ? parseInt(searchParams.get("page") as string)
        : 0
    );
  }, []);

  useEffect(() => {
    if (response === CATEGORY_RESPONSE.CREATED) {
      setPopupVisiablity(false);
      setForm({
        nameUa: "",
        nameEn: "",
        nameRu: "",
        photo: null,
        // section: { value: "", label: "" },
        characteristict: [],
      });
      Requests.getCategories();
    }

    if (response === CATEGORY_RESPONSE.REMOVED) {
      Requests.getCategories();
    }

    if (response === CATEGORY_RESPONSE.EDITED) {
      Requests.getCategories();
    }
  }, [response]);

  useEffect(() => {
    if (description) {
      setForm((form) => ({
        ...form,
        nameUa: description?.data[1]?.title,
        nameEn: description?.data[0]?.title,
        nameRu: description?.data[2]?.title,
      }));
    }
  }, [description]);

  const category = permissions.filter((el: string) => el === "category").join();
  if (category !== "category") navigate("/404");

  const addNewCharacteristic = () => {
    setForm({
      ...form,
      characteristict: [
        ...form.characteristict,
        { ua: "", ru: "", en: "", id: nanoid() },
      ],
    });
  };
  return (
    <Container>
      <Header>
        <Title>{t("categories")}</Title>
        <FlexStyledContainer>
          {removeButtonState && (
            <ButtonBlock>
              <ButtonNew theme="red" onClick={Events.removeCategories}>
                {t("remove.all")}
              </ButtonNew>
            </ButtonBlock>
          )}
          <ButtonBlock>
            <ButtonNew theme="green" onClick={Events.addButtonClickListner}>
              {t("create.category")}
            </ButtonNew>
          </ButtonBlock>
        </FlexStyledContainer>
      </Header>
      <MainContainer>
        <SubPagesBar sections={paginationSections} />

        <Table
          columns={columns}
          data={data}
          sortBy={tableSortBy}
          removeClickHandler={Events.removeCategoryHandler}
          checkboxClickHandler={Events.checkboxClickHandler}
          editClickHandler={Events.openEditButtonClickHandler}
          editable
          removable
        />

        <Pagination
          page={page}
          pageCount={
            categories.meta
              ? Math.ceil(categories.meta.totalCount / PAGE_LIMIT)
              : 1
          }
          onPageChange={Events.onPageChangeHandler}
        />

        {popupVisiablity && (
          <Popup
            backgroundClickListener={Events.closePopupClickHandler}
            height="auto"
          >
            <FlexContainer
              justify="space-between"
              direction="column"
              style={{ height: "100%" }}
              wrap="nowrap"
            >
              <FlexContainer gap="20px" align="center" justify="center">
                {formType === "create" && (
                  <H2 style={{ marginTop: "40px" }}>{t("create.category")}</H2>
                )}
                {formType === "edit" && (
                  <H2 style={{ marginTop: "40px" }}>{t("edit.category")}</H2>
                )}
                <FlexContainer
                  gap="30px"
                  align="flex-start"
                  justify="center"
                  style={{
                    padding: "30px 0px",
                    borderBottom: `1px solid ${colors.gray}`,
                  }}
                >
                  <Input
                    name="nameUa"
                    label={t("name")}
                    placeholder={t("enter.name.ua")}
                    value={form.nameUa}
                    onChange={Events.inputChangeHandler}
                  />
                  <Input
                    name="nameEn"
                    label={t("name")}
                    placeholder={t("enter.name.en")}
                    value={form.nameEn}
                    onChange={Events.inputChangeHandler}
                  />
                  <Input
                    name="nameRu"
                    label={t("name")}
                    placeholder={t("enter.name.ru")}
                    value={form.nameRu}
                    onChange={Events.inputChangeHandler}
                  />
                </FlexContainer>
                <FlexContainer direction="column" align="center" gap="40px">
                  <PhotoPicker
                    width={150}
                    height={150}
                    label={t("photo")}
                    onChange={Events.onPhotoChange}
                    value={formType === "edit" ? (form.photo as string) : null}
                  />
                  <ButtonNew
                    width={318}
                    theme="orange"
                    onClick={addNewCharacteristic}
                  >
                    {t("addCharacteristic")}
                  </ButtonNew>
                </FlexContainer>
                <FlexContainer
                  justify="center"
                  direction="column"
                  align="center"
                  gap="20px"
                >
                  <FlexContainer direction="column" width="auto">
                    {form.characteristict.map((item, index) => (
                      <InputWrap key={item.id}>
                        <img
                          alt="delete"
                          src={Assets.CROSS}
                          onClick={() => {
                            setForm((prev) => ({
                              ...prev,
                              characteristict: prev.characteristict.filter(
                                (char) => char.id !== item.id
                              ),
                            }));
                          }}
                        />
                        <Input
                          name={`characteristict[${index}].ua`}
                          label={t("Characteristic") + " " + `${index + 1} UA`}
                          placeholder={t("enter.name.ua")}
                          value={item.ua}
                          onChange={(e) =>
                            Events.inputCharacteristicChangeHandler(
                              e,
                              index,
                              "ua"
                            )
                          }
                        />
                        <Input
                          name={`characteristict[${index}].en`}
                          label={t("Characteristic") + " " + `${index + 1} EN`}
                          placeholder={t("enter.name.en")}
                          value={item.en}
                          onChange={(e) =>
                            Events.inputCharacteristicChangeHandler(
                              e,
                              index,
                              "en"
                            )
                          }
                        />
                        <Input
                          name={`characteristict[${index}].ru`}
                          label={t("Characteristic") + " " + `${index + 1} RU`}
                          placeholder={t("enter.name.ru")}
                          value={item.ru}
                          onChange={(e) =>
                            Events.inputCharacteristicChangeHandler(
                              e,
                              index,
                              "ru"
                            )
                          }
                        />
                      </InputWrap>
                    ))}
                  </FlexContainer>
                </FlexContainer>
              </FlexContainer>
              <FlexContainer
                style={{ marginBottom: "40px", marginTop: "20px" }}
                gap="30px"
                justify="center"
              >
                <ButtonNew
                  theme="gray"
                  style="transparant"
                  onClick={Events.closePopupClickHandler}
                >
                  {t("cancel")}
                </ButtonNew>

                {formType === "create" && (
                  <ButtonNew
                    theme="green"
                    onClick={Events.createButtonClickHandler}
                  >
                    {t("create")}
                  </ButtonNew>
                )}
                {formType === "edit" && (
                  <ButtonNew
                    theme="green"
                    onClick={Events.editButtonClickHandler}
                  >
                    {t("edit")}
                  </ButtonNew>
                )}
              </FlexContainer>
            </FlexContainer>
          </Popup>
        )}
      </MainContainer>
    </Container>
  );
};

export default IndexPage;
