import { createAction } from '@reduxjs/toolkit'
import {EDIT_CLIENT, GET_CLIENT, GET_CLIENTS, SET_CLIENT, SET_CLIENTS, SET_CLIENT_STATE, REMOVE_CLIENTS} from './consts'
import {
   TEditClientPayload,
   TGetClientPayload,
   TGetClientsPayload,
   TSetClientPayload,
   TSetClientStatePayload
} from './types'

export const clientActions = {
   getClients: createAction(GET_CLIENTS, (payload: TGetClientsPayload) => ({ payload })),
   setClients: createAction(SET_CLIENTS, (payload) => ({ payload })),
   setClientState: createAction(SET_CLIENT_STATE, (payload: TSetClientStatePayload) => ({ payload })),
   getClient: createAction(GET_CLIENT, (payload: TGetClientPayload) => ({ payload })),
   setClient: createAction(SET_CLIENT, (payload: TSetClientPayload) => ({ payload })),
   editClient: createAction(EDIT_CLIENT, (payload: TEditClientPayload) => ({ payload })),
   removeClient: createAction(REMOVE_CLIENTS, (payload) => ({ payload }))
}
