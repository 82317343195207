import styled from "styled-components";
import { TImage } from "./types";
import { FONT, colors } from "../../common";

export const Image = styled.div<TImage>`
  display: inline-block;
  background-image: ${({ src }) => `url(${src})`};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 46px;
  width: 46px;
`;

export const Container = styled.div``;

export const MainContainer = styled.main`
  max-width: 1609px;
  height: 925px;

  padding: 30px 30px;

  margin-top: 26px;

  background: white;

  box-shadow: 0 14px 28px rgba(135, 135, 135, 0.16);
  overflow-y: auto;

  border-radius: 22px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.h1`
  ${FONT({ weight: "700", size: "24px", color: colors.black })}
`;

export const ButtonBlock = styled.div`
  margin-left: 30px;
`;

export const FlexStyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const InputWrap = styled.div`
  margin: 10px 0;
  position: relative;
  display: flex;
  gap: 8px;
  div {
    width: 180px;
  }
  img {
    position: absolute;
    z-index: 1;
    right: 0;
    cursor: pointer;
  }
`;
