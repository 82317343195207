import { createReducer } from '@reduxjs/toolkit';

import { categoryActions } from './actions';
import { TInitialState } from './types';

const InitialState: TInitialState = {
   categories: {
      meta: null,
      data: []
   },
   categoryBySection:{
      data: [],
      meta: null,
   },
   loading: false,
   response: null,
   description: {
      data: [],
      meta: null
   }
}

const reducer = createReducer<TInitialState>(InitialState, (builder) => {
   builder
      .addCase(categoryActions.setCategories, (state, { payload }) => ({ ...state, categories: payload }))
      .addCase(categoryActions.setDescriptionCategory, (state, { payload }) => ({ ...state, description: payload }))
      .addCase(categoryActions.setCategoryBySection, (state, { payload }) => ({ ...state, categoryBySection: payload }))
      .addCase(categoryActions.setCategoriesState, (state, { payload }) => ({ ...state, response: null, ...payload }))
})

export default reducer
