export const GET_SUBCATEGORIES = 'GET_SUBCATEGORIES'
export const SET_SUBCATEGORIES = 'SET_SUBCATEGORIES'
export const SET_SUBCATEGORY_STATE = 'SET_SUBCATEGORY_STATE'
export const REMOVE_SUBCATEGORIES = 'REMOVE_SUBCATEGORIES'
export const EDIT_SUBCATEGORY = 'EDIT_SUBCATEGORY'
export const CREATE_SUBCATEGORY = 'CREATE_SUBCATEGORY'
export const SET_SUBCATEGORIES_BY_CATEGORY = 'SET_SUBCATEGORIES_BY_CATEGORY'
export const GET_SUBCATEGORIES_BY_CATEGORY = 'GET_SUBCATEGORIES_BY_CATEGORY'
export const GET_SUBCATEGORY_DESCRIPTION = 'GET_SUBCATEGORY_DESCRIPTION'
export const SET_SUBCATEGORY_DESCRIPTION = 'SET_SUBCATEGORY_DESCRIPTION'
