import { FC, useEffect, useRef, useState } from "react";

import { Assets } from "../../assets";

import { Container, Button, Input } from "./styled";
import { TCountBar } from "./typest";

const CountBar: FC<TCountBar> = ({ value, onChange, name }) => {
  const [count, setCount] = useState<number>(0);
  const inputRef = useRef(null);

  const Events = {
    plusButtonClickHandler: () => {
      setCount((count) => count + 1);
    },
    minusButtonClickHandler: () => {
      count > 0 && setCount((count) => count - 1);
    },
  };

  useEffect(() => {
    value && setCount(value);
  }, [value]);

  useEffect(() => {
    onChange && onChange(inputRef.current);
  }, [count]);

  return (
    <Container>
      <Button disabled={count <= 1} onClick={Events.minusButtonClickHandler}>
        <img src={Assets.MINUS} />
      </Button>
      <Input
        name={name}
        value={count}
        ref={inputRef}
        onChange={(e) => onChange && onChange(e.target as any)}
      />
      <Button onClick={Events.plusButtonClickHandler}>
        <img src={Assets.PLUS} />
      </Button>
    </Container>
  );
};

export default CountBar;
