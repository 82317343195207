export const GET_DELIVERY = 'GET_DELIVERY'
export const SET_DELIVERY = 'SET_DELIVERY'
export const SET_DELIVERY_STATE = 'SET_DELIVERY_STATE'
export const SET_DELIVERY_REF = 'SET_DELIVERY_REF'
export const GET_DELIVERY_REF = 'GET_DELIVERY_REF'
export const GET_DELIVERY_WAREHOUSE = 'GET_DELIVERY_WAREHOUSE'
export const SET_DELIVERY_WAREHOUSE = 'SET_DELIVERY_WAREHOUSE'
export const GET_CALCULATE = 'GET_CALCULATE'
export const CREATE_CALCULATE = 'CREATE_CALCULATE'
export const SET_CALCULATE = 'SET_CALCULATE'
export const GET_DELIVERY_CONFIG = 'GET_DELIVERY_CONFIG'
export const SET_DELIVERY_CONFIG = 'SET_DELIVERY_CONFIG'
export const UPDATE_DELIVERY_CONFIG = 'UPDATE_DELIVERY_CONFIG'

