export const GET_FEEDBACKS = 'GET_FEEDBACKS'
export const SET_FEEDBACKS = 'SET_FEEDBACKS'
export const SET_FEEDBACK_STATE = 'SET_FEEDBACK_STATE'
export const GET_FEEDBACK = 'GET_FEEDBACK'
export const SET_FEEDBACK = 'SET_FEEDBACK'
export const REMOVE_FEEDBACK = 'REMOVE_FEEDBACK'
export const EDIT_FEEDBACK = 'EDIT_FEEDBACK'
export const GET_FEEDBACK_PRODUCT = 'GET_FEEDBACK_PRODUCT'
export const SET_FEEDBACK_PRODUCT = 'SET_FEEDBACK_PRODUCT'

export const RESPONSE = {
	NOT_FOUND: 'NOT_FOUND',
	EDITED:'EDITED',
	REMOVED:'REMOVED'
}
