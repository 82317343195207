import { Middleware } from 'redux'
import { RootState } from '../types'
import { userActions } from '../user'
import { TAuthMidllewareProps, TMiddlewareProps } from './types'

const authMiddleware: TMiddlewareProps<TAuthMidllewareProps, Middleware<{}, RootState>> =
   ({ blackList, whiteList }: TAuthMidllewareProps) =>
   (store) =>
   (next) =>
   (action) => {
      const basicActionsBlackList = [
         'persist/PERSIST',
         'persist/REHYDRATE' // Ignore persist actions
      ]

      if (basicActionsBlackList.includes(action.type)) {
         next(action)
         return
      }

      // Check if action is not in black list
      for (let object of blackList) {
         const actions: string[] = Object.keys(object).map((key) => key)

         for (let blackListAction of actions) {
            if (blackListAction === action.type) {
               // Check if black action is not in white list
               for (let whiteObject of whiteList) {
                  const whiteActions: string[] = Object.keys(whiteObject).map((key) => key)

                  let isInWhiteList = false
                  for (let whiteListAction of whiteActions) {
                     if (blackListAction === whiteListAction) {
                        isInWhiteList = true
                        break
                     }
                  }

                  // If action in whiteList
                  if (!isInWhiteList) {
                     next(action)
                     return
                  }
               }
            }
         }
      }
      store.dispatch(
         userActions.connect({
            data: store.getState().user,
            callback: () => {
               next(action)
            }
         })
      )
   }

export default authMiddleware
