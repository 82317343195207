import { api } from "../../common/config/api";

export const CATEGORY_URL = {
   getCategories: `${api.withAPI}/v1/catalog/category`,
   removeCategory: `${api.withAPI}/v1/catalog/category`,
   editCategory: `${api.withAPI}/v1/catalog/category`,
   createCategory: `${api.withAPI}/v1/catalog/category`,
   getCategory: `${api.withAPI}/v1/catalog/category`,
   getCategoryBySection: `${api.withAPI}/v1/catalog/category`,
   getDescriptionCategory: `${api.withAPI}/v1/catalog/category`
}
