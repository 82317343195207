import { createAction } from '@reduxjs/toolkit'
import { SET_DESCRIPTION_SECTION, GET_DESCRIPTION_SECTION, CREATE_SECTION, EDIT_SECTION, GET_SECTIONS, REMOVE_SECTIONS, SET_SECTIONS, SET_SECTION_STATE } from './consts'
import {
   TCreateSectionPayload,
   TEditSectionPayload,
   TRemoveSectionsPayload,
   TSectionPayload,
   TSetSectionStatePayload
} from './types'

export const sectionActions = {
   setSections: createAction(SET_SECTIONS, (payload) => ({ payload })),
   getSections: createAction(GET_SECTIONS, (payload: TSectionPayload) => ({ payload })),
   getDescriptionSection: createAction(GET_DESCRIPTION_SECTION, (payload) => ({ payload })),
   setDescriptionSection: createAction(SET_DESCRIPTION_SECTION, (payload) => ({ payload })),
   setSectionState: createAction(SET_SECTION_STATE, (payload: TSetSectionStatePayload) => ({ payload })),
   createSection: createAction(CREATE_SECTION, (payload: TCreateSectionPayload) => ({ payload })),
   editSection: createAction(EDIT_SECTION, (payload: TEditSectionPayload) => ({ payload })),
   removeSections: createAction(REMOVE_SECTIONS, (payload: TRemoveSectionsPayload) => ({ payload }))
}
