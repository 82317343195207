import { call, put, takeLatest } from 'redux-saga/effects';
import { TDataWrapper } from '../types';

import { TResponse } from '../types';
import { getAccessToken } from '../user';
import {
	TCreateBrandPayload,
	TEditBrandPayload,
	TGetBrandsRequestPayload
} from "./types";
import { brandActions } from "./actions";
import { ApiBrandServices } from "./api.service";
import { TRemoveCategoriesPayload } from "../category/types";
import { showToastAction } from "../toasts";
import { generateErrorToast, generateSuccessToast } from "../../common";
import { i18n } from "../../common/config";
import { BRAND_RESPONSE } from "./consts";

function* getBrandsWorker({ payload }: TDataWrapper<TGetBrandsRequestPayload>) {
	yield put(brandActions.setBrandsState({ loading: true }))

	const token: string = yield call(getAccessToken)

	try {
		const response: TResponse = yield call([ApiBrandServices, ApiBrandServices.getBrands], {
			...payload,
			token
		})
		if (response.data.data) yield put(brandActions.setBrands(response.data))
	} catch (e) {}

	yield put(brandActions.setBrandsState({ loading: false }))
}

function* removeBrandWorker({ payload }: TDataWrapper<TRemoveCategoriesPayload>) {
	yield put(brandActions.setBrandsState({ loading: true }))
	const token: string = yield call(getAccessToken)

	const { _ids } = payload

	try {
		for (let _id of _ids) {
			yield call([ApiBrandServices, ApiBrandServices.removeBrand], { token, _id })
		}
		yield put(showToastAction.request(generateSuccessToast(i18n.t("brand.removed.successfully") as string)));
	} catch (e) {
		yield put(showToastAction.request(generateErrorToast(i18n.t("failed.to.remove.brand") as string)));
	}

	yield put(brandActions.setBrandsState({ loading: false, response: BRAND_RESPONSE.REMOVED }))
}

function* createBrandWorker({ payload }: TDataWrapper<TCreateBrandPayload>) {
	yield put(brandActions.setBrandsState({ loading: true }))
	const token: string = yield call(getAccessToken)
	const { data } = payload

	try {
		yield call([ApiBrandServices, ApiBrandServices.createBrand], { token, data })
		yield put(
			showToastAction.request(
				generateSuccessToast(i18n.t("brand.was.created.successfully") as string),
			),
		)
	} catch (e) {
		yield put(
			showToastAction.request(
				generateErrorToast(i18n.t("brand.creating.error") as string),
			),
		)
	}

	yield put(brandActions.setBrandsState({ loading: false, response: BRAND_RESPONSE.CREATED }))
}

function* editBrandWorker({ payload }: TDataWrapper<TEditBrandPayload>) {
	yield put(brandActions.setBrandsState({ loading: true }))
	const token: string = yield call(getAccessToken)

	const { _id, data } = payload
	try {
		yield call([ApiBrandServices, ApiBrandServices.editBrand], { token, _id, data })
		yield put(showToastAction.request(generateSuccessToast(i18n.t("brand.updated.successfully") as string)));
	} catch (e) {
		yield put(showToastAction.request(generateErrorToast(i18n.t("brand.to.update.category") as string)));
	}

	yield put(brandActions.setBrandsState({ loading: false, response: BRAND_RESPONSE.EDITED }))
}

export function* brandWatcher() {
	yield takeLatest(brandActions.getBrands, getBrandsWorker)
	yield takeLatest(brandActions.createBrand, createBrandWorker)
	yield takeLatest(brandActions.editBrand, editBrandWorker)
	yield takeLatest(brandActions.removeBrand, removeBrandWorker)
}
