import { createReducer } from '@reduxjs/toolkit'
import { orderActions } from './actions'
import { TInitialState } from './types'

const InitialState: TInitialState = {
   orders: {
      data: [],
      meta: null
   },
   archive: {
      data: [],
      meta: null
   },
   stats: {
      data: [],
      meta: null
   },
   graf: {
      data: [],
      meta: null
   },
   order: null,
   loading: false,
   response: null,
   delivery:{
      data: [],
      meta: null
   }
}

const reducer = createReducer<TInitialState>(InitialState, (builder) => {
   builder
      .addCase(orderActions.setOrders, (state, { payload }) => ({ ...state, orders: payload }))
      .addCase(orderActions.setDelivery, (state, { payload }) => ({ ...state, delivery: payload }))
      .addCase(orderActions.setOrderState, (state, { payload }) => ({ ...state, respones: null, ...payload }))
      .addCase(orderActions.setOrder, (state, { payload }) => ({ ...state, order: payload }))
      .addCase(orderActions.setArchive, (state, { payload }) => ({ ...state, archive: payload }))
      .addCase(orderActions.setStatistic, (state, { payload }) => ({ ...state, stats: payload }))
      .addCase(orderActions.setGraf, (state, { payload }) => ({ ...state, graf: payload }))
})

export default reducer
