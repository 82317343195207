import styled from "styled-components";
import { colors, FLEX, FONT } from "../../common";
import {TStyledStatisticsProps} from "./types";

export const Container = styled.div`
 overflow: hidden;
`;

export const Header = styled.div`
  ${FLEX({justify: 'space-between'})}
  max-width: 1609px;
`;

export const TitleBlock = styled.div`
  display: flex;
`;

export const ButtonBlock = styled.div`
  margin-left: 30px;
`;

export const FlexStyledContainer = styled.div`
  display: flex;
`;

export const Title = styled.h1`
  ${FONT({ weight: '700', size: '24px', color: colors.black })};

  margin-left: 14px;
`;

export const MainContainer = styled.main`
  max-width: 1609px;
	height: 752px;
  
  margin-top: 26px;

  padding: 15px 30px;
  
	background: white;
	
  box-shadow: 0 14px 28px rgba(135, 135, 135, 0.16);
	
  border-radius: 22px;
`;

export const ChartContainer = styled.div`
  max-width: 1609px;
  display: flex;
  justify-content: space-between;
`

export const DateBlock = styled.div`
  display: flex;
  justify-content: space-between;
  width: 325px;
`;

