import { api } from "../../common/config/api";

export const DELIVERY_URL = {
  getDelivery: `${api.withAPI}/v1/delivery/city`,
  getDeliveryRef: `${api.withAPI}/v1/delivery/city`,
  getDeliveryWarehouse: `${api.withAPI}/v1/delivery/warehouse`,
  createCalculate: `${api.withAPI}/v1/delivery/calculateDeliveryPrice`,
  deliveryConfig: `${api.withAPI}/v1/delivery/deliveryConfig`,
  updateDeliveryConfig: `${api.withAPI}/v1/delivery/deliveryConfig`,
  referral: `${api.withAPI}/v1/referral`,
};
