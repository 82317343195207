import styled from "styled-components";
import {colors, FLEX, FONT} from "../../../common";

export const Container = styled.div``;

export const Header = styled.div`
  ${FLEX({justify: 'space-between'})}
`;

export const TitleBlock = styled.div`
 display: flex;
`;
export const FlexStyledContainer = styled.div`
  width: 450px;
  
  display: flex;
  justify-content: space-between;
`;

export const Image = styled.img`
  cursor: pointer;
`;

export const Title = styled.h1`
  ${FONT({ weight: '700', size: '24px', color: colors.black })};

  margin-left: 14px;
`;

export const MainContainer = styled.main`
  max-width: 1609px;
  height: 925px;
  
  margin-top: 26px;

  padding: 30px 30px;
	
	background: white;
  
  overflow-y: auto;
	
  box-shadow: 0 14px 28px rgba(135, 135, 135, 0.16);
	
  border-radius: 22px; 
`;

export const DateBlock = styled.div`
  width: 450px;
  
  display: flex;
  justify-content: space-between;

  ${FONT({ weight: '400', size: '12px', color: colors.gray })};
`;

export const DateText = styled.p``;
