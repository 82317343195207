import styled from "styled-components";
import { TContainer, TSelectList } from "./types";
import { Assets } from "../../assets";
import { colors, FLEX, FONT } from "../../styles";

export const Container = styled.div<TContainer>`
  ${FLEX({ direction: "column", align: "flex-start" })}
  width: ${({ width }) => width};
  justify-content: flex-start;
`;

export const SelectList = styled.select<TSelectList>`
  width: 100%;
  height: ${({ height }) => height};

  border: 2px solid #d1d1d1;
  border-radius: 8px;

  ${FONT({ size: "16px" })}
  line-height: 19px;

  cursor: pointer;
  padding-left: 14px;

  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: ${`url(${Assets.ARROW_DOWN_ICON})`};
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 50%;

  &:focus {
    border: 2px solid ${({ color }) => `${color}`};
    background: ${colors.white};
  }

  @media (max-width: 1600px) {
    width: 100%;
  }
`;

export const Option = styled.option`
  cursor: pointer !important;
  height: 100px;
`;
