import styled from "styled-components";

export const MainContainer = styled.main`
  max-width: 1609px;
  height: 925px;

  margin-top: 26px;

  background: white;

  border-radius: 22px;
`;
export const CharBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  gap: 12px;
  margin: 10px 0;
  img {
    cursor: pointer;
    position: absolute;
    right: 0;
  }
`;
export const SelectWrap = styled.div`
  p {
    margin-bottom: 9px;
    font-weight: 500;
    font-size: 14px;
  }
`;
export const CharacteristicSelect = styled.select`
  width: 200px;
  height: 46px;
  border-radius: 8px;
  border: 2px solid #d1d1d1;
`;
