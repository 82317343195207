import React, { FC, SyntheticEvent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";

import {
  ApiCategoryService,
  ApiProductService,
  brandActions,
  categoryActions,
  getMainBrandSelector,
  getMainCategorySelector,
  getMainProductSelector,
  getMainSettingSelector,
  getMainSubcategorySelector,
  getUserSelector,
  productActions,
  showToastAction,
  subcategoryActions,
} from "../../store";

import {
  Assets,
  ButtonNew,
  EColors,
  generateErrorToast,
  Preloader,
  useTypedSelector,
  useValidation,
} from "../../common";

import { BasicData, ProductCharacteristics } from "./components";

import {
  Image,
  Container,
  Wrapper,
  Header,
  Title,
  TitleBlock,
  Button,
  SubBarBlock,
  DateBlock,
  DateText,
  FlexStyledContainer,
} from "./styled";
import { getLocale } from "../../types";
import { TCreateProductValidationSchema, TForm } from "./types";
import { nanoid } from "@reduxjs/toolkit";
import useSWR from "swr";

const EditProduct: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const local = getLocale();
  const { accessToken } = useSelector(getUserSelector);
  const menuSections = [
    {
      title: t("main.data"),
      type: "mainData",
      active: false,
    },
    {
      title: t("characteristics"),
      type: "characteristics",
      active: false,
    },
  ];

  const productTypeOptions = [
    { name: t("simple"), value: "simple" },
    { name: t("variated"), value: "variated" },
  ];
  const visibilityOptions = [
    { name: t("show"), value: true },
    { name: t("not.show"), value: false },
  ];
  const statusOptions = [
    { name: t("in.stock"), value: "available" },
    { name: t("out.stock"), value: "notavailable" },
  ];

  const [variationsData, setVariationsData] = useState([
    {
      order: 0,
      title: [{ title: "Color", lang: "en" }],
      type: "",
      values: [
        {
          order: 0,
          bgUrl: EColors.default,
          color: EColors.default,
          title: [{ title: "", lang: "en" }],
        },
      ],
    },
  ]);
  const [typeMethod, setTypeMethod] = useState("update");

  // const [productGroup, setProductGroup] = useState([
  //   { variation: "", value: "" },
  // ]);
  const [activePage, setActivePage] = useState(menuSections[0].type);
  const [form, setForm] = useState<TForm>({
    type: productTypeOptions[0].value,
    show: visibilityOptions[0].value,
    sku: "",
    nameRu: "",
    nameUa: "",
    nameEn: "",
    descriptionEn: "",
    descriptionUa: "",
    descriptionRu: "",
    category: "",
    subcategory: "",
    price: "",
    amount: "",
    discountPrice: "",
    photos: [],
    sellStatus: "",
    brand: "",
    gallery: [],
    video: "",
    preview: "",
    gift: "",
    characteristics: [],
  });

  const { categories } = useTypedSelector(getMainCategorySelector);
  const { subCategoriesByCategory } = useTypedSelector(
    getMainSubcategorySelector
  );

  const { newProduct, photoProduct, products, description, loading } =
    useTypedSelector(getMainProductSelector);
  const { brands } = useTypedSelector(getMainBrandSelector);
  const { logo } = useTypedSelector(getMainSettingSelector);
  const params = {
    token: accessToken || "",
    _id: form.category,
    lang: local,
  };
  const { data: descriptions } = useSWR(
    ["descriptions", params],
    ([, category]) => ApiCategoryService.getDescription(category),
    { revalidateOnFocus: false }
  );

  const categoryOptions = useMemo(
    () =>
      categories?.data?.map((category: any) => ({
        name: category?.title,
        value: category?._id,
      })),
    [categories]
  );
  const subCategoryOption = useMemo(
    () =>
      subCategoriesByCategory.data.map((subCategory: any) => ({
        name: subCategory.title,
        value: subCategory._id,
      })),
    [subCategoriesByCategory]
  );
  const brandOptions = useMemo(
    () =>
      brands?.data?.map((brand: any) => ({
        name: brand?.title,
        value: brand?._id,
      })),
    [brands?.data]
  );

  const selectColor = variationsData
    ?.filter((el) => el.type === "color")
    .reduce(
      (store: Array<string>, prev) => [
        ...store,
        ...(prev?.values?.map((item) => item.color) ?? []),
      ],
      []
    );

  const Requests = {
    removeProduct: (_id: string[]) => {
      dispatch(productActions.removeProduct({ _id }));
    },
    editProduct: async () => {
      const formData = new FormData();
      form.photos.length && formData.append("preview", form.photos[0].file);

      form.photos.map((item) => formData.append("gallery", item.file));
      const photoData = form.photos.length
        ? await ApiProductService.createPhotoProduct({
            data: formData,
            token: accessToken,
          })
        : null;

      const pathArray = form.gallery.map((item) => item.path);

      if (form.type === productTypeOptions[1].value) {
        const data = {
          sku: form.sku,
          sellStatus: form.sellStatus,
          bonusProduct: form.gift?.length ? form.gift : null,
          price: +form.price,
          show: form.show,
          category: form.category,
          subCategory: form.subcategory === "" ? undefined : form.subcategory,
          type: form.type,
          preview: photoData ? photoData.data.preview : form.preview,
          gallery: photoData
            ? [...photoData?.data.gallery, ...pathArray]?.map((item, i) => ({
                order: i + 1,
                image: item,
              }))
            : pathArray.map((item, i) => ({ order: i + 1, image: item })),
          discountPrice: form.discountPrice === "" ? 0 : +form.discountPrice,
          amount: form.amount,
          brand: form.brand,
          video: form.video || null,
          description: [
            {
              lang: "en",
              title: form.nameEn,
              description: form.descriptionEn,
              attributes: generateAttributeFromlang("en"),
            },
            {
              lang: "ua",
              title: form.nameUa,
              description: form.descriptionUa,
              attributes: generateAttributeFromlang("ua"),
            },
            {
              lang: "ru",
              title: form.nameRu,
              description: form.descriptionRu,
              attributes: generateAttributeFromlang("ru"),
            },
          ],
          group: newProduct?.group?._id,
          dimensions: {
            weight: 0.1,
            width: 0.01,
            height: 0.01,
            length: 0.01,
          },
        };
        dispatch(productActions.editProduct({ data, _id: id as string }));
        navigate("/products-management");
      } else {
        const data = {
          sku: form.sku,
          sellStatus: form.sellStatus,
          price: +form.price,
          show: form.show,
          bonusProduct: form.gift?.length ? form.gift : null,
          category: form.category,
          subCategory: form.subcategory === "" ? undefined : form.subcategory,
          type: form.type,
          preview: photoData ? photoData.data.preview : form.preview,
          gallery: photoData
            ? [...photoData?.data.gallery, ...pathArray]?.map((item, i) => ({
                order: i + 1,
                image: item,
              }))
            : pathArray.map((item, i) => ({ order: i + 1, image: item })),
          discountPrice: form.discountPrice === "" ? 0 : +form.discountPrice,
          amount: form.amount,
          brand: form.brand,
          video: form.video || null,
          description: [
            {
              lang: "en",
              title: form.nameEn,
              description: form.descriptionEn,
              attributes: generateAttributeFromlang("en"),
            },
            {
              lang: "ua",
              title: form.nameUa,
              description: form.descriptionUa,
              attributes: generateAttributeFromlang("ua"),
            },
            {
              lang: "ru",
              title: form.nameRu,
              description: form.descriptionRu,
              attributes: generateAttributeFromlang("ru"),
            },
          ],
          dimensions: {
            weight: 0.1,
            width: 0.01,
            height: 0.01,
            length: 0.01,
          },
        };
        dispatch(productActions.editProduct({ data, _id: id as string }));
        navigate("/products-management");
      }
    },
    createProduct: () => {
      const data = {
        sku: form.sku,
        sellStatus: form.sellStatus,
        price: +form.price,
        show: form.show,
        category: form.category,
        subCategory: form.subcategory === "" ? undefined : form.subcategory,
        type: form.type,
        preview: photoProduct?.data?.preview,
        gallery: form.gallery,
        discountPrice: form.discountPrice === "" ? 0 : +form.discountPrice,
        amount: +form.amount,
        brand: form.brand,
        bonusProduct: form.gift?.length ? form.gift : null,
        description: [
          {
            lang: "en",
            title: form.nameEn,
            description: form.descriptionEn,
            attributes: generateAttributeFromlang("en"),
          },
          {
            lang: "ua",
            title: form.nameUa,
            description: form.descriptionUa,
            attributes: generateAttributeFromlang("ua"),
          },
          {
            lang: "ru",
            title: form.nameRu,
            description: form.descriptionRu,
            attributes: generateAttributeFromlang("ru"),
          },
        ],
        group: newProduct?.group?._id,
        dimensions: {
          weight: 0.1,
          width: 0.01,
          height: 0.01,
          length: 0.01,
        },
      };
      dispatch(productActions.createProduct({ data }));
      navigate("/products-management");
    },
  };

  const getProduct = () => {
    dispatch(productActions.getProduct({ _id: id as string }));
    dispatch(productActions.getDescription({ _id: id }));
  };

  useEffect(() => {
    if (+form.amount <= 0) {
      setForm((form) => ({ ...form, sellStatus: statusOptions[1].value }));
    }
    if (form.amount > "0") {
      setForm((form) => ({ ...form, sellStatus: statusOptions[0].value }));
    }
  }, [form.amount]);

  const generateAttributeFromlang = (lang: "ru" | "en" | "ua") => {
    const defaultCharDescriptions = descriptions?.data.data.find(
      (char) => char.lang === local
    )?.attributes;

    const currentLangDescriptions = descriptions?.data.data.find(
      (char) => char.lang === lang
    )?.attributes;

    if (
      !defaultCharDescriptions ||
      !currentLangDescriptions ||
      !form.characteristics
    )
      return [];

    return form.characteristics
      .map((characteristic) => {
        const defaultIndex = defaultCharDescriptions.findIndex(
          (attr) => attr.attribute === characteristic.name
        );

        if (defaultIndex === -1) return null;

        const matchedAttribute = currentLangDescriptions[defaultIndex];

        return {
          key: matchedAttribute.attribute,
          id: matchedAttribute.id,
          value: characteristic[lang],
        };
      })
      .filter((item) => item);
  };

  //eslint-disable-next-line
  const schema = useMemo<TCreateProductValidationSchema>(
    () => ({
      sku: {
        condition: !!form.sku && +form.sku >= 0,
        error: t(
          "enter.the.product.code.or.the.product.already.exists.with.this.code"
        ),
      },
      name: {
        condition: !!form.nameRu && !!form.nameEn && !!form.nameUa,
        error: t("enter.the.product.name.in.all.languages"),
      },
      description: {
        condition:
          !!form.descriptionRu && !!form.descriptionUa && !!form.descriptionEn,
        error: t("enter.the.product.description.in.all.languages"),
      },
      category: {
        condition: !!form.category,
        error: t("choose.a.category"),
      },
      price: {
        condition: !!form.price && +form.price > 0,
        error: t("the.price.should.hit.more.than.zero"),
      },
      amount: {
        condition: !!form.amount && +form.amount >= 0,
        error: t("enter.amount"),
      },
      characteristics: {
        condition: form.characteristics.every(
          (item) => item.en && item.ru && item.ua && item.id && item.name
        ),
        error: t("fill.all.characteristic.fields"),
      },
    }),
    [form]
  );

  const {
    validation,
    enableValidation,
    disableValidation,
    //eslint-disable-next-line
  } = useValidation(schema);

  const Events = {
    backButtonClickHandler: () => {
      navigate("../products-management");
    },
    inputChangeHandler: (e: SyntheticEvent) => {
      const { name, value } = e.target as HTMLInputElement;
      setForm((props) => ({ ...props, [name]: value }));
    },
    inputChangeShowHandler: (e: SyntheticEvent) => {
      const { name, value } = e.target as HTMLInputElement;
      setForm((props) => ({ ...props, [name]: value === "true" }));
    },
    editProductHandler: async () => {
      try {
        enableValidation();
        await validation();
        disableValidation();
        Requests.editProduct();
      } catch (error: any) {
        error?.map((err: string) => {
          dispatch(showToastAction.request(generateErrorToast(err)));
        });
      }
    },
    createProductHandler: async () => {
      try {
        enableValidation();
        await validation();
        Requests.createProduct();
        disableValidation();
      } catch (error: any) {
        error?.map((err: string) => {
          dispatch(showToastAction.request(generateErrorToast(err)));
        });
      }
    },
    removeProductHandler: () => {
      Requests.removeProduct([newProduct._id]);
      navigate("/products-management");
    },
  };

  useEffect(() => {
    getProduct();
  }, []);

  useEffect(() => {
    dispatch(brandActions.getBrands({}));
    dispatch(productActions.getProducts({ limit: 200, lang: local }));
  }, []);

  useEffect(() => {
    dispatch(categoryActions.getCategories({ lang: local, limit: 300 }));
  }, []);

  useEffect(() => {
    if (form.category) {
      dispatch(
        subcategoryActions.getSubcategoriesByCategory({
          _id: form.category,
          lang: local,
        })
      );
    }
  }, [form.category]);

  const generateCharacteristics = () => {
    const currIndex = description.data.findIndex(
      (el: any) => el.lang === local
    );
    const char =
      description.data[0]?.attributes?.map(
        (
          attrEn: { id: string; value: string; key: string },
          attrIndex: number
        ) => ({
          en: attrEn.value,
          name: description.data[currIndex].attributes[attrIndex].key,
          ua: description.data[1]?.attributes[attrIndex].value,
          ru: description.data[2]?.attributes[attrIndex].value,
          id: nanoid(),
        })
      ) || [];
    return char;
  };

  useEffect(() => {
    if (newProduct) {
      setForm({
        ...form,
        sku: newProduct?.sku as any,
        price: newProduct?.price as any,
        amount: newProduct?.amount as any,
        gallery: newProduct?.gallery.map((item: any) => ({
          id: nanoid(),
          path: item.image,
        })),
        sellStatus: newProduct?.sellStatus,
        category: newProduct?.category?._id as any,
        nameEn: description?.data[0]?.title || "",
        nameUa: description?.data[1]?.title || "",
        nameRu: description?.data[2]?.title || "",
        descriptionEn: description?.data[0]?.description,
        descriptionUa: description?.data[1]?.description,
        descriptionRu: description?.data[2]?.description,
        subcategory: newProduct?.subCategory?.subCategory as any,
        show: newProduct?.show,
        type: newProduct?.type,
        discountPrice: newProduct?.discountPrice,
        brand: newProduct?.brand,
        video: newProduct?.video,
        preview: newProduct?.preview,
        gift: newProduct?.bonusProduct ? newProduct?.bonusProduct : "",
        characteristics: generateCharacteristics(),
      });
    }
  }, [newProduct]);

  useEffect(() => {
    if (description) {
      setForm({
        ...form,
        sku: newProduct?.sku,
        gallery: newProduct?.gallery.map((item: any) => ({
          id: nanoid(),
          path: item.image,
        })),
        price: newProduct?.price,
        amount: newProduct?.amount,
        sellStatus: newProduct?.sellStatus,
        category: newProduct?.category?._id,
        nameEn: description?.data[0]?.title,
        nameUa: description?.data[1]?.title,
        nameRu: description?.data[2]?.title,
        descriptionEn: description?.data[0]?.description,
        descriptionUa: description?.data[1]?.description,
        descriptionRu: description?.data[2]?.description,
        subcategory: newProduct?.subCategory?.subCategory,
        show: newProduct?.show,
        type: newProduct?.type,
        discountPrice: newProduct?.discountPrice,
        brand: newProduct?.brand,
        video: newProduct?.video,
        preview: newProduct?.preview,
        gift: newProduct?.bonusProduct ? newProduct?.bonusProduct : "",
        characteristics: generateCharacteristics(),
      });
    }
  }, [descriptions]);

  return (
    <>
      <Container>
        <Header>
          <TitleBlock>
            <Image
              onClick={Events.backButtonClickHandler}
              src={Assets.ARROW_LEFT}
            />
            <Title>{t("edit")}</Title>
            {typeMethod === "update" && (
              <DateBlock>
                <DateText>
                  {t("createdAt.date")}{" "}
                  {moment(newProduct?.createdAt).format("YYYY-MM-DD HH:mm")}
                </DateText>
                <DateText>
                  {t("updatedAt.date")}{" "}
                  {moment(newProduct?.updatedAt).format("YYYY-MM-DD HH:mm")}
                </DateText>
              </DateBlock>
            )}
          </TitleBlock>
          <FlexStyledContainer
            width={typeMethod === "update" ? "430px" : "200px"}
          >
            {typeMethod === "update" && (
              <ButtonNew onClick={Events.removeProductHandler} theme={"red"}>
                {" "}
                {t("delete")}{" "}
              </ButtonNew>
            )}
            {typeMethod === "update" ? (
              <ButtonNew
                theme="green"
                height={46}
                onClick={Events.editProductHandler}
              >
                {t("save")}
              </ButtonNew>
            ) : (
              <ButtonNew
                theme="green"
                height={46}
                onClick={Events.createProductHandler}
              >
                {t("create")}
              </ButtonNew>
            )}
          </FlexStyledContainer>
        </Header>
        <Wrapper>
          <SubBarBlock>
            {menuSections.map((section, key) => (
              <Button
                color={logo?.data?.mainColor}
                key={key}
                value={activePage}
                onClick={() => {
                  setActivePage(section.type);
                }}
                active={section.type === activePage && true}
              >
                {section.title}
              </Button>
            ))}
          </SubBarBlock>
          <>
            {activePage === "mainData" && (
              <>
                {!loading ? (
                  <BasicData
                    id={id}
                    setForm={setForm}
                    products={products}
                    loading={loading}
                    selectColor={selectColor}
                    brandOptions={brandOptions}
                    description={descriptions}
                    newProduct={newProduct}
                    productTypeOptions={productTypeOptions}
                    visibilityOptions={visibilityOptions}
                    statusOptions={statusOptions}
                    Events={Events}
                    variationsData={variationsData}
                    setVariationsData={setVariationsData}
                    form={form}
                    categoryOptions={categoryOptions}
                    subCategoryOption={subCategoryOption}
                  />
                ) : (
                  <Preloader loading={loading} />
                )}
              </>
            )}
            {activePage === "characteristics" && (
              <ProductCharacteristics
                form={form}
                setForm={setForm}
                data={descriptions?.data}
              />
            )}
          </>
        </Wrapper>
      </Container>
    </>
  );
};

export default EditProduct;
