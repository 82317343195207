import { createAction } from '@reduxjs/toolkit'
import {
   EDIT_ORDER,
   GET_ARCHIVE,
   GET_ORDER,
   GET_ORDERS,
   SET_ARCHIVE,
   SET_ORDER,
   SET_ORDERS,
   SET_ORDER_STATE,
   REMOVE_ORDER,
   GET_STATISTIC,
   SET_STATISTIC,
   GET_GRAF,
   SET_GRAF,
   GET_DELIVERY,
   SET_DELIVERY
} from './consts'
import {
   TEditOrderPayload,
   TGetArchivePayload,
   TGetOrderPayload,
   TGetOrdersPayload,
   TSetOrderPayload,
   TSetOrderState
} from './types'

export const orderActions = {
   getOrders: createAction(GET_ORDERS, (payload: TGetOrdersPayload) => ({ payload })),
   setOrders: createAction(SET_ORDERS, (payload) => ({ payload })),
   setOrderState: createAction(SET_ORDER_STATE, (payload: TSetOrderState) => ({ payload })),
   getOrder: createAction(GET_ORDER, (payload: TGetOrderPayload) => ({ payload })),
   setOrder: createAction(SET_ORDER, (payload: TSetOrderPayload) => ({ payload })),
   getStatistic: createAction(GET_STATISTIC, (payload) => ({payload})),
   setStatistic: createAction(SET_STATISTIC, (payload) => ({payload})),
   getGraf: createAction(GET_GRAF, (payload) => ({payload})),
   setGraf: createAction(SET_GRAF, (payload) => ({payload})),
   getArchive: createAction(GET_ARCHIVE, (payload: TGetArchivePayload) => ({ payload })),
   setArchive: createAction(SET_ARCHIVE, (payload) => ({ payload })),
   editOrder: createAction(EDIT_ORDER, (payload: TEditOrderPayload) => ({ payload })),
   removeOrder: createAction(REMOVE_ORDER, (payload) => ({payload})),
   getDelivery: createAction(GET_DELIVERY, (payload) => ({payload})),
   setDelivery: createAction(SET_DELIVERY, (payload) => ({payload}))
}
