import { createReducer } from '@reduxjs/toolkit'
import { TInitialState } from './types'
import { feedbackActions } from "./action";

const initialState: TInitialState = {
	feedbacks: {
		meta: null,
		data: []
	},
	feedback: null,
	loading: false,
	response: null,
	feedbacksProduct: {
		data: [],
		meta: null
	}
}

const reducer = createReducer<TInitialState>(initialState, (builder) => {
	builder
		.addCase(feedbackActions.setFeedbacks, (state, {payload}) => ({...state, feedbacks: payload}))
		.addCase(feedbackActions.setFeedbacksProduct, (state, {payload}) => ({...state, feedbacksProduct: payload}))
		.addCase(feedbackActions.setFeedbackState, (state, {payload}) => ({...state, response: null, ...payload}))
		.addCase(feedbackActions.setFeedback, (state, {payload}) => ({...state, feedback: payload }))
})

export default reducer
