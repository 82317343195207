import React, {
	SyntheticEvent,
	useEffect,
	useMemo,
	useState
} from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from 'moment';

import {
	FiltersWrapper,
	FlexContainer,
	HeaderWrapper,
	SubPagesBar,
	TSection,
	TSort,
	useTypedSelector,
	Table,
	DateInput,
	SearchBar,
	DataTotal,
	Pagination, ButtonNew
} from "../../common";
import { getLocale } from "../../types";
import { availabilityActions, getMainAvailabilitySelector } from "../../store";

import {
	MainContainer,
	Container,
	Header,
	Title,
	TitleBlock,
	FlexStyledContainer,
	ButtonBlock
} from "./styled";
import { TForm } from "./types";

const Availability = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const local = getLocale();

	const [removeButtonState, setRemoveButtonState] = useState<boolean>(false);
	const [selectedItems, setSelectedItems] = useState<number[]>([]);
	const [page, setPage] = useState<number>(0);
	const [searchForm, setSearchForm] = useState<TForm>({
		searchValue: '',
		startDateValue: '',
		endDateValue: ''
	});
	const [searchParams, setSearchParams] = useSearchParams({})
	const [sortParamsForm, setSortParamsForm] = useState({
		sortBy: '',
		order: '' as TSort
	});

	const { availabilitys } = useTypedSelector(getMainAvailabilitySelector);

	const paginationSections: TSection[] = useMemo(
		() => [
			{
				title: t('current.orders'),
				onClickHandler: () => {
		      navigate('../orders')
    	}
			},
			{
				title: t('notify.when.available'),
				active: true
			},
			{
				title: t('archive'),
				onClickHandler: () => {
					navigate('../orders/archive')
				}
			}
		],
		[]
	)

	const tableSortBy = useMemo(() => {
		{
			if (!sortParamsForm.order) {
				return { id: null, desc: false }
			}
			return { id: sortParamsForm.sortBy, desc: sortParamsForm.order > 0 }
		}
	}, [sortParamsForm])

	const Requests = {
		getAvailability: () => {
			dispatch(
				availabilityActions.getAvailabilitys({
					limit: 9,
					page,
					lang: local,
					regex: searchForm.searchValue,
					start: searchForm.startDateValue,
					end: searchForm.endDateValue,
					...sortParamsForm
				})
			)
		},
		removeAvailability: (_id: string) => {
			dispatch(availabilityActions.removeAvailability({_id}));
		},
	}

	useEffect(() => {
		Requests.getAvailability()
	}, [])

	const Events = {
		onPageChangeHandler: ({ selected }: { selected: number }) => {
			setPage(selected)
		},
		onChangeHandler: (e: React.SyntheticEvent) => {
			const input = e.target as HTMLInputElement
			setSearchForm({ ...searchForm, [input.name]: input.value })
			Requests.getAvailability()
		},
		onSubmitSearchHandler: (e: SyntheticEvent) => {
			e.preventDefault()
			Requests.getAvailability()
		},
		editClickHandler: (e: SyntheticEvent, index: number) => {
			navigate(`../orders/profile/${availabilitys.data[index]._id}`)
		},
		sortToggleHandler: (sortBy: string, order: any) => {
			setSortParamsForm({ sortBy, order })
		},
		checkboxClickHandler: (e: SyntheticEvent, hasCheckboxesActiveState: boolean, ckeckedItemsIndex: number[]) => {
			setRemoveButtonState(hasCheckboxesActiveState)
			setSelectedItems(ckeckedItemsIndex)
		},
		removeCheckedItemsHandler: () => {
			selectedItems.forEach((index) => {
				const { _id } = availabilitys?.data[index]
				Requests.removeAvailability(_id)
			})
			setRemoveButtonState(false)
			setSelectedItems([])
			Requests.getAvailability();
		}
	};

	const data = useMemo(
		() =>
			availabilitys.data.map((order:any) => {
				const created_at = order.createdAt
				return {
					id: <>{order.id}</>,
					createdAt:moment(created_at).format('DD.MM.YYYY HH:mm'),
					order: (
						<FlexContainer align="center" justify="center">
							<FlexContainer
								direction="column"
								align="flex-start"
								justify="flex-start"
								gap="4px"
								width="170px"
								style={{ padding: 20 }}>
								<p style={{ textAlign: 'start' }}>{order?.product?.description?.title}</p>
							</FlexContainer>
						</FlexContainer>
					),
					customer: (
						<>
							<p>
								{order.customer?.name} {order.customer?.secondName}
							</p>
							<p>{order.customer?.email}</p>
							<p>{order.customer?.phone}</p>
						</>
					),
				}
			}),
		[availabilitys]
	)

	const columns = useMemo(
		() => [
			{
				Header: t('order.code'),
				accessor: 'id',
				width: 350,
				sortToggleHandler: Events.sortToggleHandler
			},
			{
				Header: t('date'),
				accessor: 'createdAt',
				width: 350,
				sortToggleHandler: Events.sortToggleHandler
			},
			{
				Header: t('order'),
				accessor: 'order',
				width: 350,
				sortToggleHandler: Events.sortToggleHandler
			},
			{
				Header: t('client'),
				accessor: 'customer',
				width: 350,
				sortToggleHandler: Events.sortToggleHandler
			}
		],
		[]
	)

	useEffect(() => {
		Requests.getAvailability()
		setSearchParams({ ...searchParams, page: '' + page })
	}, [page, sortParamsForm, searchForm])

	// Set default page uri
	useEffect(() => {
		setPage(!isNaN(parseInt(searchParams.get('page') as string)) ? parseInt(searchParams.get('page') as string) : 0)
	}, [])

	return (
		<Container>
			<Header>
				<TitleBlock>
					<Title>{t('orders')}</Title>
				</TitleBlock>
				<FlexStyledContainer>
					{removeButtonState && (
						<ButtonBlock>
							<ButtonNew width={195} theme="red" onClick={Events.removeCheckedItemsHandler}>
								{t('remove.all')}
							</ButtonNew>
						</ButtonBlock>
					)}
				</FlexStyledContainer>
			</Header>
			<MainContainer>
				<SubPagesBar sections={paginationSections} />
				<FlexContainer direction="column" gap="30px">
					<HeaderWrapper>
						<FiltersWrapper style={{marginLeft: '-30px'}}>
							<SearchBar
								name="searchValue"
								placeholder={t('search')}
								value={searchForm.searchValue}
								onChange={Events.onChangeHandler}
								onSubmit={Events.onSubmitSearchHandler}
							/>
							<DateInput
								name="startDateValue"
								value={searchForm.startDateValue}
								onChange={Events.onChangeHandler}
							/>
							<DateInput name="endDateValue" value={searchForm.endDateValue} onChange={Events.onChangeHandler} />
						</FiltersWrapper>
						<DataTotal>
							{t("total.orders")} {availabilitys.meta?.totalCount}
						</DataTotal>
					</HeaderWrapper>

					<Table
						columns={columns}
						data={data}
						checkboxClickHandler={Events.checkboxClickHandler}
						sortBy={tableSortBy}
					/>
				</FlexContainer>

				<Pagination
					page={page}
					pageCount={availabilitys.meta ? Math.ceil(availabilitys.meta.totalCount / 9) : 1}
					onPageChange={Events.onPageChangeHandler}
				/>
			</MainContainer>
		</Container>
	);
}

export default Availability;