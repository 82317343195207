export const GET_BRANDS = 'GET_BRANDS'
export const SET_BRANDS = 'SET_BRANDS'
export const SET_BRANDS_STATE = 'SET_BRANDS_STATE'
export const REMOVE_BRAND = 'REMOVE_BRAND'
export const CREATE_BRAND = 'CREATE_BRAND'
export const EDIT_BRAND = 'EDIT_BRAND'

export enum BRAND_RESPONSE {
	CREATED = 'CREATED',
	EDITED = 'EDITED',
	REMOVED = 'REMOVED'
}