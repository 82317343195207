import { useTranslation } from "react-i18next";
import { Assets, ButtonNew, Input } from "../../../../common";
import { getLocale } from "../../../../types";

import { nanoid } from "@reduxjs/toolkit";
import { MainContainer } from "./styled";
import {
  CharacteristicSelect,
  CharBlock,
  SelectWrap,
} from "../../../EditProduct/components/ProductCharacteristics/styled";
import { TCharprops } from "./types";

const ProductCharacteristics = ({ form, setForm, data }: TCharprops) => {
  const { t } = useTranslation();

  const local = getLocale();

  const charOption = data?.data
    .find((char) => char.lang === local)
    ?.attributes.map((item) => ({
      name: item.attribute,
      value: form.category,
    }));

  const addNewCharacteristic = () => {
    setForm({
      ...form,
      characteristics: [
        ...form.characteristics,
        { ua: "", ru: "", en: "", id: nanoid(), name: "" },
      ],
    });
  };

  const inputCharacteristicChangeHandler = (
    e: React.SyntheticEvent<Element, Event>,
    index: number,
    lang: "ua" | "ru" | "en"
  ) => {
    const target = e.target as HTMLInputElement;
    const value = target.value;

    const newCharacteristics = [...form.characteristics];
    newCharacteristics[index][lang] = value;

    setForm({
      ...form,
      characteristics: newCharacteristics,
    });
  };
  const selectChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    index: number
  ) => {
    const selectedName = e.target.value;

    setForm((prev) => {
      const newCharacteristics = [...prev.characteristics];
      newCharacteristics[index].name = selectedName;

      return {
        ...prev,
        characteristics: newCharacteristics,
      };
    });
  };

  return (
    <MainContainer>
      <ButtonNew
        height={40}
        width={139}
        onClick={addNewCharacteristic}
        disabled={
          charOption && charOption?.length <= form.characteristics?.length
        }
      >
        {<>{t("add")}</>}
      </ButtonNew>
      {form.characteristics?.map((item, index) => {
        const availableCharOptions = charOption?.filter(
          (char: { name: string; value: string }) =>
            !form.characteristics.some(
              (formItem) =>
                formItem.name === char.name && formItem.id !== item.id
            )
        );
        return (
          <CharBlock key={item.id}>
            <img
              alt="delete"
              src={Assets.CROSS}
              onClick={() => {
                setForm((prev) => ({
                  ...prev,
                  characteristics: prev.characteristics.filter(
                    (char) => char.id !== item.id
                  ),
                }));
              }}
            />
            <SelectWrap>
              <p>{t("label")}</p>

              <CharacteristicSelect
                value={item.name}
                onChange={(e) => selectChange(e, index)}
              >
                <option disabled hidden value="">
                  {t("chooseAvalue")}
                </option>
                {availableCharOptions?.map(
                  (char: { name: string; value: string }) => {
                    return (
                      <option value={char?.name} key={nanoid()}>
                        {char?.name}
                      </option>
                    );
                  }
                )}
              </CharacteristicSelect>
            </SelectWrap>{" "}
            <Input
              width="210px"
              name={`characteristict[${index}].en`}
              label={t("Characteristic") + " " + `${index + 1} EN`}
              placeholder={t("enter.name.en")}
              value={item.en || ""}
              onChange={(e) => inputCharacteristicChangeHandler(e, index, "en")}
            />
            <Input
              width="210px"
              name={`characteristict[${index}].ua`}
              label={t("Characteristic") + " " + `${index + 1} UA`}
              placeholder={t("enter.name.ua")}
              value={item.ua || ""}
              onChange={(e) => inputCharacteristicChangeHandler(e, index, "ua")}
            />
            <Input
              width="210px"
              name={`characteristict[${index}].ru`}
              label={t("Characteristic") + " " + `${index + 1} RU`}
              placeholder={t("enter.name.ru")}
              value={item.ru || ""}
              onChange={(e) => inputCharacteristicChangeHandler(e, index, "ru")}
            />
          </CharBlock>
        );
      })}
    </MainContainer>
  );
};

export default ProductCharacteristics;
