import { FC } from 'react';

import { useTypedSelector } from "../../hooks";
import { getMainSettingSelector } from "../../../store";

import { EditButton, EditButtonIcon } from './styled';
import { TButtonProps } from './types';

const Button: FC<TButtonProps> = ({ width = 36, height = width, onClick }) => {

  const { logo } = useTypedSelector(getMainSettingSelector);

   return (
      <EditButton color={logo?.data?.mainColor} onClick={onClick} width={width} height={height}>
         <EditButtonIcon />
      </EditButton>
   )
}

export default Button
