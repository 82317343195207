import { createReducer } from '@reduxjs/toolkit'
import { TInitialState } from './types'
import { settingAction } from "./actions";

const initialState: TInitialState = {
	logo: {
		meta: null,
		data: []
	},
	loading: false,
	response: null,
}

const reducer = createReducer<TInitialState>(initialState, (builder) => {
	builder
		.addCase(settingAction.setLogo, (state, {payload}) => ({...state, logo: payload}))
		.addCase(settingAction.setSettingState, (state, {payload}) => ({...state, response: null, ...payload}))
})

export default reducer
