import React, { FC, SyntheticEvent, useEffect, useMemo, useState } from "react";
import { customAlphabet } from "nanoid";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ApiCategoryService, getUserSelector } from "../../store";
import {
  getMainProductSelector,
  productActions,
  RESPONSE,
  showToastAction,
} from "../../store";
import {
  Assets,
  ButtonNew,
  generateErrorToast,
  useTypedSelector,
  useValidation,
} from "../../common";

import { BasicData, ProductCharacteristics, ProductSubBar } from "./components";
import { Image, Container, Wrapper, Header, Title, TitleBlock } from "./styled";
import {
  TCreateProductValidationSchema,
  TForm,
  TFormGroup,
  TProductTypeOptions,
  TStatusOptions,
  TVisibilityOptions,
} from "./types";
import { ApiProductService } from "../../store";
import { getLocale } from "../../types";
import useSWR from "swr";

const CreateProduct: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { accessToken } = useSelector(getUserSelector);

  const productTypeOptions: TProductTypeOptions[] = [
    { name: t("simple"), value: "simple" },
    // { name: t("variated"), value: "variated" },
  ];
  const visibilityOptions: TVisibilityOptions[] = [
    { name: t("show"), value: true },
    { name: t("not.show"), value: false },
  ];
  const statusOptions: TStatusOptions[] = [
    { name: t("in.stock"), value: "available" },
    { name: t("out.stock"), value: "notavailable" },
  ];
  const nanoidNumber = customAlphabet("0123456789", 10);

  const [form, setForm] = useState<TForm>({
    type: productTypeOptions[0].value,
    show: visibilityOptions[0].value,
    sku: nanoidNumber(),
    nameUa: "",
    nameRu: "",
    nameEn: "",
    descriptionUa: "",
    descriptionRu: "",
    descriptionEn: "",
    category: "",
    subcategory: "",
    photos: [],
    video: "",
    price: "",
    amount: "",
    discountPrice: "",
    brand: "",
    sellStatus: statusOptions[0].value,
    gift: "",
    characteristics: [],
  });
  const local = getLocale();

  const params = {
    token: accessToken || "",
    _id: form.category,
    lang: local,
  };
  const { data: descriptions } = useSWR(
    ["descriptions", params],
    ([, category]) => ApiCategoryService.getDescription(category),
    { revalidateOnFocus: false }
  );

  const [formGroup, setFormGroup] = useState<TFormGroup[]>([
    { variation: "", values: "" },
  ]);

  const [activePage, setActivePage] = useState<string>("mainData");

  const { response, variationsBulk, groups, products } = useTypedSelector(
    getMainProductSelector
  );

  const SKU = products?.data?.map((el) => el.sku);

  useEffect(() => {
    if (+form.amount <= 0) {
      setForm((form) => ({ ...form, sellStatus: statusOptions[1].value }));
    }
    if (form.amount > "0") {
      setForm((form) => ({ ...form, sellStatus: statusOptions[0].value }));
    }
  }, [form.amount]);

  const generateAttributeFromlang = (lang: "ru" | "en" | "ua") => {
    const defaultCharDescriptions = descriptions?.data.data.find(
      (char) => char.lang === local
    )?.attributes;

    const currentLangDescriptions = descriptions?.data.data.find(
      (char) => char.lang === lang
    )?.attributes;

    if (
      !defaultCharDescriptions ||
      !currentLangDescriptions ||
      !form.characteristics
    )
      return [];

    return form.characteristics
      .map((characteristic) => {
        const defaultIndex = defaultCharDescriptions.findIndex(
          (attr) => attr.attribute === characteristic.name
        );

        if (defaultIndex === -1) return null;

        const matchedAttribute = currentLangDescriptions[defaultIndex];

        return {
          key: matchedAttribute.attribute,
          id: matchedAttribute.id,
          value: characteristic[lang],
        };
      })
      .filter((item) => item);
  };

  const Requests = {
    createProduct: async () => {
      const formData = new FormData();
      formData.append("preview", form.photos[0].file);
      form.photos.map((item) => formData.append("gallery", item.file));
      const photoData = await ApiProductService.createPhotoProduct({
        data: formData,
        token: accessToken,
      });

      if (form.type === productTypeOptions[1]?.value) {
        const data = {
          sku: form.sku,
          bonusProduct: form.gift.length ? form.gift : null,
          sellStatus: form.sellStatus,
          price: +form.price,
          show: form.show,
          category: form.category,
          subCategory: form.subcategory === "" ? undefined : form.subcategory,
          type: form.type,
          preview: photoData.data.preview,
          gallery: photoData.data.gallery?.map((item: string, i: number) => ({
            order: i + 1,
            image: item,
          })),

          amount: form.amount,
          discountPrice: form.discountPrice === "" ? 0 : +form.discountPrice,
          brand: form.brand,
          video: form.video || null,
          description: [
            {
              lang: "en",
              title: form.nameEn,
              description: form.descriptionEn,
              attributes: "",
            },
            {
              lang: "ua",
              title: form.nameUa,
              description: form.descriptionUa,
              attributes: "",
            },
            {
              lang: "ru",
              title: form.nameRu,
              description: form.descriptionRu,
              attributes: "",
            },
          ],
          group: groups.data.slice(-1)[0]._id,
          dimensions: {
            weight: 0.1,
            width: 0.01,
            height: 0.01,
            length: 0.01,
          },
        };

        dispatch(productActions.createProduct({ data }));
        navigate("/products-management");
      } else {
        const data = {
          sku: form.sku,
          bonusProduct: form.gift.length ? form.gift : null,
          sellStatus: form.sellStatus,
          price: +form.price,
          show: form.show,
          category: form.category,
          subCategory: form.subcategory === "" ? undefined : form.subcategory,
          type: form.type,
          preview: photoData.data.preview,
          gallery: photoData.data.gallery?.map((item: string, i: number) => ({
            order: i + 1,
            image: item,
          })),
          amount: form.amount,
          brand: form.brand,
          video: form.video || null,
          discountPrice: form.discountPrice === "" ? 0 : +form.discountPrice,
          description: [
            {
              lang: "en",
              title: form.nameEn,
              description: form.descriptionEn,
              attributes: generateAttributeFromlang("en"),
            },
            {
              lang: "ua",
              title: form.nameUa,
              description: form.descriptionUa,
              attributes: generateAttributeFromlang("ua"),
            },
            {
              lang: "ru",
              title: form.nameRu,
              description: form.descriptionRu,
              attributes: generateAttributeFromlang("ru"),
            },
          ],
          dimensions: {
            weight: 0.1,
            width: 0.01,
            height: 0.01,
            length: 0.01,
          },
        };

        dispatch(productActions.createProduct({ data }));
        navigate("/products-management");
      }
    },
  };

  const schema = useMemo<TCreateProductValidationSchema>(
    () => ({
      sku: {
        condition: !!form.sku && !SKU.includes(form.sku) && +form.sku >= 0,
        error: t(
          "enter.the.product.code.or.the.product.already.exists.with.this.code"
        ),
      },
      name: {
        condition: !!form.nameRu && !!form.nameEn && !!form.nameUa,
        error: t("enter.the.product.name.in.all.languages"),
      },
      description: {
        condition:
          !!form.descriptionRu && !!form.descriptionUa && !!form.descriptionEn,
        error: t("enter.the.product.description.in.all.languages"),
      },
      category: {
        condition: !!form.category,
        error: t("choose.a.category"),
      },
      price: {
        condition: !!form.price && +form.price > 0,
        error: t("the.price.should.hit.more.than.zero"),
      },
      discountPrice: {
        condition: +form.discountPrice < +form.price,
        error: t("the.price.with.discount.cannot.be.more.than.price.product"),
      },

      amount: {
        condition: !!form.amount && +form.amount >= 0,
        error: t("enter.amount"),
      },

      photo: {
        condition: form.photos.length !== 0,
        error: t("enter.photo"),
      },
      characteristics: {
        condition: form.characteristics.every(
          (item) => item.en && item.ru && item.ua && item.id && item.name
        ),
        error: t("fill.all.characteristic.fields"),
      },
    }),
    [form]
  );

  const { validation, enableValidation, disableValidation } =
    useValidation(schema);

  const Events = {
    backButtonClickHandler: () => {
      navigate("../products-management");
    },
    inputChangeHandler: (e: SyntheticEvent) => {
      const { name, value } = e.target as HTMLInputElement;
      setForm((props) => ({ ...props, [name]: value }));
    },
    inputChangeShowHandler: (e: SyntheticEvent) => {
      const { name, value } = e.target as HTMLInputElement;
      setForm((props) => ({ ...props, [name]: value === "true" }));
    },

    createProductHandler: async () => {
      try {
        enableValidation();
        await validation();
        Requests.createProduct();
        disableValidation();
      } catch (error: any) {
        error?.map((err: string) => {
          dispatch(showToastAction.request(generateErrorToast(err)));
        });
      }
    },
  };

  useEffect(() => {
    if (response === RESPONSE.CREATED_VARIATION_BULK) {
      setFormGroup(() =>
        variationsBulk?.data?.map((g: any) => ({
          variation: g?.variation?._id,
          values: g?.values?.map((el: any) => el?._id),
        }))
      );
      const data = {
        variations: formGroup,
      };
      dispatch(productActions.createGroup({ data }));
    }
    if (response === RESPONSE.CREATED_GROUP) {
      dispatch(productActions.getGroups({ limit: 100000 }));
    }
  }, [response]);

  useEffect(() => {
    dispatch(productActions.getProducts({ limit: 200, lang: local }));
    dispatch(productActions.getGroups({ limit: 1000 }));
  }, []);

  return (
    <Container>
      <Header>
        <TitleBlock>
          <Image
            onClick={Events.backButtonClickHandler}
            src={Assets.ARROW_LEFT}
          />
          <Title>{t("create.product")}</Title>
        </TitleBlock>
        <ButtonNew theme="green" onClick={Events.createProductHandler}>
          {t("create")}
        </ButtonNew>
      </Header>
      <Wrapper>
        <ProductSubBar setActivePage={setActivePage} activePage={activePage} />
        <>
          {activePage === "mainData" && (
            <>
              <BasicData
                products={products}
                // isDuplicate={isDuplicate}
                // selectText={selectText}
                productTypeOptions={productTypeOptions}
                visibilityOptions={visibilityOptions}
                statusOptions={statusOptions}
                Events={Events}
                // variationsData={variationsData}
                form={form}
                setForm={setForm}
                // selectColor={selectColor}
              />
            </>
          )}
          {activePage === "characteristics" && (
            <ProductCharacteristics
              form={form}
              setForm={setForm}
              data={descriptions?.data}
            />
          )}
        </>
      </Wrapper>
    </Container>
  );
};

export default CreateProduct;
