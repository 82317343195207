import axios, {AxiosRequestConfig, AxiosResponse} from 'axios'

export class HttpService {
	static async request<T = any>(axiosConfig: AxiosRequestConfig): Promise<AxiosResponse<T>> {
		try {
			return await axios.request(axiosConfig)
		} catch (e) {
			throw new Error('Axios error:' + e)
		}
	}
}