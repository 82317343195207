import { call, put, takeLatest } from "redux-saga/effects";
import { TDataWrapper, TResponse } from "../types";
import { bannerActions } from "./actions";
import { ApiBannerService } from "./api.service";
import { RESPONSE } from "./const";
import {
  TCreateBannerPayload,
  TEditBannerPayload,
  TRemoveBannerPayload,
  TSaveBannersPayload,
} from "./types";
import { getAccessToken } from "../user";
import { showToastAction } from "../toasts";
import { generateErrorToast, generateSuccessToast } from "../../common";
import { i18n } from "../../common/config";

function* getBannersWorker() {
  yield put(bannerActions.setBannerState({ loading: true }));

  const token: string = yield call(getAccessToken);

  try {
    const response: TResponse = yield call(
      [ApiBannerService, ApiBannerService.getBanners],
      token
    );

    if (response.data.data) {
      yield put(bannerActions.setBanners(response.data.data));
    }
  } catch (e) {
    yield put(bannerActions.setBanners([]));
    yield put(
      showToastAction.request(
        generateErrorToast(i18n.t("error.getting.banners") as string)
      )
    );
  }

  yield put(bannerActions.setBannerState({ loading: false }));
}

function* removeBannerWorker({ payload }: TDataWrapper<TRemoveBannerPayload>) {
  yield put(bannerActions.setBannerState({ loading: true }));

  const { _id } = payload;
  const token: string = yield call(getAccessToken);

  try {
    yield call([ApiBannerService, ApiBannerService.removeBanner], {
      token,
      _id,
    });
    yield put(
      showToastAction.request(
        generateSuccessToast(i18n.t("banner.removed.successfully") as string)
      )
    );
  } catch (e) {
    yield put(
      showToastAction.request(
        generateErrorToast(i18n.t("failed.to.remove.banner") as string)
      )
    );
  }

  yield put(bannerActions.setBannerState({ loading: false }));
}

function* saveBannersWorker({ payload }: TDataWrapper<TSaveBannersPayload>) {
  yield put(bannerActions.setBannerState({ loading: true }));

  const { banners } = payload;

  for (const banner of banners) {
    if (banner._id) {
      const { data, _id } = banner;
      yield call(editBanner, { data, _id });
    } else {
      const { data } = banner;
      yield call(createBanner, { data });
    }
  }
  yield put(
    showToastAction.request(
      generateSuccessToast(i18n.t("banner.was.updating.successfully") as string)
    )
  );
  yield put(
    bannerActions.setBannerState({ loading: false, response: RESPONSE.SAVED })
  );
}

function* editBanner({ data, _id }: TEditBannerPayload) {
  const token: string = yield call(getAccessToken);

  try {
    yield call([ApiBannerService, ApiBannerService.editBanner], {
      token,
      data,
      _id,
    });
  } catch (e) {}
}

function* createBanner({ data }: TCreateBannerPayload) {
  const token: string = yield call(getAccessToken);

  try {
    yield call([ApiBannerService, ApiBannerService.createBanner], {
      token,
      data,
    });
    yield put(bannerActions.getBanners());
  } catch (e) {}
}

export function* bannerWatcher() {
  yield takeLatest(bannerActions.getBanners, getBannersWorker);
  yield takeLatest(bannerActions.removeBanner, removeBannerWorker);
  yield takeLatest(bannerActions.saveBanners, saveBannersWorker);
}
