import styled from "styled-components";

export const LabelPhoto = styled.label`
  width: 165px;
  height: 165px;
  border: 1px dashed blue;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  input {
    width: 4px;
    height: 4px;
    display: none;
  }
  img {
    width: 40px;
    height: 40px;
  }
`;
export const ImageWrap = styled.div`
  position: relative;
  img {
    display: block;
    width: 165px;
    height: 165px;
    border-radius: 5px;
    object-fit: cover;
  }
  button {
    position: absolute;
    width: 30px;
    height: 30px;
    border: none;
    background-color: #000000;
    opacity: 0.3;
    cursor: pointer;
    border-radius: 5px 0 5px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 14px;
      height: 18px;
      border-radius: 0;
    }
  }
`;
