import React from 'react';
import { Component } from './styled';

const NotFount = () => {
	return (
		<Component>
			404
		</Component>
	);
};

export default NotFount;