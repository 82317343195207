import {AxiosResponse} from 'axios'
import { HttpService } from '../http.service'
import {TResponse} from '../types'
import {CLIENT_URL} from './config'
import {
   TEditClientRequestPayload,
   TGetClientRequestPayload,
   TGetClientsRequestPayload,
   TRemoveClientRequestPayload
} from './types'

export class ApiClientService extends HttpService {
   static getClients({
      token,
      limit = 10,
      page = 0,
      lang = 'ua',
      start = '',
      end = '',
      regex = '',
      sortBy = '',
      order = ''
   }: TGetClientsRequestPayload): Promise<AxiosResponse<TResponse>> {
      return this.request({
         url: `${CLIENT_URL.getClients}`,
         method: 'GET',
         headers: {
            Authorization: token
         },
         params: {
            limit,
            skip: limit * page,
            lang,
            regex,
            start,
            end,
            sortBy,
            order
         }
      })
   }

   static getClient({ token, _id }: TGetClientRequestPayload): Promise<AxiosResponse<TResponse>> {
      return this.request({
         url: `${CLIENT_URL.getClient}/${_id}`,
         method: 'GET',
         headers: {
            Authorization: token
         }
      })
   }

   static editClient({ token, _id, data }: TEditClientRequestPayload): Promise<AxiosResponse<TResponse>> {
      return this.request({
         url: `${CLIENT_URL.editClient}/${_id}`,
         method: 'PATCH',
         data,
         headers: {
            Authorization: token
         }
      })
   }

   static removeClient({ token, _id }: TRemoveClientRequestPayload): Promise<AxiosResponse<TResponse>> {
      return this.request({
         url: `${CLIENT_URL.removeClient}/${_id}`,
         method: 'DELETE',
         headers: {
            Authorization: token
         }
      })
   }
}
