import React, { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { nanoid } from "@reduxjs/toolkit";
import {
  colors,
  DropDownList,
  FlexContainer,
  Input,
  Textarea,
} from "../../../../common";

import { FormContainer, MainContainer, Label, FormBlock } from "../../styled";
import plusicon from "../../../../common/assets/icons/plus-grey.svg";
import remove from "../../../../common/assets/icons/delete.svg";
import { Catalog } from "../Catalog";
import { VariationContainer } from "../VariationContainer";
import { TForm } from "../../types";
import { ImageWrap, LabelPhoto } from "./styled";
import { YouTubeVideo } from "./YoutubeVideo";
import { TProducts } from "../../../../store/product/types";

const BasicData = ({
  Events,
  selectColor,
  form,
  variationsData,
  setVariationData,
  productTypeOptions,
  visibilityOptions,
  statusOptions,
  setForm,
  isDuplicate,
  products,
}: {
  setForm: React.Dispatch<React.SetStateAction<TForm>>;
  products: TProducts;
  [x: string]: any;
}) => {
  const { t } = useTranslation();
  const [previewURLs, setPreviewURLs] = useState<
    { id: string; preview: string }[]
  >([]);
  const EventsVariations = {
    onAddVariationClickHandler: () => {
      setVariationData(
        variationsData.concat([
          {
            order: variationsData.length + 1,
            title: [{ title: "", lang: "en" }],
            type: "",
            values: [
              {
                order: 1,
                bgUrl: "",
                color: "",
                title: [{ title: "", lang: "en" }],
              },
            ],
          },
        ] as typeof variationsData)
      );
    },
  };

  const productOptions = products.data.map((item) => ({
    name: item.description.title,
    value: item._id,
  }));

  const setImage = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const fileObjects = Array.from(files).map((file) => ({
        id: nanoid(),
        file: file,
      }));
      setForm((prev) => ({
        ...prev,
        photos: [...prev.photos, ...fileObjects],
      }));
    }
  };

  const generatePreview = (file: File, id: string) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewURLs((prev) => [
        ...prev,
        { preview: reader.result as string, id },
      ]);
    };
    reader.readAsDataURL(file);
  };

  const deleteImage = (id: string) => {
    setForm((prev) => ({
      ...prev,
      photos: prev.photos.filter((item) => item.id !== id),
    }));
  };

  useEffect(() => {
    if (form.photos) {
      setPreviewURLs([]);
      form.photos.forEach((photo: { id: string; file: File }) => {
        generatePreview(photo.file, photo.id);
      });
    }
  }, [form.photos]);

  return (
    <MainContainer>
      <FormContainer>
        <FlexContainer gap="62px">
          <DropDownList
            name="type"
            label={t("product.type")}
            options={productTypeOptions}
            placeholder={t("enter.type.product")}
            value={form.type}
            onChange={Events.inputChangeHandler}
          />
          {form.type === productTypeOptions[0].value && (
            <>
              <DropDownList
                name="show"
                label={t("visibility")}
                options={visibilityOptions}
                value={form.show}
                onChange={Events.inputChangeShowHandler}
              />
              <Input
                name="sku"
                type={"number"}
                label={t("SKU")}
                placeholder={t("enter.code")}
                value={form.sku}
                onChange={Events.inputChangeHandler}
                disabled
              />
              <DropDownList
                name="gift"
                placeholder={t("chooseAgift")}
                label={t("gift")}
                options={productOptions}
                value={form.gift}
                onChange={Events.inputChangeHandler}
              />
            </>
          )}
        </FlexContainer>
        <FormBlock>
          <Input
            name="nameEn"
            width="270px"
            label={t("name")}
            placeholder={t("enter.name.en")}
            value={form.nameEn}
            onChange={Events.inputChangeHandler}
          />
          <Input
            name="nameUa"
            width="270px"
            label={t("name")}
            placeholder={t("enter.name.ua")}
            value={form.nameUa}
            onChange={Events.inputChangeHandler}
          />
          <Input
            name="nameRu"
            width="270px"
            label={t("name")}
            placeholder={t("enter.name.ru")}
            value={form.nameRu}
            onChange={Events.inputChangeHandler}
          />
          <Textarea
            width="275px"
            name="descriptionEn"
            height="260px"
            placeholder={t("enter.description.en")}
            label={t("description")}
            onChange={Events.inputChangeHandler}
          >
            {form.descriptionEn}
          </Textarea>
          <Textarea
            width="275px"
            name="descriptionUa"
            height="260px"
            placeholder={t("enter.description.ua")}
            label={t("description")}
            onChange={Events.inputChangeHandler}
          >
            {form.descriptionUa}
          </Textarea>
          <Textarea
            width="275px"
            name="descriptionRu"
            height="260px"
            placeholder={t("enter.description.ru")}
            label={t("description")}
            onChange={Events.inputChangeHandler}
          >
            {form.descriptionDe}
          </Textarea>
        </FormBlock>

        {form.type === productTypeOptions[0].value ? (
          <FlexContainer gap="30px">
            <FlexContainer gap="62px">
              <Input
                name="price"
                type="number"
                label={t("price")}
                placeholder={t("enter.price")}
                value={form.price}
                onChange={Events.inputChangeHandler}
              />
              <Input
                name="discountPrice"
                type="number"
                label={t("discount.price")}
                placeholder={t("enter.discount.price")}
                value={form.discountPrice}
                onChange={Events.inputChangeHandler}
              />
            </FlexContainer>
            <FlexContainer gap="62px">
              <Input
                name="amount"
                type={"number"}
                label={t("amount")}
                placeholder={t("enter.amount")}
                value={form.amount}
                onChange={Events.inputChangeHandler}
              />
              <DropDownList
                name="sellStatus"
                label={t("status")}
                options={statusOptions}
                placeholder={t("enter.status")}
                value={form.sellStatus}
                onChange={Events.inputChangeHandler}
              />
            </FlexContainer>
            <Input
              name="video"
              type={"text"}
              label={t("video")}
              placeholder={t("enter.video")}
              value={form.video}
              onChange={Events.inputChangeHandler}
            />
            {form.video && <YouTubeVideo videoLink={form.video} />}
          </FlexContainer>
        ) : (
          <VariationContainer
            isDuplicate={isDuplicate}
            selectColor={selectColor}
            setVariationData={setVariationData}
            EventsVariations={EventsVariations}
            variationsData={variationsData}
          />
        )}
      </FormContainer>

      <FlexContainer direction="column" align="center" gap="">
        <Catalog setForm={setForm} form={form} />
        <FlexContainer
          direction="column"
          style={{
            borderTop: `1px solid ${colors.lines}`,
            marginTop: "8px",
            padding: "24px 0 24px 30px",
          }}
        >
          <Label>{t("photo")}</Label>
          <FlexContainer gap="20px">
            <LabelPhoto>
              <img alt="add" src={plusicon} />
              <input
                type="file"
                multiple
                accept="image/*"
                onChange={setImage}
              />
            </LabelPhoto>
            {previewURLs.map((item) => (
              <ImageWrap key={nanoid()}>
                {form.photos?.length > 1 && (
                  <button onClick={() => deleteImage(item.id)}>
                    <img alt="delete" src={remove} />
                  </button>
                )}
                <img alt="gallery" src={item.preview} />
              </ImageWrap>
            ))}
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    </MainContainer>
  );
};

export default BasicData;
