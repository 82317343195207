import styled from 'styled-components'
import { Assets } from '../../assets'
import { TContainer, TStyledInputProps, TSwitchPasswordVisiableButtonProps } from './types'
import { colors, FLEX, FONT } from "../../styles";

export const SwitchPasswordVisiableButton = styled.img<TSwitchPasswordVisiableButtonProps>`
   position: absolute;
   z-index: 2;
   top: 40px;
   right: 20px;
   cursor: pointer;
   width: 22px;
   height: 16px;
   content: url(${({ visiable }) => (visiable ? Assets.SHOW_PASSWORD_ICON : Assets.HIDE_PASSWORD_ICON)});
`

export const Container = styled.div<TContainer>`
   ${({ align }) => FLEX({ direction: 'column', align, justify: 'center' })}
   width: ${({ width }) => width || 'auto'};
   max-width: 100%;
   position: relative;
`;

export const StyledInput = styled.input<TStyledInputProps>`
   width: 100%;

   margin: 1px;
   padding: 13px 14px;

   outline: none;

   border: 2px solid #D1D1D1;
   border-radius: 8px;

   background: ${colors.white};

   ${FONT({
      size: '14px',
      weight: '700',
      color: colors.black,
   })}

   transition: 0.2s;

   &:focus {
      border: 2px solid  ${({ color }) => `${color}`};
      background: ${colors.white};
   }

   &::placeholder {
      ${FONT({
         size: '16px',
         weight: '400',
         color: colors.gray,
      })}
   }

   @media (max-width: 1600px) {
      width: 100%;
   }
`;

export const Error = styled.span`
  width: 100%;
  height: 5px;

  ${FONT({
   size: '12px',
   weight: '700',
   align: 'right',
   color: colors.red,
})}
`
