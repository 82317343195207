import { AxiosResponse } from "axios";
import { HttpService } from "../http.service";
import { TResponse } from "../types";

import {
  TCreateCalculateRequestPayload,
  TGetDeliveryRefRequestPayload,
  TGetDeliveryRequestPayload,
  TGetDeliveryWarehouseRequestPayload,
  TGetDeliveryConfigRequestPayload,
  TUpdateRequestPayload,
  TCreateReferralCode,
  TReferralResponse,
  TDeleteReferralCode,
} from "./types";
import { DELIVERY_URL } from "./config";

export class ApiDeliveryService extends HttpService {
  static getDelivery({
    limit = 10000,
    token,
    query = "",
  }: TGetDeliveryRequestPayload): Promise<AxiosResponse<TResponse>> {
    return this.request({
      url: `${DELIVERY_URL.getDelivery}`,
      method: "GET",
      headers: {
        Authorization: token,
      },
      params: {
        query,
        limit,
      },
    });
  }

  static getDeliveryConfig({
    token,
  }: TGetDeliveryConfigRequestPayload): Promise<AxiosResponse<TResponse>> {
    return this.request({
      url: `${DELIVERY_URL.deliveryConfig}`,
      method: "GET",
      headers: {
        Authorization: token,
      },
    });
  }

  static updateDelivery({
    data,
    token,
  }: TUpdateRequestPayload): Promise<AxiosResponse<TResponse>> {
    return this.request<TResponse>({
      url: DELIVERY_URL.updateDeliveryConfig,
      method: "POST",
      data: data,
      headers: {
        Authorization: token,
      },
    });
  }

  static getDeliveryWarehouse({
    token,
    limit = 100,
    cityRef = "",
  }: TGetDeliveryWarehouseRequestPayload): Promise<AxiosResponse<TResponse>> {
    return this.request({
      url: `${DELIVERY_URL.getDeliveryWarehouse}`,
      method: "GET",
      headers: {
        Authorization: token,
      },
      params: {
        limit,
        cityRef,
      },
    });
  }

  static getDeliveryRef({
    limit = 10,
    token,
    ref = "",
  }: TGetDeliveryRefRequestPayload): Promise<AxiosResponse<TResponse>> {
    return this.request({
      url: `${DELIVERY_URL.getDeliveryRef}`,
      method: "GET",
      headers: {
        Authorization: token,
      },
      params: {
        ref,
        limit,
      },
    });
  }

  static createCalculate({
    data,
    token,
  }: TCreateCalculateRequestPayload): Promise<AxiosResponse<TResponse>> {
    return this.request<TResponse>({
      url: DELIVERY_URL.createCalculate,
      method: "POST",
      data: data,
      headers: {
        Authorization: token,
      },
    });
  }

  static createRefferal({
    code,
    bonuses,
    token,
  }: TCreateReferralCode): Promise<AxiosResponse> {
    return this.request({
      url: DELIVERY_URL.referral,
      method: "POST",
      data: { code, bonuses },
      headers: {
        Authorization: token,
      },
    });
  }
  static getReferral(token: string): Promise<AxiosResponse<TReferralResponse>> {
    return this.request({
      url: DELIVERY_URL.referral,
      method: "GET",
      headers: {
        Authorization: token,
      },
    });
  }
  static deleteReferral({
    id,
    token,
  }: TDeleteReferralCode): Promise<AxiosResponse> {
    return this.request({
      url: `${DELIVERY_URL.referral}/${id}`,
      method: "DELETE",

      headers: { Authorization: token },
    });
  }
}
