import { call, put, takeLatest } from 'redux-saga/effects'
import { TDataWrapper, TResponse } from '../types'
import { getAccessToken } from '../user'
import { deliveryActions } from './actions'
import {
	TCreateCalculatePayload,
	TGetDeliveryConfigPayload,
	TGetDeliveryPayload,
	TGetDeliveryRefPayload,
	TGetDeliveryWarehouseRequestPayload,
	TUpdateDeliveryConfigWorker
} from './types'
import {ApiDeliveryService} from "./api.serrvice";
import { showToastAction } from '../toasts'
import { generateErrorToast, generateSuccessToast } from '../../common'
import { i18n } from '../../common/config'

function* getDeliveryWorker({ payload }: TDataWrapper<TGetDeliveryPayload>) {
	const token: string = yield call(getAccessToken)

	try {
		const response: TResponse = yield call([ApiDeliveryService, ApiDeliveryService.getDelivery], { ...payload, token })
		if (response.data) yield put(deliveryActions.setDelivery(response.data))
	} catch (e) {
		yield put(deliveryActions.setDelivery({ data: [], meta: null }))
	}
}

function* getDeliveryConfigWorker({ payload }: TDataWrapper<TGetDeliveryConfigPayload>) {
	yield put(deliveryActions.setDeliveryState({ loading: true }))
	const token: string = yield call(getAccessToken)

	try {
		const response: TResponse = yield call([ApiDeliveryService, ApiDeliveryService.getDeliveryConfig], { ...payload, token })
		if (response.data) yield put(deliveryActions.setDeliveryConfig(response.data))
	} catch (e) {
		yield put(deliveryActions.setDelivery(null))
	}
	yield put(deliveryActions.setDeliveryState({ loading: false }))
}


function* getDeliveryRefWorker({ payload }: TDataWrapper<TGetDeliveryRefPayload>) {
	const token: string = yield call(getAccessToken)

	try {
		const response: TResponse = yield call([ApiDeliveryService, ApiDeliveryService.getDeliveryRef], { ...payload, token })
		if (response.data) yield put(deliveryActions.setDeliveryRef(response.data))
	} catch (e) {
		yield put(deliveryActions.setDeliveryRef({ data: [], meta: null }))
	}
}

function* getDeliveryWarehouseWorker({ payload }: TDataWrapper<TGetDeliveryWarehouseRequestPayload>) {
	const token: string = yield call(getAccessToken)

	try {
		const response: TResponse = yield call([ApiDeliveryService, ApiDeliveryService.getDeliveryWarehouse], { ...payload, token })
		if (response.data) yield put(deliveryActions.setDeliveryWarehouse(response.data))
	} catch (e) {
		yield put(deliveryActions.setDeliveryWarehouse({ data: [], meta: null }))
	}
}

function* createCalculateWorker({ payload }: TDataWrapper<TCreateCalculatePayload>) {
	const token: string = yield call(getAccessToken)

	try {
		const response: TResponse = yield call([ApiDeliveryService, ApiDeliveryService.createCalculate], { ...payload, token })
		if (response.data.data) yield put(deliveryActions.setCalculate({...response.data.data}))
	} catch (e) {}
}

function* updateDeliveryConfigWorker({ payload }: TDataWrapper<TUpdateDeliveryConfigWorker>) {
	yield put(deliveryActions.setDeliveryState({ loading: true }))
	const token: string = yield call(getAccessToken)
	try {
		const response: TResponse = yield call([ApiDeliveryService, ApiDeliveryService.updateDelivery], { ...payload, token })
    yield put(showToastAction.request(generateSuccessToast(i18n.t("delivery.updated.successfully") as string)));
   } catch (e) {
      yield put(showToastAction.request(generateErrorToast(i18n.t("delivry.updated.erorr") as string)));
   }
}

export function* deliveryWatcher() {
	yield takeLatest(deliveryActions.getDelivery, getDeliveryWorker)
	yield takeLatest(deliveryActions.getDeliveryRef, getDeliveryRefWorker)
	yield takeLatest(deliveryActions.getDeliveryWarehouse, getDeliveryWarehouseWorker)
	yield takeLatest(deliveryActions.createCalculate, createCalculateWorker)
	yield takeLatest(deliveryActions.getDeliveryConfig, getDeliveryConfigWorker)
	yield takeLatest(deliveryActions.updateDeliveryConfig, updateDeliveryConfigWorker)
}
