import React, { useState } from 'react';
import {
	ContainerPicker,
	PopupContainer,
	Wrapper,
	ColorPickerText
} from "../../../../common/components/ColorPicker/styled";
import { EColors } from "../../../../common";

const ColorPickerProduct = ({EventsVariations, variationTitle, formVariations, selectColor }:any) => {
	const [popupIsOpen, setPopupIsOpen] = useState(false);

	const handleClick = (event:any, color:string) => {
		EventsVariations.inputChangeHandler(event, variationTitle.order, formVariations.order, color)
		setPopupIsOpen(false)
	};

	return (
		<Wrapper>
			<ContainerPicker
				onClick={() => setPopupIsOpen(true)}
				style={{background: formVariations.color}}>
				{formVariations.color === '' && <ColorPickerText>{'Натисніть, щоб вибрати колір'}</ColorPickerText> }
			</ContainerPicker>
			{
				popupIsOpen &&
				<PopupContainer>
					{
						Object.values(EColors)
							.map((color, idx) =>
							selectColor.includes(color) ? (
								<></>
							):(
            <ContainerPicker
									key={idx}
									onClick={(event) => handleClick(event, color)}
									style={{background: color}}
								/>
							)
							)
								
					}
				</PopupContainer>
			}
		</Wrapper>
	);
};

export default ColorPickerProduct;