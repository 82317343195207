import {SECTION_URL} from './config'
import {
   TCreateSectionRequestPayload,
   TEditSectionRequestPayload,
   TGetSectionRequestPayload,
   TRemoveSectionRequestPayload,
   TSectionsRequestPayload
} from './types'
import {TGetCategoryRequestPayload} from "../category/types";
import {AxiosResponse} from "axios";
import {TResponse} from "../types";
import { HttpService } from '../http.service';

export class ApiSectionService extends HttpService {
   static getSections({ token, limit = 10, page = 0, order, sortBy, lang = 'ua' }: TSectionsRequestPayload) {
      return this.request({
         url: SECTION_URL.getSections,
         method: 'GET',
         headers: {
            Authorization: token
         },
         params: {
            limit,
            skip: limit * page,
            order,
            sortBy,
            lang
         }
      })
   }

   static createSection({ token, data }: TCreateSectionRequestPayload) {
      return this.request({
         url: SECTION_URL.createSection,
         method: 'POST',
         data,
         headers: {
            Authorization: token
         }
      })
   }

   static editSection({ token, data, id: _id }: TEditSectionRequestPayload) {
      return this.request({
         url: `${SECTION_URL.editSection}/${_id}`,
         method: 'PATCH',
         data,
         headers: {
            Authorization: token
         }
      })
   }

   static removeSection({ token, id }: TRemoveSectionRequestPayload) {
      return this.request({
         url: `${SECTION_URL.removeSection}/${id}`,
         method: 'DELETE',
         headers: {
            Authorization: token
         }
      })
   }

   static getSection({ token, _id, lang = 'ua' }: TGetSectionRequestPayload) {
      return this.request({
         url: `${SECTION_URL.getSection}/${_id}`,
         method: 'GET',
         headers: {
            Authorization: token
         },
         params: {
            lang
         }
      })
   }
   static getDescription({ token, _id }: TGetCategoryRequestPayload): Promise<AxiosResponse<TResponse>> {
      return this.request({
         url: `${SECTION_URL.getDescriptionSection}/${_id}/descriptions`,
         method: 'GET',
         headers: {
            Authorization: token
         }
      })
   }
}
