import styled from 'styled-components'

export const VariationsContainer = styled.div`
  max-width: 1115px;
  width: 100%;
  background: #F5F5F5;
  padding: 21px 15px;
  border-radius: 14px;
  margin-bottom: 20px;
`;

export const VariationBlock = styled.div`
 position: relative;
`;

export const VariationsHeader = styled.div`
  position: absolute;
  right: 10px;
  top: 40px;
`;

export const Name = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 14px;
  color: #222222;
`;

export const VariationsMain = styled.div``;

export const InputVariations = styled.div``;
