import { api } from "../../common/config/api";


export const SECTION_URL = {
   getSections: `${api.withAPI}/v1/catalog/section`,
   removeSection: `${api.withAPI}/v1/catalog/section`,
   createSection: `${api.withAPI}/v1/catalog/section`,
   editSection: `${api.withAPI}/v1/catalog/section`,
   getSection: `${api.withAPI}/v1/catalog/section`,
   getDescriptionSection: `${api.withAPI}/v1/catalog/section`
}
