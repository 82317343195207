import React, { FC, SyntheticEvent, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { TCategory } from "../../store/category/types";
import { DROP_DOWN_LIST_LIMIT, PAGE_LIMIT } from "./consts";
import { api } from "../../common/config/api";
import {
  ButtonNew,
  DataTotal,
  DateInput,
  FiltersWrapper,
  FlexContainer,
  HeaderWrapper,
  PaginateDropDownList,
  Pagination,
  SearchBar,
  Table,
  TOption,
  TSort,
  useTypedSelector,
} from "../../common";

import { getMainProductSelector, productActions, RESPONSE } from "../../store";

import { getUserSelector } from "../../store/user";
import { getLocale } from "../../types";
import { ApiCategoryService } from "../../store/category/api.service";

import {
  ButtonBlock,
  Container,
  FlexStyledContainer,
  Header,
  Image,
  MainContainer,
  Title,
  TitleBlock,
} from "./styled";

const ProductsManagement: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const local = getLocale();

  const { products, response, loading } = useTypedSelector(
    getMainProductSelector
  );
  const { accessToken, permissions } = useTypedSelector(getUserSelector);

  const [page, setPage] = useState<number>(0);
  const [searchParams, setSearchParams] = useSearchParams({});
  const [searchForm, setSearchForm] = useState({
    value: "",
    start: "",
    end: "",
  });
  const [filterForm, setFilterForm] = useState<{
    value: string;
    label: string;
  } | null>({
    value: "",
    label: "",
  });

  const [sortParamsForm, setSortParamsForm] = useState({
    sortBy: "",
    order: "" as TSort,
  });

  const tableSortBy = useMemo(() => {
    {
      if (!sortParamsForm.order) {
        return { id: null, desc: false };
      }
      return { id: sortParamsForm.sortBy, desc: sortParamsForm.order > 0 };
    }
  }, [sortParamsForm]);

  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [removeButtonState, setRemoveButtonState] = useState<boolean>(false);

  const data = useMemo(
    () =>
      products.data.map((product: any) => {
        const publish_date = product?.createdAt;
        return {
          id: <>{product.sku}</>,
          photo: (
            <Image
              src={`${api.withImageAPI}/product/preview/${product.preview}`}
            />
          ),
          label: <>{product.description && product.description?.title}</>,
          publish_date: <>{moment(publish_date).format("DD.MM.YYYY HH:mm")}</>,
          category: <>{product.category.title}</>,
          total: <>{product.amount}</>,
          price: <>{product.price}</>,
          sellStatus: <>{t(product.sellStatus.split(" ").join("."))}</>,
        };
      }),
    [products]
  );

  const Events = {
    onPageChangeHandler: ({ selected }: { selected: number }) => {
      setPage(selected);
    },
    loadOptions: async (search: string, loadOptions: any, additions: any) => {
      const page = additions?.page || 0;

      const options = await Requests.getCategories(page);

      return {
        options: (options.data.data as TCategory[]).map((item) => ({
          value: item._id,
          label: item.title,
        })),
        hasMore: options.data.meta.totalCount > page * DROP_DOWN_LIST_LIMIT,
        additional: {
          page: page + 1,
        },
      };
    },
    onChangeHandler: (e: React.SyntheticEvent) => {
      const input = e.target as HTMLInputElement;
      setSearchForm({ ...searchForm, [input.name]: input.value });
      Requests.getProducts();
    },
    onSubmitSearchHandler: (e: SyntheticEvent) => {
      e.preventDefault();
      Requests.getProducts();
    },
    paginateDropDownChangeHandler: (value: TOption) => {
      setFilterForm(value);
    },

    sortToggleHandler: (sortBy: string, order: TSort) => {
      setSortParamsForm({ sortBy, order });
    },
    checkboxClickHandler: (
      e: SyntheticEvent,
      hasCheckboxesActiveState: boolean,
      ckeckedItemsIndex: number[]
    ) => {
      setRemoveButtonState(hasCheckboxesActiveState);
      setSelectedItems(ckeckedItemsIndex);
    },
    addButtonClickHandler: () => {
      navigate("/product-create");
    },
    editClickHandler: (e: SyntheticEvent, index: number) => {
      navigate(`/product/${products.data[index]._id}`);
    },
    removeProducts: () => {
      selectedItems.forEach((index) => {
        const { _id } = products?.data[index];
        Requests.removeProduct(_id);
      });
      setRemoveButtonState(false);
      setSelectedItems([]);
    },
  };

  const columns = useMemo(
    () => [
      {
        Header: t("product.code"),
        accessor: "id",
        width: 100,
        sortToggleHandler: Events.sortToggleHandler,
      },
      {
        Header: t("photo"),
        accessor: "photo",
      },
      {
        Header: t("label"),
        accessor: "label",
        width: 200,
      },
      {
        Header: t("publish.date"),
        accessor: "publish_date",
        width: 200,
      },
      {
        Header: t("category"),
        accessor: "category",
        width: 200,
        sortToggleHandler: Events.sortToggleHandler,
      },
      {
        Header: t("total"),
        accessor: "total",
        width: 75,
      },
      {
        Header: t("price"),
        accessor: "price",
        width: 100,
        sortToggleHandler: Events.sortToggleHandler,
      },
      {
        Header: t("product.status"),
        accessor: "sellStatus",
        width: 150,
        sortToggleHandler: Events.sortToggleHandler,
      },
    ],
    []
  );

  const Requests = {
    getProducts: () => {
      dispatch(
        productActions.getProducts({
          limit: PAGE_LIMIT,
          page,
          lang: local,
          query: searchForm.value,
          date_start: searchForm.start,
          date_end: searchForm.end,
          category: [filterForm?.value || ""],
          ...sortParamsForm,
        })
      );
    },
    removeProduct: (_id: string) => {
      dispatch(productActions.removeProduct({ _id }));
    },
    getCategories: async (page: number = 0) => {
      return await ApiCategoryService.getCategories({
        token: accessToken as string,
        limit: DROP_DOWN_LIST_LIMIT,
        page,
        lang: local,
      });
    },
  };

  useEffect(() => {
    Requests.getProducts();
    setSearchParams({ ...searchParams, page: "" + page });
  }, [page, filterForm, tableSortBy, searchForm]);

  // Set default page uri
  useEffect(() => {
    setPage(
      !isNaN(parseInt(searchParams.get("page") as string))
        ? parseInt(searchParams.get("page") as string)
        : 0
    );
  }, []);

  useEffect(() => {
    if (response === RESPONSE.REMOVED) {
      Requests.getProducts();
    }
  }, [response]);

  useEffect(() => {
    if (!loading && products?.meta?.needReload) Requests.getProducts();
  });

  const product = permissions.filter((el: string) => el === "product").join();
  if (product !== "product") navigate("/404");
  return (
    <Container>
      <Header>
        <TitleBlock>
          <Title>{t("manage.products")}</Title>
        </TitleBlock>
        <FlexStyledContainer>
          {removeButtonState && (
            <ButtonBlock>
              <ButtonNew theme="red" onClick={Events.removeProducts}>
                {t("remove.all")}
              </ButtonNew>
            </ButtonBlock>
          )}
          <ButtonBlock>
            <ButtonNew theme="green" onClick={Events.addButtonClickHandler}>
              {t("add.product")}
            </ButtonNew>
          </ButtonBlock>
        </FlexStyledContainer>
      </Header>
      <MainContainer>
        <FlexContainer direction="column" gap="30px">
          <HeaderWrapper>
            <FiltersWrapper>
              <SearchBar
                name="value"
                placeholder={t("search")}
                value={searchForm.value}
                onChange={Events.onChangeHandler}
                onSubmit={Events.onSubmitSearchHandler}
              />
              <DateInput
                name="start"
                value={searchForm.start}
                onChange={Events.onChangeHandler}
              />
              <DateInput
                name="end"
                value={searchForm.end}
                onChange={Events.onChangeHandler}
              />
              <PaginateDropDownList
                isClearable
                loadOptions={Events.loadOptions}
                placeholder={t("filter.by.category")}
                onChange={Events.paginateDropDownChangeHandler}
                value={filterForm || { value: "", label: "" }}
              />
            </FiltersWrapper>
            <DataTotal>
              {t("total.product")}: {products.meta?.totalCount}
            </DataTotal>
          </HeaderWrapper>

          <Table
            columns={columns}
            data={data}
            sortBy={tableSortBy}
            checkboxClickHandler={Events.checkboxClickHandler}
            editable
            editClickHandler={Events.editClickHandler}
          />
        </FlexContainer>

        <Pagination
          page={page}
          pageCount={
            products.meta ? Math.ceil(products.meta.totalCount / PAGE_LIMIT) : 1
          }
          onPageChange={Events.onPageChangeHandler}
        />
      </MainContainer>
    </Container>
  );
};

export default ProductsManagement;
