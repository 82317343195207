import { AxiosResponse } from "axios";
import { PayloadAuth, TResponse } from "../types";
import {
  createShopActionResponse,
  GetShopActionPayload,
  GetShopByIdActionResponse,
  TRemoveShopRequestPayload,
} from "./types";
import { SHOP_URL } from "./config";
import { HttpService } from "./http.service";
import { setAuthorizationHeadersProp } from "../../common";

export class ApiShopService extends HttpService {
  static getShop(
    request: GetShopActionPayload & PayloadAuth
  ): Promise<AxiosResponse<GetShopByIdActionResponse>> {
    return this.request<GetShopByIdActionResponse>("GET_SHOP", {
      url: SHOP_URL.getShop,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        ...setAuthorizationHeadersProp(request.authorization),
      },
    });
  }
  static createShop(
    request: GetShopActionPayload & PayloadAuth
  ): Promise<AxiosResponse<createShopActionResponse>> {
    return this.request<createShopActionResponse>("CREATE_SHOP", {
      url: SHOP_URL.createShop,
      method: "POST",
      data: {
        name: request.name,
        phone: request.phone,
        address: request.address,
        workMode: request.workMode,
        lon: request.lon,
        lat: request.lat,
      },
      headers: {
        "Content-Type": "application/json",
        ...setAuthorizationHeadersProp(request.authorization),
      },
    });
  }

  static removeShop({
    token,
    _id,
  }: TRemoveShopRequestPayload): Promise<AxiosResponse<TResponse>> {
    return this.request("DELETE_SHOP", {
      url: `${SHOP_URL.delete_shop}/${_id}`,
      method: "DELETE",
      headers: {
        Authorization: token,
      },
    });
  }
}
