import { api } from "../../common/config/api";

export const PRODUCT_URL = {
   getProducts: `${api.withAPI}/v2/catalog/product/search`,
   getProduct: `${api.withAPI}/v1/catalog/product`,
   removeProduct: `${api.withAPI}/v1/catalog/product`,
   createProduct: `${api.withAPI}/v1/catalog/product`,
   getGroups: `${api.withAPI}/v1/catalog/group`,
   getGroup: `${api.withAPI}/v1/catalog/group`,
   createGroup: `${api.withAPI}/v1/catalog/group`,
   editProduct: `${api.withAPI}/v1/catalog/product`,
   editGroup: `${api.withAPI}/v1/catalog/group`,
   createVariationBulk: `${api.withAPI}/v1/catalog/variationBulk`,
   editVariation: `${api.withAPI}/v1/catalog/variation`,
   editVariationValue: `${api.withAPI}/v1/catalog/variationValue`,
   createVariationValue: `${api.withAPI}/v1/catalog/variationValue`,
   removeVariation: `${api.withAPI}/v1/catalog/variation`,
   createPhotoProduct: `${api.withAPI}/v1/file/product`,
   getProductsGroup: `${api.withAPI}/v1/catalog/product/search`,
   getDescription: `${api.withAPI}/v1/catalog/product`,
   getVariationValue: `${api.withAPI}/v1/catalog/variationValue`
}
