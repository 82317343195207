import styled from "styled-components";
import { colors, FLEX, FONT } from "../../common";

export const Container = styled.div``;

export const Header = styled.div`
  ${FLEX({ justify: "space-between" })}
`;

export const TitleBlock = styled.div`
  display: flex;
  align-items: center;
`;
export const FlexStyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Image = styled.img`
  cursor: pointer;
`;

export const ButtonBlock = styled.div`
  margin-left: 30px;
`;

export const RecipientContainer = styled.div``;

export const SumBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
`;

export const Title = styled.h1`
  ${FONT({ weight: "700", size: "24px", color: colors.black })};

  margin-left: 14px;
`;

export const DateBlock = styled.div`
  display: flex;
  justify-content: space-between;

  margin-left: 60px;

  ${FONT({ weight: "400", size: "12px", color: colors.gray })};
`;

export const DateText = styled.p``;

export const MainContainer = styled.main`
  max-width: 1609px;
  height: auto;

  margin-top: 26px;

  background: white;

  box-shadow: 0 14px 28px rgba(135, 135, 135, 0.16);

  border-radius: 22px;
  padding-bottom: 20px;
`;

export const UserContainer = styled.div`
  background-color: #f5f5f5;
  width: 318px;
  max-width: 100%;
  height: auto;
  min-height: 66px;
  ${FONT({ color: colors.black, size: "16px", weight: "400" })}
  box-shadow: 0px 4px 12px rgba(141, 141, 141, 0.25);
  padding: 14px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
`;

export const TotalSum = styled.div`
  ${FONT({ size: "16px" })}
`;

export const InputRef = styled.input`
  width: 100%;
  height: 45px;
  padding: 13px 14px;
  margin-top: 7px;

  outline: none;

  border: 2px solid #d1d1d1;
  border-radius: 8px;

  background: ${colors.white};

  transition: 0.2s;

  &:focus {
    border: 2px solid ${({ color }) => `${color}`};
    background: ${colors.white};
  }

  &::placeholder {
    ${FONT({
      size: "16px",
      weight: "400",
      color: colors.gray,
    })}
  }

  @media (max-width: 1600px) {
    width: 100%;
  }
`;

export const Label = styled.label`
  margin-bottom: 8px;
  margin-left: 15px;
  line-height: 16px;
  ${FONT({ color: colors.black, size: "14px", weight: "500" })}
`;

export const InputBlock = styled.div`
  width: 318px;
`;
