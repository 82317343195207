import { api } from "../../common/config/api";

export const SUBCATEGORY_URL = {
   getSubcategories: `${api.withAPI}/v1/catalog/subcategory`,
   removeSubcategory: `${api.withAPI}/v1/catalog/subcategory`,
   createSubcategory: `${api.withAPI}/v1/catalog/subcategory`,
   editSubcategory: `${api.withAPI}/v1/catalog/subcategory`,
   getSubcategoriesByCategory: `${api.withAPI}/v1/catalog/subcategory`,
   getSubcategoryDescription: `${api.withAPI}/v1/catalog/subcategory`
}
