export const YouTubeVideo = ({ videoLink }: { videoLink: string }) => {
  function extractVideoId(url: string) {
    const match = url.match(/v=([^&]*)/);
    return match ? match[1] : null;
  }
  return (
    <iframe
      title="video"
      width="100%"
      height="401"
      src={`https://www.youtube.com/embed/${extractVideoId(videoLink)}`}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
  );
};
