import { FC, SyntheticEvent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  ButtonNew,
  colors,
  FlexContainer,
  generateErrorToast,
  H2,
  Input,
  Label,
  PaginateDropDownList,
  Pagination,
  Popup,
  SubPagesBar,
  Table,
  TOption,
  TSort,
  useTypedSelector,
  useValidation,
} from "../../../../common";
import { getUserSelector } from "../../../../store/user";
import { PAGE_LIMIT } from "../../consts";
import {
  ButtonBlock,
  Container,
  FlexStyledContainer,
  Header,
  MainContainer,
  Title,
} from "../../styled";
import { DROP_DOWN_LIST_LIMIT } from "./consts";
import { ApiCategoryService } from "../../../../store/category/api.service";
import { TCategory } from "../../../../store/category/types";
import {
  getMainSubcategorySelector,
  subcategoryActions,
  SUBCATEGORY_RESPONSE,
  showToastAction,
} from "../../../../store";
import { getLocale } from "../../../../types";

import { TForm, TFormTypes, TSubCategoryValidationSchema } from "./types";

const IndexPage: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { subcategories, response, description } = useTypedSelector(
    getMainSubcategorySelector
  );
  const { accessToken } = useTypedSelector(getUserSelector);

  const locale = getLocale();

  const navigate = useNavigate();

  const [page, setPage] = useState<number>(0);

  const [searchParams, setSearchParams] = useSearchParams({});

  const [removeButtonState, setRemoveButtonState] = useState<boolean>(false);

  const [popupVisiablity, setPopupVisiablity] = useState<boolean>(false);

  const [formType, setFormType] = useState<TFormTypes>("create");

  const [selectedItems, setSelectedItems] = useState<number[]>([]);

  const [form, setForm] = useState<TForm>({
    nameUa: "",
    nameEn: "",
    nameRu: "",
    _id: "",
    category: { value: "", label: "" },
  });

  const [sortParamsForm, setSortParamsForm] = useState({
    sortBy: "",
    order: "" as TSort,
  });

  const tableSortBy = useMemo(() => {
    {
      if (!sortParamsForm.order) {
        return { id: null, desc: false };
      }
      return { id: sortParamsForm.sortBy, desc: sortParamsForm.order > 0 };
    }
  }, [sortParamsForm]);

  const data = useMemo(
    () =>
      subcategories.data.map((subcategory) => {
        return {
          title: subcategory.title,
          productsTotalCount: <>{subcategory.productsTotalCount}</>,
        };
      }),
    [subcategories]
  );

  const Requests = {
    getSubcategories: () => {
      dispatch(
        subcategoryActions.getSubcategories({
          limit: PAGE_LIMIT,
          page: page,
          lang: locale,
          ...sortParamsForm,
        })
      );
    },

    getCategories: async (page: number) => {
      return await ApiCategoryService.getCategories({
        token: accessToken as string,
        page,
        lang: locale,
        limit: DROP_DOWN_LIST_LIMIT,
      });
    },
    createSubcategory: () => {
      const data = new FormData();

      data.append(
        "description[]",
        JSON.stringify({
          title: form.nameEn,
          lang: "en",
        })
      );
      data.append(
        "description[]",
        JSON.stringify({
          title: form.nameUa,
          lang: "ua",
        })
      );
      data.append(
        "description[]",
        JSON.stringify({
          title: form.nameRu,
          lang: "ru",
        })
      );
      data.append("category", form.category.value);
      dispatch(subcategoryActions.createSubcategory({ data }));
    },
    removeSubcategories: (_ids: string[]) => {
      dispatch(subcategoryActions.removeSubcategories({ _ids }));
    },
    editSubcategory: () => {
      const data = new FormData();

      data.append(
        "description[]",
        JSON.stringify({
          title: form.nameEn,
          lang: "en",
        })
      );
      data.append(
        "description[]",
        JSON.stringify({
          title: form.nameUa,
          lang: "ua",
        })
      );
      data.append(
        "description[]",
        JSON.stringify({
          title: form.nameRu,
          lang: "ru",
        })
      );
      form.category && data.append("category", form.category.value);
      dispatch(
        subcategoryActions.editSubcategory({ data, _id: form._id as string })
      );
    },
    getCategory: async (_id: string) => {
      return await ApiCategoryService.getCategory({
        token: accessToken as string,
        _id,
        lang: locale,
      });
    },
  };

  const schema = useMemo<TSubCategoryValidationSchema>(
    () => ({
      name: {
        condition: !!form.nameRu && !!form.nameEn && !!form.nameUa,
        error: t("enter.the.subcategory.name.in.all.languages"),
      },
      category: {
        condition: !!form.category.value,
        error: t("select.category"),
      },
    }),
    [form]
  );

  const {
    validation,
    enableValidation,
    disableValidation,
    //eslint-disable-next-line
  } = useValidation(schema);

  const Events = {
    onPageChangeHandler: ({ selected }: { selected: number }) => {
      setPage(selected);
    },
    sortToggleHandler: (sortBy: string, order: any) => {
      setSortParamsForm({ sortBy, order });
    },
    closePopupClickHandler: () => {
      setPopupVisiablity(false);
    },
    inputChangeHandler: (e: SyntheticEvent) => {
      const { name, value } = e.target as HTMLInputElement;
      setForm((props) => ({ ...props, [name]: value }));
    },
    createButtonClickHandler: async () => {
      try {
        enableValidation();
        await validation();
        Requests.createSubcategory();
        setPopupVisiablity(false);
        disableValidation();
      } catch (error: any) {
        error?.map((err: string) => {
          dispatch(showToastAction.request(generateErrorToast(err)));
        });
      }
    },
    editButtonClickHandler: async () => {
      try {
        enableValidation();
        await validation();
        Requests.editSubcategory();
        setPopupVisiablity(false);
        disableValidation();
      } catch (error: any) {
        error?.map((err: string) => {
          dispatch(showToastAction.request(generateErrorToast(err)));
        });
      }
    },
    addButtonClickListner: () => {
      setForm({
        nameUa: "",
        nameEn: "",
        nameRu: "",
        category: { value: "", label: "" },
      });
      setFormType("create");
      setPopupVisiablity(true);
    },
    removeSubcategories: () => {
      Requests.removeSubcategories(
        selectedItems.map((index) => subcategories.data[index]._id)
      );
      setRemoveButtonState(false);
      setSelectedItems([]);
    },

    loadCategoriesOptions: async (
      search: string,
      loadOptions: any,
      additions: any
    ) => {
      const page = additions?.page || 0;

      const options = await Requests.getCategories(page as number);

      return {
        options: (options.data.data as TCategory[]).map((item) => ({
          value: item._id,
          label: item.title,
        })),
        hasMore: options.data.meta.totalCount > page * DROP_DOWN_LIST_LIMIT,
        additional: {
          page: page + 1,
        },
      };
    },
    paginateDropDownChangeHandler: (value: TOption) => {
      setForm((form) => ({ ...form, category: value }));
    },
    removeCategoryHandler: (e: SyntheticEvent, index: number) => {
      Requests.removeSubcategories([subcategories.data[index]._id]);
    },
    checkboxClickHandler: (
      e: SyntheticEvent,
      hasCheckboxesActiveState: boolean,
      ckeckedItemsIndex: number[]
    ) => {
      setRemoveButtonState(hasCheckboxesActiveState);
      setSelectedItems(ckeckedItemsIndex);
    },
    openEditButtonClickHandler: async (e: SyntheticEvent, index: number) => {
      const response = await Requests.getCategory(
        subcategories.data[index].category
      );
      await dispatch(
        subcategoryActions.getSubcategoryDescription({
          _id: subcategories.data[index]._id,
        })
      );
      setForm({
        nameUa: description?.data[1]?.title,
        nameEn: description?.data[0]?.title,
        nameRu: description?.data[2]?.title,
        _id: subcategories.data[index]._id,
        category: {
          value: subcategories.data[index].category,
          label: response.data.data.title,
        },
      });
      setFormType("edit");
      setPopupVisiablity(true);
    },
    onChange: (e: SyntheticEvent) => {
      const input = e.target as HTMLInputElement;
      setForm({ ...form, [input.name]: input.value });
    },
  };

  const columns = useMemo(
    () => [
      {
        Header: t("name"),
        accessor: "title",
        width: 500,
        sortToggleHandler: Events.sortToggleHandler,
      },
      {
        Header: t("total.product"),
        accessor: "productsTotalCount",
        width: 500,
        sortToggleHandler: Events.sortToggleHandler,
      },
    ],
    []
  );

  const paginationSections = useMemo(
    () => [
      // {
      //    title: t('sections'),
      //    onClickHandler: () => {
      //       navigate('/catalog/sections')
      //    }
      // },
      {
        title: t("categories"),
        onClickHandler: () => {
          navigate("/catalog/categories");
        },
      },
      {
        title: t("subcategories"),
        active: true,
      },
      {
        title: t("brands"),
        onClickHandler: () => {
          navigate("/catalog/brand");
        },
      },
    ],
    []
  );

  // Request to receive data
  useEffect(() => {
    Requests.getSubcategories();
    setSearchParams({ ...searchParams, page: "" + page, lang: locale });
  }, [page, sortParamsForm]);

  // Set default page uri
  useEffect(() => {
    setPage(
      !isNaN(parseInt(searchParams.get("page") as string))
        ? parseInt(searchParams.get("page") as string)
        : 0
    );
  }, []);

  useEffect(() => {
    if (description) {
      setForm((form) => ({
        ...form,
        nameUa: description?.data[1]?.title,
        nameEn: description?.data[0]?.title,
        nameDe: description?.data[2]?.title,
      }));
    }
  }, [description]);

  useEffect(() => {
    if (response == SUBCATEGORY_RESPONSE.CREATED) {
      setPopupVisiablity(false);
      setForm({
        nameUa: "",
        nameRu: "",
        nameEn: "",
        category: { value: "", label: "" },
      });
      Requests.getSubcategories();
    }

    if (response === SUBCATEGORY_RESPONSE.REMOVED) {
      Requests.getSubcategories();
    }

    if (response === SUBCATEGORY_RESPONSE.EDITED) {
      Requests.getSubcategories();
    }
  }, [response]);

  return (
    <Container>
      <Header>
        <Title>{t("subcategory")}</Title>
        <FlexStyledContainer>
          {removeButtonState && (
            <ButtonBlock>
              <ButtonNew theme="red" onClick={Events.removeSubcategories}>
                {t("remove.all")}
              </ButtonNew>
            </ButtonBlock>
          )}
          <ButtonBlock>
            <ButtonNew theme="green" onClick={Events.addButtonClickListner}>
              {t("create.subCategory")}
            </ButtonNew>
          </ButtonBlock>
        </FlexStyledContainer>
      </Header>
      <MainContainer>
        <SubPagesBar sections={paginationSections} />
        <Table
          columns={columns}
          data={data}
          sortBy={tableSortBy}
          editable
          removeClickHandler={Events.removeCategoryHandler}
          checkboxClickHandler={Events.checkboxClickHandler}
          editClickHandler={Events.openEditButtonClickHandler}
          removable
        />
        <Pagination
          page={page}
          pageCount={
            subcategories.meta
              ? Math.ceil(subcategories.meta.totalCount / PAGE_LIMIT)
              : 1
          }
          onPageChange={Events.onPageChangeHandler}
        />

        {popupVisiablity && (
          <Popup backgroundClickListener={Events.closePopupClickHandler}>
            <FlexContainer
              justify="space-between"
              direction="column"
              style={{ height: "100%" }}
              wrap="nowrap"
            >
              <FlexContainer gap="20px" align="center" justify="center">
                {formType == "create" && (
                  <H2 style={{ marginTop: "40px" }}>
                    {t("create.subCategory")}
                  </H2>
                )}
                {formType === "edit" && (
                  <H2 style={{ marginTop: "40px" }}>{t("edit.subCategory")}</H2>
                )}

                <FlexContainer
                  gap="30px"
                  align="flex-start"
                  justify="center"
                  style={{
                    padding: "30px 0px",
                    borderBottom: `1px solid ${colors.gray}`,
                  }}
                >
                  <Input
                    name="nameUa"
                    label={t("name")}
                    placeholder={t("enter.name.ua")}
                    value={form.nameUa}
                    onChange={Events.inputChangeHandler}
                  />
                  <Input
                    name="nameEn"
                    label={t("name")}
                    placeholder={t("enter.name.en")}
                    value={form.nameEn}
                    onChange={Events.inputChangeHandler}
                  />
                  <Input
                    name="nameRu"
                    label={t("name")}
                    placeholder={t("enter.name.ru")}
                    value={form.nameRu}
                    onChange={Events.inputChangeHandler}
                  />
                </FlexContainer>
                <FlexContainer
                  justify="center"
                  direction="column"
                  align="center"
                  gap="20px"
                >
                  <FlexContainer direction="column" width="auto">
                    <Label>{t("category")}</Label>
                    <PaginateDropDownList
                      loadOptions={Events.loadCategoriesOptions}
                      placeholder={t("select.category")}
                      onChange={Events.paginateDropDownChangeHandler}
                      value={form.category}
                    />
                  </FlexContainer>
                </FlexContainer>
              </FlexContainer>
              <FlexContainer
                style={{ marginBottom: "40px" }}
                gap="30px"
                justify="center"
              >
                <ButtonNew
                  theme="gray"
                  style="transparant"
                  onClick={Events.closePopupClickHandler}
                >
                  {t("cancel")}
                </ButtonNew>

                {formType == "create" && (
                  <ButtonNew
                    theme="green"
                    onClick={Events.createButtonClickHandler}
                  >
                    {t("create")}
                  </ButtonNew>
                )}
                {formType == "edit" && (
                  <ButtonNew
                    theme="green"
                    onClick={Events.editButtonClickHandler}
                  >
                    {t("edit")}
                  </ButtonNew>
                )}
              </FlexContainer>
            </FlexContainer>
          </Popup>
        )}
      </MainContainer>
    </Container>
  );
};

export default IndexPage;
