import {AxiosResponse} from 'axios'
import { HttpService } from '../http.service'
import {TResponse} from '../types'
import {ORDER_URL} from './config'
import {
   TEditOrderRequestPayload,
   TGetArchiveRequestPayload, TGetDeliveryRequestPayload,
   TGetOrderRequestPayload,
   TGetOrdersRequestPayload,
   TGetStatisticRequestPayload,
   TRemoveOrderRequestPayload
} from './types'

export class ApiOrderService extends HttpService {
   static getOrders({
      token,
      limit = 10,
      page = 0,
      lang = 'ua',
      value = '',
      field = '',
      start = '',
      end = '',
      regex = '',
      sortBy = '',
      order = ''
   }: TGetOrdersRequestPayload): Promise<AxiosResponse<TResponse>> {
      return this.request({
         url: `${ORDER_URL.getOrders}`,
         method: 'GET',
         headers: {
            Authorization: token
         },
         params: {
            limit,
            skip: limit * page,
            lang,
            regex,
            value,
            field,
            start,
            end,
            sortBy,
            order
         }
      })
   }

   static getDelivery({
                       token,
                       query = ''
                    }: TGetDeliveryRequestPayload): Promise<AxiosResponse<TResponse>> {
      return this.request({
         url: `${ORDER_URL.getDelivery}`,
         method: 'GET',
         headers: {
            Authorization: token
         },
         params: {
            query
         }
      })
   }

   static getArchive({
      token,
      limit = 10,
      page = 0,
      auth='admin',
      lang = 'ua',
      value = '',
      field = '',
      start = '',
      end = '',
      regex = '',
      sortBy = '',
      order = ''
   }: TGetArchiveRequestPayload): Promise<AxiosResponse<TResponse>> {
      return this.request({
         url: `${ORDER_URL.getArchive}`,
         method: 'GET',
         headers: {
            Authorization: token
         },
         params: {
            limit,
            skip: limit * page,
            lang,
            regex,
            value,
            field,
            start,
            auth,
            end,
            sortBy,
            order
         }
      })
   }

   static getOrder({ token, _id, lang = 'ua' }: TGetOrderRequestPayload): Promise<AxiosResponse<TResponse>> {
      return this.request({
         url: `${ORDER_URL.getOrder}/${_id}`,
         method: 'GET',
         headers: {
            Authorization: token
         },
         params: {
            lang
         }
      })
   }

   static editOrder({ token, _id, data }: TEditOrderRequestPayload): Promise<AxiosResponse<TResponse>> {
      return this.request({
         url: `${ORDER_URL.editOrder}/${_id}`,
         method: 'PATCH',
         data,
         headers: {
            Authorization: token
         }
      })
   }

   static removeOrder({ token, _id }: TRemoveOrderRequestPayload): Promise<AxiosResponse<TResponse>> {
      return this.request({
         url: `${ORDER_URL.removeOrder}/${_id}`,
         method: 'DELETE',
         headers: {
            Authorization: token
         }
      })
   }

   static getStatistic({ token }: TGetStatisticRequestPayload): Promise<AxiosResponse<TResponse>> {
      return this.request({
         url: `${ORDER_URL.getStatistic}`,
         method: 'GET',
         headers: {
            Authorization: token
         }
      })
   }

   static getGraf({ token, start = '', end = '' }: TGetStatisticRequestPayload): Promise<AxiosResponse<TResponse>> {
      return this.request({
         url: `${ORDER_URL.getGraf}`,
         method: 'GET',
         headers: {
            Authorization: token
         },
         params: {
            start,
            end,
         }
      })
   }
}
