import styled from "styled-components";
import {TImage} from "../../../Banner/types";

export const MainContainer = styled.main``;

export const ColorBlock = styled.div`
  width: 30px;
  height: 30px;
  margin-left: 65px;
  border-radius: 50%;
`;

export const Image = styled.div<TImage>`
  display: inline-block;
  background-image: ${({ src }) => `url(${src})`};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 46px;
  width: 46px;
`
export const VariationBlock = styled.div`
 position: relative;
`;

