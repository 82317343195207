import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { Assets, Preloader, useTypedSelector } from "../../common";

import { RESPONSE } from "../../store/setting/consts";
import { api } from "../../common/config/api";
import { getUserSelector, userActions } from "../../store/user";
import { getMainSettingSelector, settingAction } from "../../store";

import {
  SidebarWrapper,
  StyledContainer,
  NavLink,
  MenuWrapper,
  Image,
  LogoutBtn,
  NavLinkBlock,
  ImageBlock,
  MenuName,
  Logo,
  AdminBlock,
  AdminPhoto,
  Name,
  LogoTitle,
} from "./styled";

export default function Sidebar({ onMenuItemClick }: any) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { name, secondName, image, permissions } =
    useTypedSelector(getUserSelector);
  const { logo, response, loading } = useTypedSelector(getMainSettingSelector);

  const sidebarMenus = [
    {
      name: "orders",
      path: "/orders",
      exact: false,
      icon: Assets.BASKET,
      permission: "order",
    },
    {
      name: "manage.products",
      path: "/products-management",
      exact: false,
      icon: Assets.PRODUCTS_MANAGEMENT_ICON,
      permission: "product",
    },
    {
      name: "manage.clients",
      path: "/customers",
      exact: false,
      icon: Assets.CLIENT_MANAGEMENT_ICON,
      permission: "client",
    },
    {
      name: "categories",
      path: "/catalog",
      exact: false,
      icon: Assets.CATEGORIES_ICON,
      permission: "category",
    },
    {
      name: "support",
      path: "/support",
      exact: false,
      icon: Assets.SUPPORT_ICON,
      permission: "support",
    },
    {
      name: "bonus.system",
      path: "/bonus-system",
      exact: false,
      icon: Assets.BONUS_SYSTEM_ICON,
      permission: "clientGroup",
    },
    {
      name: "banners",
      path: "/banner",
      exact: false,
      icon: Assets.BANNER_ICON,
      permission: "banner",
    },
    {
      name: "delivery",
      path: "/delivery",
      exact: false,
      icon: Assets.DELIVERY_ICON,
      permission: "delivery",
    },
    {
      name: "feedback",
      path: "/feedback",
      exact: false,
      icon: Assets.REVIEWS_ICON,
      permission: "feedback",
    },
    // {
    //   name: "shop",
    //   path: "/shop",
    //   exact: false,
    //   icon: Assets.SHOPS_ICON,
    //   permission: "shop",
    // },
    {
      name: "statistics",
      path: "/statistics",
      exact: false,
      icon: Assets.STATISTICS_ICON,
      permission: "statistic",
    },
    {
      name: "roles",
      path: "/role",
      exact: false,
      icon: Assets.ROLES_ICON,
      permission: "admin",
    },
    {
      name: "settings",
      path: "/settings",
      exact: false,
      icon: Assets.SETTINGS_ICON,
      permission: "config",
    },
  ];

  const Events = {
    exitButtonHandler: () => {
      dispatch(userActions.logoutRequest());
    },
  };

  useEffect(() => {
    dispatch(settingAction.getLogo({}));
  }, []);

  useEffect(() => {
    if (response === RESPONSE.EDITED) {
      dispatch(settingAction.getLogo({}));
    }
  }, [response]);

  return (
    <>
      {!loading ? (
        <SidebarWrapper>
          <StyledContainer>
            <LogoTitle>MANGALOFF</LogoTitle>
            {/* <Logo src={`${api.withImageAPI}/logo/${logo?.data?.logo}`} /> */}
            <AdminBlock>
              <AdminPhoto
                src={
                  image
                    ? `${api.withImageAPI}/admin/${image}`
                    : Assets.DEFAULT_ADMIN_ICON
                }
              />
              <Name>
                {name ?? "-"} {secondName ?? "-"}
              </Name>
            </AdminBlock>
          </StyledContainer>
          <MenuWrapper>
            {sidebarMenus?.map((menu: any, index: number) => (
              <React.Fragment key={index}>
                {permissions?.map((el: string, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      {el === menu?.permission && (
                        <NavLink
                          color={logo?.data?.mainColor}
                          key={menu.name + index}
                          to={menu.path}
                          onClick={onMenuItemClick}
                        >
                          <NavLinkBlock>
                            <ImageBlock>
                              {menu.icon ? <Image src={menu.icon} /> : ""}
                            </ImageBlock>
                            <MenuName>{t(menu.name)}</MenuName>
                          </NavLinkBlock>
                        </NavLink>
                      )}
                    </React.Fragment>
                  );
                })}
              </React.Fragment>
            ))}
          </MenuWrapper>
          <LogoutBtn
            color={logo?.data?.mainColor}
            onClick={Events.exitButtonHandler}
          >
            <Image src={Assets.LOGOUT_ICON} />
            {t("exit")}
          </LogoutBtn>
        </SidebarWrapper>
      ) : (
        <Preloader loading={loading} />
      )}
    </>
  );
}
