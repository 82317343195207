import React, {
	SyntheticEvent,
	useEffect,
	useMemo,
	useState
} from 'react';
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
	Divider,
	Table,
	Modal,
	useTypedSelector,
	ButtonNew,
	RelativePreloader
} from "../../common";
import {
	getMainShopSelector,
	getShopAction,
	getUserSelector,
	shopAction,
	SHOP_RESPONSE
} from "../../store";

import ModalForm from "./ModalForm/ModalForm";

import {
	Container,
	Header,
	Title,
	MainContainer,
} from './styled';
import { TForm } from './types';

const Shop = () => {
	const [modalActive, setModalActive] = useState<boolean>(false);
	const [removeButtonState, setRemoveButtonState] = useState<boolean>(false)
	const [selectedItems, setSelectedItems] = useState<number[]>([])
	const [formData, setFormData] = useState<TForm>({
		name: '',
		phone: '',
		workMode: '',
	});

	const dispatch = useDispatch();
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { shops, response, loading } = useTypedSelector(getMainShopSelector);
	const { permissions } = useTypedSelector(getUserSelector);

	const columns = useMemo(
		() => [
			{
				Header: t('title'),
				accessor: 'title',
				width: 200,
			},
			{
				Header: t('address'),
				accessor: 'address',
				width: 400,
			},
			{
				Header: t('phone'),
				accessor: 'phone',
				width: 400
			},
			{
				Header: t('operatingMode'),
				accessor: 'operatingMode',
				width: 400,
			},
		],
		[]
	);

	const data = useMemo(
		() =>
			shops?.map((s) => {
				return {
					title: !!s?.name
						? s?.name
						: '-',
					key: s?._id,
					address: !!s.address
						? s?.address
						: '-',
					phone: !!s.phone
						? s?.phone
						: '-',
					operatingMode: s.workMode ?? '-'
				}
			}),
		[shops]
	);

	const Requests = {
		removeShops: (_ids: string[]) => {
			dispatch(shopAction.removeShops({_ids}))
		},
	};

	const Events = {
		removeShops: () => {
			Requests.removeShops(selectedItems.map((index) => shops[index]._id))
			setRemoveButtonState(false)
			setSelectedItems([])
		},
		removeShopHandler: (e: SyntheticEvent, index: number) => {
			Requests.removeShops([shops[index]._id])
		},
		checkboxClickHandler: (e: SyntheticEvent, hasCheckboxesActiveState: boolean, ckeckedItemsIndex: number[]) => {
			setRemoveButtonState(hasCheckboxesActiveState)
			setSelectedItems(ckeckedItemsIndex)
		},
	}

	useEffect(() => {
		if (response === SHOP_RESPONSE.REMOVED) {
			dispatch(getShopAction.request({}))
		}
	}, [response])

	const handleModalActive = () => {
		setModalActive(true)
	};

	useEffect(() => {
		dispatch(getShopAction.request({}))
	}, []);

	const shop = permissions.filter((el:string) => el === 'shop').join();
	if(shop !== 'shop') navigate('/404');
	return (
		<>
			{
				!loading ? <Container>
					<Header>
						<Title>{t('shop')}</Title>
						{removeButtonState && (
							<ButtonNew theme="red" onClick={Events.removeShops}>
								{t('remove.all')}
							</ButtonNew>
						)}
					</Header>
					<MainContainer>
						<Divider height={30}/>
						<Table
							columns={columns}
							data={data}
							removeClickHandler={Events.removeShopHandler}
							checkboxClickHandler={Events.checkboxClickHandler}
							removable
						/>
						<Divider height={30}/>
						<ButtonNew width={139} height={40} onClick={handleModalActive}>
							<span>{t('add')}</span>
						</ButtonNew>
					</MainContainer>
					{ modalActive &&
						<Modal>
							<ModalForm formData={formData} setFormData={setFormData} setModalActive={setModalActive}/>
						</Modal>
					}
				</Container> : <RelativePreloader loading={loading} />
			}
		</>
	);
};

export default Shop;