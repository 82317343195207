import styled from "styled-components";
import { colors, FLEX, FONT } from "../../common";

export const Container = styled.div``;

export const Header = styled.div`
  ${FLEX({justify: 'space-between'})}
`;

export const Title = styled.h1`
  ${FONT({ weight: '700', size: '24px', color: colors.black })}
  
  margin-bottom: 35px;
`;

export const MainContainer = styled.main`
  max-width: 1609px;
  height: 925px;

  padding: 0 30px;
	
	background: white;
	
  box-shadow: 0 14px 28px rgba(135, 135, 135, 0.16);
	
  border-radius: 22px;
`;

export const Box = styled.div`
  width: 18px;
  height: 18px;

  border: 1px solid #818181;
  border-radius: 2px;
`;

export const StyledRow = styled.tr`
  & td:first-child{
    padding-right: 41px;
  }
  
  & td:not(:first-child){
    width: 20%;
  }
  
  & td:last-child{
    padding-left: 200px;
  }
  
  & td {
    padding-top: 28px;
    padding-bottom: 28px;

    border-bottom: 1px solid #F4F4F4;
  }
  
  ${FONT({ size: '14px', color: colors.black, weight: '400' })}
`

export const StyledHeader = styled.th`
  border-bottom: 1px solid #F4F4F4;

  padding-bottom: 19px;
  
  ${FONT({
    size: '14px',
    color: colors.gray,
    weight: '500',
    align: 'left',
})}
`

export const AddButton = styled.button`
  ${FLEX({align: "center"})}
  padding: 10px 18px 11px 24px;
  
  background: #F18018;
  border-radius: 8px;
  color: white;
  
  border: none;
  outline: none;
  
  cursor: pointer;
`;

export const Image = styled.img`
  margin-right: 9px;
`;

export const Name = styled.span`
  ${FONT({ weight: '600', size: '16px', color: colors.white })}
`

export const StyledTable = styled.table`
  width: 100%;
`

export const StyledBlock = styled.div``;

export const SaveButton = styled.button`
  width: 36px;
  height: 36px;

  margin-right: 20px;
  
  border: none;
  outline: none;
  
  background: #F18018;
  border-radius: 6px;
  
  cursor: pointer;
`;

export const DeleteButton = styled.button`
  width: 36px;
  height: 36px;

  border: none;
  outline: none;
  
  background: #DC3333;
  border-radius: 6px;
  
  cursor: pointer;
`;

export const StyledImage = styled.img``;
