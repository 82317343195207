import { createReducer } from '@reduxjs/toolkit'
import { supportActions } from './actions'
import { TInitialState } from './types'

const InitialState: TInitialState = {
   requests: {
      data: [],
      meta: null
   },
   loading: false
}

const reducer = createReducer<TInitialState>(InitialState, (builder) => {
   builder
      .addCase(supportActions.setRequests, (state, { payload }) => ({
         ...payload,
         requests: { ...state.requests, ...payload }
      }))
      .addCase(supportActions.setSupportState, (state, { payload }) => ({ ...state, ...payload }))
})

export default reducer
