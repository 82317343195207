import { api } from "../../common/config/api";

export const ORDER_URL = {
   getOrders: `${api.withAPI}/v1/order`,
   getOrder: `${api.withAPI}/v1/order`,
   editOrder: `${api.withAPI}/v1/order`,
   getArchive: `${api.withAPI}/v1/order/archive`,
   removeOrder: `${api.withAPI}/v1/order`,
   getStatistic: `${api.withAPI}/v1/statistic/stats`,
   getGraf: `${api.withAPI}/v1/statistic/graf`,
   getDelivery: `${api.withAPI}/v1/delivery/city`
}
