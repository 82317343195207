import React, { FC, SyntheticEvent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  bannerActions,
  BANNER_RESPONSE,
  getBannerSelector,
  TPostBannerData,
  showToastAction,
} from "../../store";
import { api } from "../../common/config/api";

import {
  ButtonNew,
  DateInput,
  FlexContainer,
  Input,
  PhotoPicker,
  useTypedSelector,
  Table,
  TableUtils,
  TColumnTable,
  TDataTable,
  RelativePreloader,
  generateErrorToast,
} from "../../common";
import { getUserSelector } from "../../store/user";

import {
  ButtonContainer,
  Container,
  MainContainer,
  Header,
  Title,
  Span,
  SpanOrder,
  Block,
  OrderBlock,
} from "./styled";
import { TBanner } from "./types";
import { log } from "console";

const IndexPage: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { banners, response, loading } = useTypedSelector(getBannerSelector);
  const { permissions } = useTypedSelector(getUserSelector);

  const [disabled, setDisabled] = useState(false);
  const [form, setForm] = useState<TBanner[]>([]);

  const Events = {
    onChangeHandler: (e: SyntheticEvent) => {
      const input = e.target as HTMLInputElement;
      const id = TableUtils.getRowIndex(input);
      setForm((form) =>
        form.map((item, index) => {
          if (index === id) {
            return { ...item, [input.name]: input.value };
          }
          return item;
        })
      );
    },
    onChangeDateHandler: (e: SyntheticEvent) => {
      const input = e.target as HTMLInputElement;
      const id = TableUtils.getRowIndex(input);
      setForm((form) =>
        form.map((item, index) => {
          if (index === id) {
            // @ts-ignore
            return {
              ...item,
              [input.name]: new Date(input.value).toISOString(),
            };
          }
          return item;
        })
      );
    },
    onImageChange: (file: File | null, id: number) => {
      if (id !== null) {
        setForm((form) =>
          form.map((item, index) => {
            if (index === id) {
              return { ...item, image: file };
            }
            return item;
          })
        );
      }
    },
    addButtonClickHandler: () => {
      setForm((form) =>
        form.concat([
          {
            start: new Date().toISOString(),
            end: new Date().toISOString(),
            order: "" + form.length,
            image: null,
          },
        ])
      );
    },
    removeItemButtonClickHandler: (e: SyntheticEvent, index: number) => {
      !!form[index]._id && Requests.removeBanner(form[index]._id as string);
      setForm(
        form.filter((banner, bannerIndex) => {
          if (bannerIndex === index) {
            return false;
          }
          return true;
        })
      );
    },
    onSaveClickHandler: async () => {
      editBannerHandler();
    },
  };

  const editBannerHandler = () => {
    const banners: TPostBannerData[] = [];

    form.forEach((banner) => {
      const data = new FormData();

      data.append("start", ("" + new Date(banner.start).getTime()) as string);
      data.append("end", ("" + new Date(banner.end).getTime()) as string);
      data.append("order", banner.order);

      banner.image && data.append("img", banner.image);

      banner._id
        ? banners.push({ data, _id: banner._id })
        : banners.push({ data });
    });

    Requests.saveBanners(banners);
  };

  const Requests = {
    getBanners: () => {
      dispatch(bannerActions.getBanners());
    },
    removeBanner: (_id: string) => {
      dispatch(bannerActions.removeBanner({ _id }));
    },
    saveBanners: (banners: TPostBannerData[]) => {
      dispatch(bannerActions.saveBanners({ banners }));
    },
  };

  const bannerOrder = form?.map((el: any) => el.order);
  let valueArr = bannerOrder.map((item) => {
    return item;
  });
  let isDuplicate = valueArr.some((item, idx) => {
    return valueArr.indexOf(item) !== idx;
  });

  useEffect(() => {
    if (isDuplicate) {
      dispatch(showToastAction.request(generateErrorToast(t("not.less1"))));
    }
  }, [form]);

  const data: TDataTable[] = useMemo(
    () =>
      form.map((banner, index) => {
        let photo = null;
        const start = new Date(form[index].start).toISOString().substr(0, 10);
        const end = new Date(form[index].end).toISOString().substr(0, 10);

        banners.forEach((element: any) => {
          if (element._id === banner._id) {
            photo = element.image;
          }
        });

        if (start >= end) {
          setDisabled(true);
        } else {
          setDisabled(false);
        }

        if (+banner.order < 0 || isDuplicate) {
          setDisabled(true);
        } else {
          setDisabled(false);
        }

        return {
          number: (
            <>
              {+banner.order < 0 ? (
                <OrderBlock>
                  <Input
                    type="number"
                    name="order"
                    width="100px"
                    value={"" + banner.order}
                    onChange={Events.onChangeHandler}
                  />
                  <SpanOrder>{t("not.less")}</SpanOrder>
                </OrderBlock>
              ) : (
                <>
                  <Input
                    type="number"
                    name="order"
                    width="100px"
                    value={"" + banner.order}
                    onChange={Events.onChangeHandler}
                  />
                </>
              )}
            </>
          ),
          image: (
            <FlexContainer
              style={{ margin: "10px 0" }}
              align="center"
              justify="center"
            >
              <PhotoPicker
                width={235}
                height={110}
                name="image"
                index={index}
                value={photo && `${api.withImageAPI}/banner/${photo}`}
                onChange={Events.onImageChange}
              />
            </FlexContainer>
          ),
          date_start: (
            <DateInput
              name="start"
              onChange={Events.onChangeDateHandler}
              value={start}
            />
          ),
          date_end:
            start >= end ? (
              <Block>
                <DateInput
                  name="end"
                  onChange={Events.onChangeDateHandler}
                  value={end}
                />
                <Span>{t("enter.end.date.greater.than.the.start.date")}</Span>
              </Block>
            ) : (
              <>
                <DateInput
                  name="end"
                  onChange={Events.onChangeDateHandler}
                  value={end}
                />
              </>
            ),
        };
      }),
    [form]
  );

  const columns: TColumnTable[] = useMemo(
    () => [
      {
        Header: t("number"),
        accessor: "number",
        width: 100,
      },
      {
        Header: t("image"),
        accessor: "image",
        width: 400,
      },
      {
        Header: t("date.start"),
        accessor: "date_start",
        width: 400,
      },
      {
        Header: t("date.end"),
        accessor: "date_end",
        width: 400,
      },
    ],
    []
  );

  useEffect(() => {
    setForm(
      banners.map((banner: any) => {
        const { start, end, _id, order } = banner;
        return { start, end, order: "" + order, _id, image: null };
      })
    );
  }, [banners]);

  useEffect(() => {
    Requests.getBanners();
  }, []);

  // Send request if get response from saveBanners request
  useEffect(() => {
    response == BANNER_RESPONSE.SAVED && Requests.getBanners();
  }, [response]);

  const banner = permissions.filter((el: string) => el === "banner").join();
  if (banner !== "banner") navigate("/404");
  return (
    <>
      {!loading ? (
        <Container>
          <Header>
            <Title>{t("banners")}</Title>
            <ButtonNew
              disabled={disabled}
              onClick={Events.onSaveClickHandler}
              theme={"green"}
            >
              {t("save")}
            </ButtonNew>
          </Header>
          <MainContainer>
            <Table
              columns={columns}
              data={data}
              removeClickHandler={Events.removeItemButtonClickHandler}
              removable
            />
            <ButtonContainer>
              <ButtonNew
                width={139}
                height={40}
                onClick={Events.addButtonClickHandler}
              >
                <span>{t("add")}</span>
              </ButtonNew>
            </ButtonContainer>
          </MainContainer>
        </Container>
      ) : (
        <RelativePreloader loading={loading} />
      )}
    </>
  );
};

export default IndexPage;
