import { FC } from 'react';

import { colors } from "../../styles";
import { useTypedSelector } from "../../hooks";
import { getMainSettingSelector } from "../../../store";

import { AbsoluteContainer, LoadingBar } from './styled';
import { TPreloader } from './types';

const Preloader: FC<TPreloader> = ({
   loading,
   size = '80px',
   secondaryColor = colors.white
}) => {
   const { logo } = useTypedSelector(getMainSettingSelector);

   return (
      <>
         {loading && (
            <AbsoluteContainer>
               <LoadingBar size={size} color={logo?.data?.mainColor} secondaryColor={secondaryColor} />
            </AbsoluteContainer>
         )}
      </>
   )
}

export default Preloader
