import { FC } from "react";
import { Button, Container } from "./styled";
import { TSubPagesBar } from "./types";
import { useTypedSelector } from "../../hooks";
import { getMainSettingSelector } from "../../../store/setting";

const SubPagesBar: FC<TSubPagesBar> = ({ sections }) => {
  const { logo } = useTypedSelector(getMainSettingSelector);
  return (
    <Container>
      {sections.map((section, key) => {
        return (
          <Button
            color={logo?.data?.mainColor}
            key={key}
            onClick={section.onClickHandler}
            active={section.active || false}
          >
            {section.title}
          </Button>
        );
      })}
    </Container>
  );
};

export default SubPagesBar;
