import { SyntheticEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import useSWR, { mutate } from "swr";

import {
  ButtonNew,
  Input,
  RelativePreloader,
  useTypedSelector,
} from "../../common";
import {
  deliveryActions,
  getMainDeliverySelector,
  getUserSelector,
} from "../../store";
import { ApiDeliveryService } from "../../store/delivery/api.serrvice";

import {
  Container,
  Header,
  MainContainer,
  Title,
  FormContainer,
  FormBlock,
  Wrapper,
  ErrorP,
  AddReferral,
  ReferralList,
} from "./styled";
import { TForm } from "./types";

const Delivery = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form, setForm] = useState<TForm>({
    deliveryPrice: "",
    freeDeliveryPrice: "",
    cashback: "0",
    referralСode: "",
    bonusCount: "",
  });

  const { accessToken } = useTypedSelector(getUserSelector);
  const token = "Barear " + accessToken;

  const { deliveryConfig, loading } = useTypedSelector(getMainDeliverySelector);

  useEffect(() => {
    dispatch(deliveryActions.getDeliveryConfig({}));
  }, []);

  // operation with referral code
  const { data: referralData } = useSWR(
    ["referralData", token],
    ([, token]) => ApiDeliveryService.getReferral(token),
    { revalidateOnFocus: false }
  );

  const onDeleteReferral = async (id: string) => {
    await ApiDeliveryService.deleteReferral({ id, token });
    mutate(["referralData", token]);
  };

  const createReferral = async () => {
    const data = { code: form.referralСode, bonuses: +form.bonusCount, token };
    await ApiDeliveryService.createRefferal(data);
    setForm((prev) => ({ ...prev, referralСode: "", bonusCount: "0" }));
    mutate(["referralData", token]);
  };

  useEffect(() => {
    if (deliveryConfig) {
      setForm((prev) => ({
        deliveryPrice: deliveryConfig?.data?.deliveryPrice,
        freeDeliveryPrice: deliveryConfig?.data?.freeDeliveryPrice,
        cashback: deliveryConfig?.data?.cashback,
        referralСode: prev.referralСode,
        bonusCount: prev.bonusCount,
      }));
    }
  }, [deliveryConfig]);

  const inputChangeHandler = (e: SyntheticEvent) => {
    const { name, value } = e.target as HTMLInputElement;
    setForm((props) => ({ ...props, [name]: value }));
  };

  const saveDeliveryHandler = () => {
    if (+form.cashback >= 0 && +form.cashback <= 100) {
      const data = {
        deliveryPrice: +form.deliveryPrice,
        freeDeliveryPrice: +form.freeDeliveryPrice,
        cashback: +form.cashback,
      };
      dispatch(deliveryActions.updateDeliveryConfig({ data }));
      dispatch(deliveryActions.getDeliveryConfig({}));
    }
  };

  return (
    <>
      {!loading ? (
        <Container>
          <Header>
            <Title>{t("delivery")}</Title>
            <ButtonNew onClick={saveDeliveryHandler} theme={"green"}>
              {t("save")}
            </ButtonNew>
          </Header>
          <MainContainer>
            {/* <Wrapper>
              <Title>{t("paid.delivery.within.city")}</Title>
              <FormContainer>
                <FormBlock>
                  <Input
                    width={"500px"}
                    name="deliveryPrice"
                    label={t("price.delivery")}
                    type="number"
                    value={form?.deliveryPrice}
                    onChange={inputChangeHandler}
                  />
                </FormBlock>
                <FormBlock>
                  <Input
                    width={"500px"}
                    name="priceProduct"
                    label={t("price.product")}
                    disabled={true}
                    value={"0"}
                    onChange={() => ""}
                  />
                </FormBlock>
              </FormContainer>
            </Wrapper> */}
            {/* <Wrapper>
              <Title>{t("free.delivery.within.city")}</Title>
              <FormContainer>
                <FormBlock>
                  <Input
                    width={"500px"}
                    name="freePriceDeliveryText"
                    label={t("price.delivery")}
                    placeholder={t("enter.discount")}
                    value={"0"}
                    disabled={true}
                    onChange={() => ""}
                  />
                </FormBlock>
                <FormBlock>
                  <Input
                    width={"500px"}
                    type="number"
                    name="freeDeliveryPrice"
                    label={t("price.product")}
                    placeholder={t("enter.discount")}
                    value={form.freeDeliveryPrice}
                    onChange={inputChangeHandler}
                  />
                </FormBlock>
              </FormContainer>
            </Wrapper> */}
            <Wrapper>
              <Title>{t("cashback")}</Title>
              <FormContainer>
                <FormBlock>
                  <Input
                    width={"500px"}
                    name="cashback"
                    label={t("cashbackPercentage")}
                    type="number"
                    value={form?.cashback}
                    onChange={inputChangeHandler}
                  />
                  {(+form.cashback < 0 || +form.cashback > 100) && (
                    <ErrorP>{t("error.cashback")}</ErrorP>
                  )}
                </FormBlock>
              </FormContainer>
            </Wrapper>
            <Wrapper>
              <Title>{t("createReferralСode")}</Title>
              <FormContainer>
                <FormBlock>
                  <Input
                    width={"230px"}
                    placeholder="rdA04f"
                    name="referralСode"
                    label={t("referralCode")}
                    type="text"
                    value={form?.referralСode}
                    onChange={inputChangeHandler}
                  />
                </FormBlock>
                <FormBlock>
                  <Input
                    width={"150px"}
                    name="bonusCount"
                    placeholder="0"
                    label={t("amountOBonuses")}
                    type="number"
                    value={form?.bonusCount}
                    onChange={inputChangeHandler}
                  />
                </FormBlock>
                <AddReferral
                  onClick={createReferral}
                  disabled={
                    +form.bonusCount <= 0 || form.referralСode.length < 4
                  }
                  type="button"
                >
                  {t("add")}
                </AddReferral>
              </FormContainer>
            </Wrapper>
            <ReferralList>
              {referralData?.data.data.map((item) => (
                <li key={item._id}>
                  <div>{item.code}</div>
                  <div>{item.bonuses}</div>
                  <button onClick={() => onDeleteReferral(item._id)}>
                    {t("delete")}
                  </button>
                </li>
              ))}
            </ReferralList>
          </MainContainer>
        </Container>
      ) : (
        <RelativePreloader loading={loading} />
      )}
    </>
  );
};

export default Delivery;
