import styled from "styled-components";
import {
  colors,
  FLEX,
  FONT
} from "../../common";
import { TImage } from "./types";

export const Container = styled.div``;

export const Header = styled.div`
  ${FLEX({justify: 'space-between'})}
`;

export const Title = styled.h1`
  ${FONT({ weight: '700', size: '24px', color: colors.black })}
`;

export const Image = styled.div<TImage>`
   display: inline-block;
   background-image: ${({ src }) => `url(${src})`};
   background-repeat: no-repeat;
   background-size: contain;
   background-position: center;
   height: 102px;
   width: 175px;
   margin: 14px 0;
`;

export const Block = styled.div`
   display: flex;
  justify-content: space-around;
`;

export const Span = styled.div`
  width: 200px;

  margin-top: 5px;
  margin-left: 130px;
  
  ${FONT({weight: '400', size: '14px', color: colors.red})};
`;

export const SpanOrder = styled.div`
  width: 200px;
  margin-top: 5px;

  ${FONT({weight: '400', size: '14px', color: colors.red})};
`;

export const ButtonContainer = styled.div`
   margin-top: 30px;
`;

export const MainContainer = styled.main`
  max-width: 1609px;
  height: 925px;
  
  margin-top: 26px;

  padding: 0 30px;
  
  overflow-y: scroll;
	
	background: white;
	
  box-shadow: 0 14px 28px rgba(135, 135, 135, 0.16);
	
  border-radius: 22px;
`;

export const Name = styled.span`
  ${FONT({ weight: '600', size: '16px', color: colors.white })}
`

export const OrderBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const Photo = styled.img``;
