import styled, { css } from "styled-components";
import { colors, FLEX, FONT, TButton } from "../../common";

export const Container = styled.div``;

export const Title = styled.h1`
  ${FONT({ weight: "700", size: "24px", color: colors.black })};

  margin-left: 14px;
`;

export const Header = styled.div`
  ${FLEX({ justify: "space-between" })}
`;

export const ColorPickerBlock = styled.div`
  margin-top: -30px;
`;

export const Label = styled.p`
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #222222;
`;

export const Wrapper = styled.div`
  background: white;

  padding: 19px 30px;

  margin-top: 26px;

  overflow-y: auto;

  box-shadow: 0 14px 28px rgba(135, 135, 135, 0.16);

  border-radius: 22px;

  max-width: 1609px;
  height: auto;
`;

export const MainContainer = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  padding-right: 30px;
  gap: 30px;
  border-right: 1px solid ${colors.lines};
`;

export const VariationWrapper = styled.div``;

export const VariationsContainer = styled.div`
  max-width: 1115px;
  width: 100%;
  background: #f5f5f5;
  padding: 21px 15px;
  border-radius: 14px;
`;

export const VariationBlock = styled.div``;

export const VariationsHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Name = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 14px;
  color: #222222;
`;

export const Delete = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #dc3333;
  cursor: pointer;
`;

export const VariationsMain = styled.div``;

export const DropdownListBlock = styled.div`
  margin-top: -11px;
`;

export const TitleBlock = styled.div`
  display: flex;
`;

export const Image = styled.img`
  margin-right: 11px;
  cursor: pointer;
`;

export const Message = styled.div`
  font-weight: bold;
  font-size: 25px;
  padding-top: 300px;
  margin-left: 500px;
`;

export const FormBlock = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 8px;
`;
