export const GET_CLIENTS = 'GET_CLIENTS'
export const SET_CLIENTS = 'SET_CLIENTS'

export const SET_CLIENT_STATE = 'SET_CLIENT_STATE'

export const GET_CLIENT = 'GET_CLIENT'
export const SET_CLIENT = 'SET_CLIENT'

export const EDIT_CLIENT = 'EDIT_CLIENT'
export const REMOVE_CLIENTS = 'REMOVE_CLIENTS'

export const RESPONSE = {
   NOT_FOUND: 'NOT_FOUND',
   CREATED: 'CREATED',
   EDITED:'EDITED',
   REMOVED:'REMOVED'
}