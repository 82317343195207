import { NavLink as NavLinks } from "react-router-dom";
import styled from "styled-components";
import { colors, FLEX, FONT } from "../../common/styles";
import { TStyledStatisticsProps } from "./types";

export const Logo = styled.img`
  margin-top: 20px;
  max-height: 50px;
  height: 50%;
`;

export const AdminBlock = styled.div`
  width: 223px;
  height: 55px;

  margin-top: 20px;
  padding: 10px 16px;

  border-radius: 12px;

  background: #fff4ea;

  margin-bottom: 30px;

  ${FLEX({ justify: "flex-start" })}
`;

export const AdminPhoto = styled.img`
  display: inline-block;
  background-image: ${({ src }) => `url(${src})`};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;
`;

export const Name = styled.p`
  margin-left: 10px;

  ${FONT({ weight: "400", size: "12px", color: colors.black })}
`;

export const LayoutWrapper = styled.div`
  width: 100%;

  height: calc(100vh);

  ${FLEX({ align: "flex-start", wrap: "nowrap" })}
`;

export const ContentWrapper = styled.div`
  width: calc(100vw - 252px);

  height: 100%;
  background: #e5e5e5;
  border-left: 1px solid #d1d1d1;
`;

export const ContentInnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 40px 30px;
  overflow: hidden;
  overflow-y: auto;
`;

export const Image = styled.img`
  width: 20px;
  height: 20px;

  margin-right: 15px;

  cursor: pointer;

  ${FLEX({})}
`;

export const SidebarWrapper = styled.div`
  width: 252px;
  height: 100%;

  background: ${colors.white};

  ${FLEX({
    direction: "column",
    align: "flex-start",
    justify: "flex-end",
    wrap: "nowrap",
  })}
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding-top: 20px;

  width: 252px;
`;

export const MenuWrapper = styled.div`
  width: 100%;
  height: 100%;

  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  ${FLEX({
    direction: "column",
    align: "flex-end",
    justify: "flex-start",
    wrap: "nowrap",
  })}
`;

export const NavLink = styled(NavLinks)<TStyledStatisticsProps>`
  width: 100%;
  outline: 0;
  padding: 11px 0 11px 30px;

  cursor: pointer;

  text-decoration: none;
  transition: 0.15s ease-in-out;

  ${FONT({ weight: "400", size: "14px", color: colors.gray })}

  &.active {
    color: ${colors.white};
    background-color: ${({ color }) => `${color}`};
    border-right: ${({ color }) => `${color}`};
  }

  &:hover {
    color: ${colors.white};
    background-color: ${({ color }) => `${color}`};
    border-right: 2px solid ${({ color }) => `${color}`};
  }

  @media (max-width: 1600px) {
    padding: 8px 0 8px 30px;
  }
`;

export const NavLinkBlock = styled.div`
  display: flex;
  align-items: center;
`;

export const ImageBlock = styled.div``;

export const MenuName = styled.div``;

export const LogoutBtn = styled.div`
  transition: 0.15s ease-in-out;
  cursor: pointer;

  padding: 0 0 15px 33px;

  ${FONT({ weight: "400", size: "14px" })}
  color: ${({ color }) => `${color}`};

  ${FLEX({ justify: "flex-start" })}
`;
export const LogoTitle = styled.span`
  color: ${colors.orange};
  font-weight: 700;
  font-size: 28px;
`;
