import styled from "styled-components";

export const Label = styled.label`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #222222;
`;

export const Wrapper = styled.div`
  position: relative;
  z-index: 11;
`;

export const ColorPickerContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const Swatch = styled.div`
  padding: 5px;
  cursor: pointer;
`;

export const Color = styled.div`
  width: 45px;
  height: 45px;
  border-radius: 50%;
`;

export const DisplayColorPicker = styled.div`
  position: absolute;
  z-index: 2;
`

export const ButtonClose = styled.button`
  cursor: pointer;
`;

export const ContainerPicker = styled.div`
	&:not(:last-child){
    margin-right: 20px;
	}
  width: 45px;
  height: 45px;
  border-radius: 50%;
	cursor: pointer;
`;

export const PopupContainer = styled.div`
	position: absolute;
  left: 50px;
  top: 0;
	border-radius: 50px;
  display: flex;
	justify-content: space-around;
  padding: 20px;
	border: 2px solid orange;
  background: white;
`;

export const ColorPickerText = styled.div`
	text-align: left;
  width: 150px	;
`;