import { FC } from 'react';

import { RemoveButton, RemoveButtonIcon } from './styled';
import { TButtonProps } from './types';

const Button: FC<TButtonProps> = ({ width = 36, height = width, onClick }) => {
   return (
      <RemoveButton onClick={onClick} width={width} height={height}>
         <RemoveButtonIcon />
      </RemoveButton>
   )
}

export default Button
