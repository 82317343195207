import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  colors,
  DropDownList,
  FlexContainer,
  RelativePreloader,
  Textarea,
} from "../../../../common";
import { Input } from "../../../../common/components/Input";
import { TProducts, VariationType } from "../../../../store/product/types";
import { api } from "../../../../common/config/api";
import { VariationContainer } from "../VariationContainer";
import { FormBlock } from "../../../CreateProduct/styled";
import plusicon from "../../../../common/assets/icons/plus-grey.svg";
import remove from "../../../../common/assets/icons/delete.svg";
import { FormContainer, MainContainer, Label } from "../../styled";
import {
  ImageWrap,
  LabelPhoto,
} from "../../../CreateProduct/components/BasicData/styled";
import { nanoid } from "@reduxjs/toolkit";
import { TForm } from "../../types";
import { YouTubeVideo } from "../../../CreateProduct/components/BasicData/YoutubeVideo";

const BasicData = ({
  Events,
  form,
  setForm,
  loading,
  brandOptions,
  variationsData,
  productTypeOptions,
  visibilityOptions,
  statusOptions,
  categoryOptions,
  subCategoryOption,
  selectColor,
  products,
  id,
}: {
  setForm: React.Dispatch<React.SetStateAction<TForm>>;
  products: TProducts;
  id?: string;
  [x: string]: any;
}) => {
  const { t } = useTranslation();
  const [previewURLs, setPreviewURLs] = useState<
    { id: string; preview: string }[]
  >([]);

  const variationTypeOptions = useMemo(
    () =>
      Object.keys(VariationType).map((prop) => ({
        name: t(prop),
        value: VariationType[prop as keyof typeof VariationType],
      })),
    []
  );
  const productOptions = products.data
    .filter((item) => item._id !== id)
    .map((item) => ({
      name: item.description.title,
      value: item._id,
    }));

  const setImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const fileObjects = Array.from(files).map((file) => ({
        id: nanoid(),
        file: file,
      }));
      setForm((prev) => ({
        ...prev,
        photos: [...prev.photos, ...fileObjects],
      }));
    }
  };
  const generatePreview = (file: File, id: string) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewURLs((prev) => [
        ...prev,
        { preview: reader.result as string, id },
      ]);
    };
    reader.readAsDataURL(file);
  };

  const deleteImage = (id: string) => {
    setForm((prev) => ({
      ...prev,
      photos: prev.photos.filter((item) => item.id !== id),
    }));
  };
  useEffect(() => {
    if (form.photos) {
      setPreviewURLs([]);
      form.photos.forEach((photo: { id: string; file: File }) => {
        generatePreview(photo.file, photo.id);
      });
    }
  }, [form.photos]);
  return (
    <MainContainer>
      <FormContainer>
        <FlexContainer gap="62px">
          <DropDownList
            name="type"
            label={t("product.type")}
            placeholder={t("enter.type.product")}
            options={productTypeOptions}
            value={form.type}
            onChange={Events.inputChangeHandler}
          />
          {form.type === productTypeOptions[0].value && (
            <>
              <DropDownList
                name="show"
                label={t("visibility")}
                options={visibilityOptions}
                value={form.show}
                onChange={Events.inputChangeShowHandler}
              />
              <Input
                name="sku"
                type={"number"}
                label={t("SKU")}
                placeholder={t("enter.code")}
                value={form.sku}
                onChange={Events.inputChangeHandler}
                disabled
              />
              <DropDownList
                name="gift"
                placeholder={t("chooseAgift")}
                label={t("gift")}
                options={[
                  { name: t("chooseAgift"), value: "" },
                  ...productOptions,
                ]}
                value={form.gift}
                onChange={Events.inputChangeHandler}
              />
            </>
          )}
        </FlexContainer>
        <FormBlock>
          <Input
            name="nameEn"
            width="275px"
            label={t("name")}
            placeholder={t("enter.name.en")}
            value={form.nameEn}
            onChange={Events.inputChangeHandler}
          />
          <Input
            name="nameUa"
            width="275px"
            label={t("name")}
            placeholder={t("enter.name.ua")}
            value={form.nameUa}
            onChange={Events.inputChangeHandler}
          />
          <Input
            name="nameRu"
            width="275px"
            label={t("name")}
            placeholder={t("enter.name.ru")}
            value={form.nameRu}
            onChange={Events.inputChangeHandler}
          />
        </FormBlock>
        <FormBlock>
          <Textarea
            width="275px"
            name="descriptionEn"
            height="260px"
            placeholder={t("enter.description.en")}
            label={t("description")}
            onChange={Events.inputChangeHandler}
          >
            {form.descriptionEn}
          </Textarea>
          <Textarea
            width="275px"
            name="descriptionUa"
            height="260px"
            placeholder={t("enter.description.ua")}
            label={t("description")}
            onChange={Events.inputChangeHandler}
          >
            {form.descriptionUa}
          </Textarea>
          <Textarea
            width="275px"
            name="descriptionRu"
            height="260px"
            placeholder={t("enter.description.ru")}
            label={t("description")}
            onChange={Events.inputChangeHandler}
          >
            {form.descriptionRu}
          </Textarea>
        </FormBlock>

        {form.type === productTypeOptions[0].value ? (
          <FlexContainer gap="30px">
            <FlexContainer gap="62px">
              <Input
                name="price"
                type="number"
                label={t("price")}
                placeholder={t("enter.price")}
                value={form.price}
                onChange={Events.inputChangeHandler}
              />
              <Input
                name="discountPrice"
                type="number"
                label={t("discount.price")}
                placeholder={t("enter.discount.price")}
                value={form.discountPrice}
                onChange={Events.inputChangeHandler}
              />
            </FlexContainer>
            <FlexContainer gap="62px">
              <Input
                name="amount"
                label={t("amount")}
                placeholder={t("enter.amount")}
                value={form.amount}
                onChange={Events.inputChangeHandler}
              />
              <DropDownList
                name="sellStatus"
                label={t("status")}
                options={statusOptions}
                placeholder={t("enter.status")}
                value={form.sellStatus}
                onChange={Events.inputChangeHandler}
              />
            </FlexContainer>
            <Input
              name="video"
              type={"text"}
              label={t("video")}
              placeholder={t("enter.video")}
              value={form.video}
              onChange={Events.inputChangeHandler}
            />
            {form.video && <YouTubeVideo videoLink={form.video} />}
          </FlexContainer>
        ) : (
          <>
            <>
              {!loading ? (
                <VariationContainer
                  selectColor={selectColor}
                  variationsData={variationsData}
                  variationTypeOptions={variationTypeOptions}
                />
              ) : (
                <RelativePreloader loading={loading} />
              )}
            </>
            <FlexContainer gap="30px">
              <FlexContainer gap="62px">
                <Input
                  name="price"
                  type="number"
                  label={t("price")}
                  placeholder={t("enter.price")}
                  value={form.price}
                  onChange={Events.inputChangeHandler}
                />
                <Input
                  name="discountPrice"
                  type="number"
                  label={t("discount.price")}
                  placeholder={t("enter.discount.price")}
                  value={form.discountPrice}
                  onChange={Events.inputChangeHandler}
                />
              </FlexContainer>
              <FlexContainer gap="62px">
                <Input
                  name="amount"
                  label={t("amount")}
                  placeholder={t("enter.amount")}
                  value={form.amount}
                  onChange={Events.inputChangeHandler}
                />
                <DropDownList
                  name="sellStatus"
                  label={t("status")}
                  options={statusOptions}
                  placeholder={t("enter.status")}
                  value={form.sellStatus}
                  onChange={Events.inputChangeHandler}
                />
              </FlexContainer>
              <FlexContainer gap="62px">
                <Input
                  name="video"
                  type={"text"}
                  label={t("video")}
                  placeholder={t("enter.video")}
                  value={form.video}
                  onChange={Events.inputChangeHandler}
                />
              </FlexContainer>

              {form.video && <YouTubeVideo videoLink={form.video} />}
            </FlexContainer>
          </>
        )}
      </FormContainer>
      <FlexContainer direction="column" align="center" gap="">
        <FlexContainer
          direction="column"
          gap="30px"
          style={{ marginTop: "8px", padding: "24px 30px" }}
        >
          {/* <DropDownList
            name="section"
            label={t("section")}
            options={sectionOptions}
            value={form.section}
            placeholder={t("choose.category")}
            width="100%"
            onChange={Events.inputChangeHandler}
          /> */}
          <DropDownList
            name="category"
            label={t("category")}
            options={categoryOptions}
            value={form.category}
            placeholder={t("choose.category")}
            width="100%"
            onChange={Events.inputChangeHandler}
          />
          <DropDownList
            name="subcategory"
            label={t("subcategory")}
            options={subCategoryOption}
            value={form.subcategory}
            placeholder={t("choose.subcategory")}
            width="100%"
            onChange={Events.inputChangeHandler}
          />
          <DropDownList
            name="brand"
            label={t("brand")}
            options={brandOptions}
            value={form.brand}
            placeholder={t("choose.brand")}
            width="100%"
            onChange={Events.inputChangeHandler}
          />
        </FlexContainer>
        <FlexContainer
          direction="column"
          style={{
            borderTop: `1px solid ${colors.lines}`,
            marginTop: "8px",
            padding: "24px 30px",
          }}
        >
          <Label>{t("photo")}</Label>
          <FlexContainer gap="20px">
            <LabelPhoto>
              <img alt="add" src={plusicon} />
              <input
                type="file"
                multiple
                accept="image/*"
                onChange={setImage}
              />
            </LabelPhoto>
            {previewURLs.map((item) => (
              <ImageWrap key={nanoid()}>
                {form.photos?.length + form.gallery.length > 1 && (
                  <button onClick={() => deleteImage(item.id)}>
                    <img alt="delete" src={remove} />
                  </button>
                )}
                <img alt="gallery" src={item.preview} />
              </ImageWrap>
            ))}
            {form.gallery?.map((item: { id: string; path: string }) => (
              <ImageWrap key={nanoid()}>
                {form.photos?.length + form.gallery.length > 1 && (
                  <button
                    onClick={() =>
                      setForm((prev) => ({
                        ...prev,
                        gallery: prev.gallery.filter(
                          (photo) => photo.id !== item.id
                        ),
                      }))
                    }
                  >
                    <img alt="delete" src={remove} />
                  </button>
                )}
                <img
                  alt="gallery"
                  src={`${api.withImageAPI}/product/gallery/${item.path}`}
                />
              </ImageWrap>
            ))}
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    </MainContainer>
  );
};

export default BasicData;
