import { AxiosResponse } from "axios";

import { HttpService } from "../http.service";
import { TResponse } from "../types";

import { SUPPORT_URL } from "./config";
import {
  TRequestModifyRequestPayload,
  TSupportRequestsRequestPayload,
} from "./types";

export class ApiSupportService extends HttpService {
  static getRequests({
    token,
    limit,
    page,
    lang,
  }: TSupportRequestsRequestPayload): Promise<AxiosResponse<TResponse>> {
    return this.request<TResponse>({
      url: `${SUPPORT_URL.getRequests}?limit=${limit}&skip=${
        limit * page
      }&lang=${lang}`,
      method: "GET",
      headers: {
        Authorization: token,
      },
    });
  }

  static modifyRequest({
    token,
    _id,
    status,
  }: TRequestModifyRequestPayload): Promise<AxiosResponse<TResponse>> {
    return this.request<TResponse>({
      url: `${SUPPORT_URL.modifyRequest}/${_id}`,
      method: "PATCH",
      data: {
        status,
      },
      headers: {
        Authorization: token,
      },
    });
  }
}
