import { FC, useState, useEffect, SyntheticEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { FlexContainer } from '../Styled';
import {
   Container,
   CrossIcon,
   ErrorLable,
   ImageButton,
   Label,
   PlusIcon
} from './styled';
import { TPhotoPicker } from './types';

const PhotoPicker: FC<TPhotoPicker> = ({ width, height, activeCross, name, label, onChange, value, index, file: inputFile, limit = 600000 }) => {
   const { t } = useTranslation()

   const [photo, setPhoto] = useState<string>(value as string);
   const [file, setFile] = useState<File | null | undefined>(undefined);
   const [errorSize, setErrorSize] = useState<boolean>(false);
   const [oldFile, setOldFile] = useState<File | null>(null);

   const inputRef = useRef<HTMLInputElement>(null);

   const Events = {
      buttonClickHandler: (e: SyntheticEvent) => {
         const input = inputRef.current as HTMLInputElement

         input.onchange = (e) => {
            const input = e.target as HTMLInputElement
            file && setOldFile(file)
            const newFile = input.files ? input.files[0] : null
            setErrorSize(false)

            if (newFile && newFile.size > limit) {
               setErrorSize(true)
               return
            }

            setFile(newFile)
            ;(e.target as HTMLInputElement).value = ''
         }

         input.click()
      },
      removePhotos: () => {
         setFile(null)
         setPhoto('')
      },
      removeButtonClickHandler() {
         file !== undefined && onChange && onChange(null, index as number, name as string, file)
         Events.removePhotos()
      }
   }

   const Utils = {
      readFileAsImage: () => {
         const reader = new FileReader()
         file && reader.readAsDataURL(file)
         reader.onload = (e) => {
            setPhoto((e.target as FileReader).result as string)
         }
      }
   }

   // When file was changed
   useEffect(() => {
      file && Utils.readFileAsImage()
      file !== undefined && onChange && onChange(file, index as number, name as string, oldFile || null)
   }, [file])

   // When inputFile was changed
   useEffect(() => {
      inputFile && setFile(inputFile)
      inputFile == null && Events.removePhotos()
   }, [inputFile])

   // If name was changed
   useEffect(() => {
      inputFile == null && Events.removePhotos()
   }, [name])

   // if photo was changed
   useEffect(() => {
      setPhoto(value as string)
   }, [value])

   return (
      <Container>
         <FlexContainer direction="column" width="auto">
            <Label>{label}</Label>
            <FlexContainer width="auto" position="relative">
               <ImageButton width={width} height={height} onClick={Events.buttonClickHandler} src={photo}>
                  <PlusIcon />
                  <input className="photo-input" name={name + 'input'} ref={inputRef} type="file" accept="image/*" />
               </ImageButton>
               { activeCross && <CrossIcon onClick={Events.removeButtonClickHandler} hidden={!photo} /> }
            </FlexContainer>
            {errorSize && (
               <ErrorLable>{`${t('file.is.too.big.please.upload.smaller.then')} ${limit} ${t('bytes')}`}</ErrorLable>
            )}
         </FlexContainer>
      </Container>
   )
}

export default PhotoPicker
