import styled from 'styled-components'
import { TStyledButtonProps } from './types'
import { BORDER, colors, FONT } from "../../styles";
import { Assets } from "../../assets";

export const StyledButton = styled.button<TStyledButtonProps>`
   display: flex;
   justify-content: center;
   align-items: center;
   gap: 9px;
   cursor: pointer;
   padding: 14px 16px;
   border-radius: 5px;
   width: 100%;
   line-height: 19px;
   transition: background-color 0.3s ease;
   max-width: ${({ width }) => `${width}px`};
   height: ${({ height }) => `${height}px`};

   ${FONT({ color: colors.white, weight: '600', size: '16px' })}

   &.orange {
      background-color: ${({ color }) => `${color}`};
      border: 1px solid ${({ color }) => `${color}`};

      &:hover {
         color:  ${({ color }) => `${color}`};
         & > * {
            fill:  ${({ color }) => `${color}`};
         }

         & * {
            filter: ${({ color }) => `${color}`};
         }
      }
   }

   &.green {
      background-color: ${colors.green};
      ${BORDER({ color: colors.green })};

      &:hover {
         color: ${colors.green};

         & * {
            filter: ${colors.green};
         }
      }
   }

   &.red {
      background-color: ${colors.red};
      ${BORDER({ color: colors.red })};

      &:hover {
         color: ${colors.red};

         & * {
            filter: ${colors.red};
         }
      }
   }

   &:hover {
      background-color: transparent;
      line-height: 19px;
   }

   &.gray {
      background-color: ${colors.gray};
      ${BORDER({ color: colors.gray })};

      &:hover {
         color: ${colors.gray};

         & * {
            filter: ${colors.gray};
         }
      }
   }

   &:hover {
      background-color: transparent;
      line-height: 19px;
   }

   &.transparant {
      background-color: transparent;

      &.red {
         color: ${colors.red};
      }

      &.green {
         color: ${colors.green};
      }

      &.orange {
         color:  ${({ color }) => `${color}`};
      }

      &.gray {
         color: ${colors.gray};
      }

      &:hover {
         color: ${colors.white};

         &.red {
            background-color: ${colors.red};
         }

         &.green {
            background-color: ${colors.green};
         }

         &.orange {
            background-color: ${({ color }) => `${color}`};
         }

         &.gray {
            background-color: ${colors.gray};
         }
      }
   }
`

export const EditButton = styled.button<TStyledButtonProps>`
   width: ${({ width }) => `${width}px`};
   height: ${({ height }) => `${height}px`};
   border-radius: 6px;
   background-color: ${({ color }) => `${color}`};
   border: none;
   cursor: pointer;
   position: relative;
`

export const RemoveButton = styled(EditButton)`
   background-color: ${colors.red};
`

export const EditButtonIcon = styled.img.attrs({ src: Assets.DESCRIPTION_ICON})`
   position: absolute;
   top: 50%;
   left: 50%;
   -webkit-transform: translate(-50%, -50%);
   -ms-transform: translate(-50%, -50%);
   transform: translate(-50%, -50%);
   width: auto;
   height: 50%;
`

export const RemoveButtonIcon = styled(EditButtonIcon).attrs({ src: Assets.DELETE_ICON })``
