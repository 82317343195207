import { FC } from 'react';

import { AlignContainer } from '../Styled';
import { colors } from "../../styles";
import { useTypedSelector } from "../../hooks";
import { getMainSettingSelector } from "../../../store";

import { RelativeLoadingBar } from './styled';
import { TPreloader } from './types';

const RelativePreloader: FC<TPreloader> = ({
   loading,
   size = '85px',
   secondaryColor = colors.white
}) => {

   const { logo } = useTypedSelector(getMainSettingSelector);

   return (
      <>
         {loading && (
            <AlignContainer style={{ width: '100%', height: '100%' }}>
               <RelativeLoadingBar size={size} color={logo?.data?.mainColor} secondaryColor={secondaryColor} />
            </AlignContainer>
         )}
      </>
   )
}

export default RelativePreloader
