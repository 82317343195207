import styled from "styled-components";
import { colors, FONT } from "../../common";

export const Container = styled.div``;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.h1`
  ${FONT({ weight: '700', size: '24px', color: colors.black })}
`;

export const ButtonContainer = styled.div`
  display: flex;
`;

export const ButtonBlock = styled.div`
  margin-left: 30px;
`;

export const MainContainer = styled.main`
  max-width: 1609px;
  height: 925px;
  
  margin-top: 26px;
	
	background: white;
	
  box-shadow: 0 14px 28px rgba(135, 135, 135, 0.16);
	
  border-radius: 22px;
`;
