import React, {
	FC,
	SyntheticEvent,
	useEffect,
	useMemo,
	useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
	Assets,
	ButtonNew,
	Checkbox,
	generateErrorToast,
	Input,
	ItemsPerRowGridContainer,
	PhotoPicker,
	useTypedSelector,
	useValidation
} from "../../common";
import {
	ADMIN_RESPONSE,
	adminActions,
	getMainAdminSelector,
	showToastAction,
} from "../../store";

import {
	ButtonBlock,
	Container,
	FlexStyledContainer,
	Header,
	MainContainer,
	Title,
	TitleBlock,
	Image,
	PermissionWrapper,
	PermissionContainer,
	PermissionTitle,
	PermissionBlock,
	Name
} from './styled';
import {TCreateRoleValidationSchema, TData, TForm} from "./types";

const CreatePage: FC = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { response } = useTypedSelector(getMainAdminSelector);

	const [data, setData] = useState<TData[]>( [
		{title: 'order', checked: false},
		{title: 'product', checked: false},
		{title: 'client', checked: false},
		{title: 'category', checked: false},
		{title: 'support', checked: false},
		{title: 'clientGroup', checked: false},
		{title: 'banner', checked: false},
		{title: 'delivery', checked: false},
		{title: 'feedback', checked: false},
		{title: 'shop', checked: false},
		{title: 'statistic', checked: false},
		{title: 'admin', checked: false},
		{title: 'config', checked: false},
	]);
	const [role, setRole] = useState<string[]>([]);
	const [form, setForm] = useState<TForm>({
		name: '',
		surname: '',
		login: '',
		password: '',
		profile_photo: null
	});
	const addRoleArrayHandle = (el: TData) => {
		let newData = data.map((element) => {
			if (el === element) {
				return {...element, checked: !element.checked};
			} else {
				return element;
			}
		});
		setData(newData);
		if(role.includes(el.title)) {
			setRole((prevState) =>
				prevState.filter((element:string) => el.title !== element));
		} else {
			setRole((prevState) => [...prevState, el.title]);
		}
	};

	const schema = useMemo<TCreateRoleValidationSchema>(
		() => ({
			login: {
				condition: form.login.length >= 4,
				error: t('enter.login.limit')
			},
			password: {
				condition: form.password.length >= 4,
				error: t('enter.password.limit')
			},
			name: {
				condition: form.name.length >= 2,
				error: t('enter.name')
			},
			surname: {
				condition: form.surname.length >= 2,
				error: t('enter.surname')
			},
		}),
		[form]
	);

	const {
		validation,
		enableValidation,
		disableValidation,
	} = useValidation(schema);


	const Events = {
		backButtonClickHandler: () => {
			navigate('/role')
		},
		inputChangeHandler: (e: SyntheticEvent) => {
			const { name, value } = e.target as HTMLInputElement
			setForm((props) => ({ ...props, [name]: value }))
		},
		onProfilePhotoChange: (file: File | null) => {
			setForm({ ...form, profile_photo: file })
		},
		createAdminHandler: async () => {
			const data = new FormData()
			form.name && data.append('name', form.name)
			form.profile_photo && data.append('img', form.profile_photo as File)
			form.surname && data.append('secondName', form.surname)
			form.login && data.append('login', form.login)
			form.password && data.append('password', form.password)
			role && role.map((el:string) => data.append('permissions[]',el))
			try {
				enableValidation();
				await validation();
				dispatch(adminActions.createAdmin({ data }))
				disableValidation()
			} catch (error:any) {
				error?.map((err:string) => {
					dispatch(showToastAction.request(generateErrorToast(err)));
				});
			}
		}
	};

	useEffect(() => {
		if (response === ADMIN_RESPONSE.CREATED) {
			navigate('/role')
		}
	}, [response]);

	return (
		<Container>
			<Header>
				<TitleBlock>
					<Image onClick={Events.backButtonClickHandler} src={Assets.ARROW_LEFT}/>
					<Title>{t('create')}</Title>
				</TitleBlock>
				<FlexStyledContainer>
					<ButtonBlock>
						<ButtonNew theme="green" onClick={Events.createAdminHandler}>
							{t('save')}
						</ButtonNew>
					</ButtonBlock>
				</FlexStyledContainer>
			</Header>
			<MainContainer>
				<ItemsPerRowGridContainer perRow={2} width="700px" columnGap="62px" rowGap="30px">
					<Input
						name="name"
						label={t('name')}
						placeholder={t('enter.name')}
						onChange={Events.inputChangeHandler}
						value={form.name}
					/>
					<Input
						name="surname"
						label={t('surname')}
						placeholder={t('enter.surname')}
						onChange={Events.inputChangeHandler}
						value={form.surname}
					/>
					<Input
						name="login"
						label={t('login')}
						placeholder={t('enter.login.limit')}
						onChange={Events.inputChangeHandler}
						value={form.login}
					/>
					<Input
						name="password"
						label={t('password')}
						placeholder={t('enter.password.limit')}
						onChange={Events.inputChangeHandler}
						value={form.password}
					/>
					<PhotoPicker width={150} height={150} label={t('profile.photo')} onChange={Events.onProfilePhotoChange} />
				</ItemsPerRowGridContainer>
				<PermissionWrapper>
						<PermissionTitle>{t('access')}</PermissionTitle>
					  <PermissionContainer>
						  {
								data.map((el, idx) => (
										<PermissionBlock key={idx}>
											<Checkbox checked={el.checked} onChange={() => addRoleArrayHandle(el)}/>
											<Name>{t(el.title)}</Name>
										</PermissionBlock>
								))
						  }
					  </PermissionContainer>
				</PermissionWrapper>
			</MainContainer>
		</Container>
	)
}

export default CreatePage
