import { FC, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import { AlignContainer } from '../Styled';
import { TInfinityScroll } from './types';

const InfinityScroll: FC<TInfinityScroll> = ({ children, next, preloader, loading }) => {
   const { ref, inView } = useInView({
      /* Optional options */
      threshold: 0
   })

   useEffect(() => {
      inView && next()
   }, [inView])

   return (
      <div className="infinity-scroll">
         {children}
         {loading && <AlignContainer ref={ref}>{preloader}</AlignContainer>}
      </div>
   )
}

export default InfinityScroll
