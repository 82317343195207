import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import {
	ButtonNew,
	EColors,
	ELocales,
	FlexContainer,
	Input,
	useTypedSelector
} from "../../../../common";
import { getMainProductSelector, productActions, RESPONSE } from "../../../../store";

import {
	ColorPickerBlock,
	ContainerPicker,
	Label,
	Name,
	PopupContainer,
	VariationBlock,
	VariationsHeader,
	VariationsMain,
	WrapperPicker
} from "../../styled";
import {ColorPickerText} from "../../../../common/components/ColorPicker/styled";

const VariationValue = (
	{
		variationsData,
		formVariations,
		variationTitle,
		setValuesText,
		valuesText,
		index,
		setValuesColor,
		valuesColor,
		variationTextId,
		setVariationTextId,
		variationColorId,
		setVariationColorId,
		selectColor,
	}: any) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { id } = useParams();

	const { variationValue, newProduct, response } = useTypedSelector(getMainProductSelector);

	const [groupType, setGroupType] = useState<any>({});

	const [idVariationValue, setIdVariationValue] = useState('');
	const [popupIsOpen, setPopupIsOpen] = useState(false);
	const [activeOnChange, setActiveOnChange] = useState(false);
	const [variationValues, setVariationValues] = useState({
		bgUrl: variationTitle.values[index].bgUrl,
		color: variationTitle.values[index].color,
		title: variationTitle.values[index].title,
		order: index
	});
	const [type, setType] = useState('');

	const handleOpenPopup = () => {
		setPopupIsOpen(true);
	};

	const createDefaultVariationValue = async (id: string, type:string) => {
		const data = {
			variation: id,
			bgUrl: '',
			color: '',
			title: [
				{
					title: '',
					lang: ELocales.en
				},
				{
					title: '',
					lang: ELocales.uk
				},
				{
					title: '',
					lang: ELocales.de
				}
			],
			order: variationTitle.values.length + 1
		}
		setType(type);
		dispatch(productActions.createVariationValue({data}));
	};

	useEffect(() => {
		if(variationTitle){
			if(variationTitle?.type === 'color'){
				setValuesColor(variationTitle.values.map((el:any) => el?.id))
				setVariationColorId(variationTitle?.id)
			}
			if(variationTitle?.type === 'text'){
				setValuesText(variationTitle.values.map((el:any) => el?.id))
				setVariationTextId(variationTitle?.id)
			}
		}
	}, [variationTitle]);

  useEffect(() => {
   if(variationsData.length === 2){
		  setGroupType({
			  variations: [
				  {
					  variation: variationColorId,
					  values: valuesColor
				  },
				  {
					  variation: variationTextId,
					  values:  valuesText
				  }
			  ]
		  })
	  }else{
		 setGroupType({
			 variations: [
				 {
					 variation: variationColorId === '' ? variationTextId : variationColorId,
					 values: variationColorId === '' ? valuesText : valuesColor
				 }
			 ]
		 })
   }
  }, [variationsData, variationColorId, variationTextId, valuesText, valuesColor]);

	useEffect(() => {
		if(response === RESPONSE.CREATED_VARIATION_VALUE){
			if (type === 'color') {
				setValuesColor((value: any) => [...value, variationValue?.data?._id])
				setType('');
			}
			if(type === 'text') {
				setValuesText((value: any) => [...value, variationValue?.data?._id])
				setType('');
			}
		}
	}, [response, type]);

	useEffect(() => {
		const data = groupType;
		if(response === RESPONSE.CREATED_VARIATION_VALUE){
			dispatch(productActions.editGroup({_id: newProduct?.group?._id, data}))
		}
		if( response === RESPONSE.EDITED_GROUP){
			dispatch(productActions.getProduct({_id: id as string}))
			dispatch(productActions.getProduct({_id: id as string}))
			dispatch(productActions.getProduct({_id: id as string}))
		}
	}, [response, groupType]);

	const handleChooseColor = useCallback((color: string, id: string) => {
		setActiveOnChange(true);
		setVariationValues((prevState: any) => {
			return {
				...prevState,
				bgUrl: color,
				color: color
			}
		});
		setIdVariationValue(id);
		setPopupIsOpen(false);
	}, [variationValues, idVariationValue]);

	const inputChangeHandler = useCallback((e: SyntheticEvent, id: string) => {
		setActiveOnChange(true);
		const {value} = e.target as HTMLInputElement;
		setVariationValues((prevState: any) => {
			return {
				...prevState,
				title: [
					{
						title: value,
						lang: ELocales.en
					},
					{
						title: value,
						lang: ELocales.uk
					},
					{
						title: value,
						lang: ELocales.de
					}
				],
			}
		});
		setIdVariationValue(id);
	}, [variationValues, idVariationValue]);

	useEffect(() => {
		const data = variationValues;
		activeOnChange && dispatch(productActions.editVariationValue({_id: idVariationValue, data}));
	}, [variationValues]);

	return (
		<VariationBlock className={'variationValue_form_container'} key={formVariations.order}>
			<VariationsHeader>
				<Name>{t('value.for.variation')}</Name>
			</VariationsHeader>
			<VariationsMain>
				<FlexContainer gap={'62px'} style={{padding: '10px 0'}} align={'center'}>
					<Input
						name="title"
						placeholder={t('enter.title')}
						value={variationValues.title[0].title}
						onChange={(event) => inputChangeHandler(event, formVariations.id)}
					/>
					{
						variationTitle.type === 'color' &&
						<ColorPickerBlock>
							<Label>{t('display.type')}</Label>
							<WrapperPicker>
								<ContainerPicker onClick={handleOpenPopup} style={{background: variationValues?.bgUrl}}>
									{!formVariations.color && <ColorPickerText>{'Натисніть, щоб вибрати колір'}</ColorPickerText> }
								</ContainerPicker>
								{
									popupIsOpen &&
									<PopupContainer>
										{
											Object.values(EColors)
												.map((color, idx) =>
													selectColor.includes(color) ? (
														<></>
													):(
														<ContainerPicker
															key={idx}
															onClick={() => handleChooseColor(color, formVariations.id)}
															style={{background: color}}
														/>
													)
												)

										}
									</PopupContainer>
								}
							</WrapperPicker>
						</ColorPickerBlock>
					}
					<ButtonNew onClick={() => createDefaultVariationValue(variationTitle?.id, variationTitle?.type)} width={139} height={40}>
						<span>{t('add')}</span>
					</ButtonNew>
				</FlexContainer>
			</VariationsMain>
		</VariationBlock>
	);
};

export default VariationValue;