import { createReducer } from '@reduxjs/toolkit';

import { TInitialState } from './types';
import { brandActions } from "./actions";

const InitialState: TInitialState = {
	brands: {
		meta: null,
		data: []
	},
	loading: false,
	response: null,
}

const reducer = createReducer<TInitialState>(InitialState, (builder) => {
	builder
		.addCase(brandActions.setBrands, (state, { payload }) => ({ ...state, brands: payload }))
		.addCase(brandActions.setBrandsState, (state, { payload }) => ({ ...state, response: null, ...payload }))
})

export default reducer