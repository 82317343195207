import styled from "styled-components"
import { colors } from "../../styles"

export const Container = styled.div`
  
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
    
`

export const BlurContainer = styled.div`

  width: 100%;
  height: 100%;

  background-color: ${colors.black};
  opacity: 0.4;
`

export const ModalContainer = styled.div`

  background-color: ${colors.white};

  padding: 43px 80px 42px 80px;
  
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  border-radius: 20px;
  max-height: 100vh;
`