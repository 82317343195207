import React, { SyntheticEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  AlignContainer,
  Assets,
  ButtonNew,
  Divider,
  FlexContainer,
  useTypedSelector,
  Input,
  RelativePreloader,
} from "../../common";

import { bonusActions, getBonusesSelector } from "../../store";
import { getUserSelector } from "../../store/user";

import {
  Container,
  Header,
  MainContainer,
  Title,
  StyledImage,
  Line,
  DeleteButton,
  Span,
  InputBonusBlock,
} from "./styled";
import { TBonus } from "../../store/bonus/types";

const BonusSystem = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const bonuses = useTypedSelector(getBonusesSelector);
  const { permissions } = useTypedSelector(getUserSelector);

  const [form, setForm] = useState(bonuses.data);
  const [disabled, setDisabled] = useState(false);

  const Events = {
    inputHandler: (e: SyntheticEvent, value: string | number) => {
      const input = e.target as HTMLInputElement;
      const bonusContainerIndex = Utils.getBonusIndex(e);

      setForm((form) =>
        form.map((bonus: TBonus, index: number) => {
          if (index !== bonusContainerIndex) return bonus;
          return { ...bonus, [input.name]: value };
        })
      );
    },
    onAddClickHandler: () => {
      setForm(form.concat([{ name: "", discount: 0 }] as typeof form));
    },
    onSaveClickHandler: () => {
      dispatch(bonusActions.saveBonusesRequest(form));
    },
    removeButtonClickHandler: (e: SyntheticEvent) => {
      const bonusContainerIndex = Utils.getBonusIndex(e);

      const bonus = form[bonusContainerIndex];

      const id = bonus._id;

      id && dispatch(bonusActions.deleteBonusRequest({ id }));

      setForm(
        form.filter((bonus: any, index: number) => {
          return index !== bonusContainerIndex;
        })
      );
    },
  };

  useEffect(() => {
    form.map((el) => {
      if (
        el.discount >= 0 &&
        el.discount <= 100 &&
        el.discount.toString() !== ""
      ) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    });
  }, [form]);

  const Utils = {
    getBonusIndex: (e: SyntheticEvent): number => {
      const target = e.target as HTMLElement;
      const bonusesContainer = target.closest(".bonuses_form_container");
      const bonusContainer = target.closest(".bonus_form_container");
      const bonusContainerIndex = Array.prototype.indexOf.call(
        bonusesContainer?.children,
        bonusContainer
      );

      return bonusContainerIndex;
    },
  };

  useEffect(() => {
    dispatch(bonusActions.getBonusesRequest());
  }, []);

  useEffect(() => {
    if (bonuses.data) {
      setForm(bonuses.data);
    }
  }, [bonuses.data]);

  const clientGroup = permissions
    .filter((el: string) => el === "clientGroup")
    .join();
  if (clientGroup !== "clientGroup") navigate("/404");
  return (
    <>
      {!bonuses.loading ? (
        <Container>
          <Header>
            <Title>{t("bonus.system")}</Title>
            <ButtonNew
              disabled={disabled}
              onClick={Events.onSaveClickHandler}
              theme={"green"}
            >
              {t("save")}
            </ButtonNew>
          </Header>
          <MainContainer>
            <FlexContainer gap="30px" className="bonuses_form_container">
              {form.map((bonus: TBonus, index: number) => {
                return (
                  <FlexContainer
                    key={index}
                    gap="30px"
                    className="bonus_form_container"
                    align="flex-start"
                  >
                    <Input
                      width={"568px"}
                      name="name"
                      label={t("customer.group")}
                      placeholder={t("enter.client.group")}
                      value={bonus.name}
                      onChange={Events.inputHandler}
                    />

                    {bonus.discount >= 0 &&
                    bonus.discount <= 100 &&
                    bonus.discount !== 0 ? (
                      <>
                        <Input
                          width={"568px"}
                          name="discount"
                          type="percentage"
                          label={t("discount")}
                          placeholder={t("enter.discount")}
                          value={bonus.discount.toString()}
                          onChange={Events.inputHandler}
                        />
                      </>
                    ) : (
                      <InputBonusBlock>
                        <Input
                          width={"568px"}
                          name="discount"
                          type="percentage"
                          label={t("discount")}
                          placeholder={t("enter.discount")}
                          value={bonus.discount.toString()}
                          onChange={Events.inputHandler}
                        />

                        <Span>{t("enter.discount.least.0.more.than.100")}</Span>
                      </InputBonusBlock>
                    )}
                    <AlignContainer>
                      <DeleteButton
                        style={{ marginTop: "26px" }}
                        onClick={Events.removeButtonClickHandler}
                      >
                        <StyledImage src={Assets.DELETE_ICON} />
                      </DeleteButton>
                    </AlignContainer>
                    <Line />
                  </FlexContainer>
                );
              })}
            </FlexContainer>
            <Divider height={30} />
            <ButtonNew
              width={139}
              height={40}
              onClick={Events.onAddClickHandler}
            >
              <span>{t("add")}</span>
            </ButtonNew>
          </MainContainer>
        </Container>
      ) : (
        <RelativePreloader loading={bonuses.loading} />
      )}
    </>
  );
};

export default BonusSystem;
