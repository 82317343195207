import styled from "styled-components";

export const Container = styled.div<any>`
  margin-top: 27px;
	padding: 30px 22px;
  width: ${({ width }) => `${width}px`};
	height: 402px;
	background-color: white;
  border-radius: 16px;
  box-shadow: 0 10px 22px rgba(135, 135, 135, 0.16);
`;

export const DoughnutContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const ChartContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
  padding-top: 12px;
`;

export const LineContainer = styled.div``;

export const H2 = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #818181;
`;

export const P = styled.div`
  margin-top: 8px;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #222222;
`