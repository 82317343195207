import React from 'react';

import {
	BlurContainer,
	Container,
	ModalContainer
} from './styled';

const Modal = ({ children }:any) => {
	return (
		<Container>
			<ModalContainer>
				{ children }
			</ModalContainer>
			<BlurContainer />
		</Container>
	);
};

export default Modal;