import { FC } from 'react';

import { useTypedSelector } from "../../hooks";
import { getMainSettingSelector } from "../../../store";

import {
  Container,
  SearchBarTemplate,
  SearchIcon
} from './styled';
import { TSearchBar } from './types';

const SearchBar: FC<TSearchBar> = ({ name, value = '', placeholder = '', onChange, onSubmit }) => {

  const { logo } = useTypedSelector(getMainSettingSelector);

   return (
      <form onSubmit={onSubmit}>
         <Container>
            <SearchBarTemplate
               color={logo?.data?.mainColor}
               name={name}
               value={value}
               placeholder={placeholder}
               onChange={onChange}
               onSubmit={onSubmit}
            />
            <SearchIcon />
         </Container>
      </form>
   )
}

export default SearchBar
