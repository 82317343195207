export const GET_CATEGORIES = 'GET_CATEGORIES'
export const SET_CATEGORIES = 'SET_CATEGORIES'
export const SET_CATEGORY_STATE = 'SET_CATEGORY_STATE'
export const REMOVE_CATEGORIES = 'REMOVE_CATEGORIES'
export const EDIT_CATEGORY = 'EDIT_CATEGORY'
export const CREATE_CATEGORY = 'CREATE_CATEGORY'
export const SET_CATEGORY_BY_SECTION = 'SET_CATEGORY_BY_SECTION'
export const GET_CATEGORY_BY_SECTION = 'GET_CATEGORY_BY_SECTION'
export const GET_DESCRIPTION_CATEGORY = 'GET_DESCRIPTION_CATEGORY'
export const SET_DESCRIPTION_CATEGORY = 'SET_DESCRIPTION_CATEGORY'

export enum CATEGORY_RESPONSE {
   CREATED = 'CREATED',
   EDITED = 'EDITED',
   REMOVED = 'REMOVED'
}
