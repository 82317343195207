import jwtDecode from 'jwt-decode'
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import {generateErrorToast, generateSuccessToast} from '../../common'
import { i18n } from '../../common/config'
import { showToastAction } from '../toasts'
import { userActions } from './action'
import { ApiUserService } from './api.service'
import { getUserSelector } from './selectors'
import { TInitialState, TUserPostResponse } from './types'

function* loginWorker({ payload }: any) {
   try {
      const response: TUserPostResponse = yield call([ApiUserService, ApiUserService.login], payload)

      const data = response.data.data

      if (!data) {
         return
      }

      const { accessToken, refreshToken } = data

      const { login, exp, name, secondName, role, image, permissions } = jwtDecode<TInitialState>(accessToken)

      const user = {
         accessToken,
         refreshToken,
         login,
         exp,
         name,
         secondName,
         role,
         image,
         permissions
      }

      yield put(userActions.auth(user))
      yield put(showToastAction.request(generateSuccessToast(i18n.t('authorization.successful') as string)));
   } catch (e) {
      yield put(showToastAction.request(generateErrorToast(i18n.t('authorization.error') as string)));
   }
}

function* connectWorker({ payload }: any) {
   const { data, callback } = payload

   const EXPIRATION_DELAY = 1 // Minutes

   const isExpired = (new Date().getTime() - data.exp * 1000) / 1000 / 60 > - EXPIRATION_DELAY

   if (!isExpired) {
      yield call(callback)
      return
   }
   try {
      const response: TUserPostResponse = yield call([ApiUserService, ApiUserService.refresh_token], {
         refreshToken: data.refreshToken
      })
      if (response.status !== 200) yield put(userActions.logoutRequest())

      const { accessToken, refreshToken } = response.data.data

      const { login, exp, name, secondName, image, role, permissions } = jwtDecode<TInitialState>(accessToken)

      const user = {
         accessToken,
         refreshToken,
         login,
         exp,
         name,
         secondName,
         image,
         role,
         permissions
      }

      yield put(userActions.auth(user))
   } catch (e) {
      yield put(userActions.logoutRequest())
   }
}

function* logoutWather() {
   const token: string = yield call(getAccessToken)

   try {
      yield call([ApiUserService, ApiUserService.logout], { token })
   } catch (e) {}
   yield put(userActions.logout())
}

export function* userWatcher() {
   yield takeLatest(userActions.request, loginWorker)
   yield takeLatest(userActions.connect, connectWorker)
   yield takeEvery(userActions.logoutRequest, logoutWather)
}

export function* getAccessToken(): Generator<unknown, string, TInitialState> {
   const { accessToken } = yield select(getUserSelector)
   return ('Barear ' + accessToken) as string
}
