import { AxiosResponse } from 'axios';
import { TResponse } from '../types';
import {
	TCreateBrandRequestPayload,
	TEditBrandRequestPayload,
	TGetBrandRequestPayload,
	TGetBrandsRequestPayload,
	TRemoveBrandRequestPayload
} from './types';
import { BRAND_URL } from "./config";
import { HttpService } from '../http.service';

export class ApiBrandServices extends HttpService {
	static getBrands(
		{
			token,
			limit = 10,
			page = 0,
			order = '',
			sortBy = '',
			lang = 'ua'
		}: TGetBrandsRequestPayload): Promise<AxiosResponse<TResponse>> {
		return this.request({
			url: BRAND_URL.getBrands,
			method: 'GET',
			headers: {
				Authorization: token
			},
			params: {
				limit,
				skip: limit * page,
				order,
				sortBy,
				lang
			}
		})
	}

	static removeBrand({ token, _id }: TRemoveBrandRequestPayload): Promise<AxiosResponse<TResponse>> {
		return this.request({
			url: `${BRAND_URL.removeBrand}/${_id}`,
			method: 'DELETE',
			headers: {
				Authorization: token
			}
		})
	}

	static editBrand({ token, _id, data }: TEditBrandRequestPayload): Promise<AxiosResponse<TResponse>> {
		return this.request({
			url: `${BRAND_URL.editBrand}/${_id}`,
			method: 'PATCH',
			headers: {
				Authorization: token
			},
			data
		})
	}

	static createBrand({ token, data }: TCreateBrandRequestPayload): Promise<AxiosResponse<TResponse>> {
		return this.request({
			url: BRAND_URL.createBrand,
			method: 'POST',
			headers: {
				Authorization: token
			},
			data
		})
	}

	static getBrand({ token, _id}: TGetBrandRequestPayload): Promise<AxiosResponse<TResponse>> {
		return this.request({
			url: `${BRAND_URL.getBrand}/${_id}`,
			method: 'GET',
			headers: {
				Authorization: token
			}
		})
	}
}
