import { FC } from "react";
import { Route, Routes } from "react-router-dom";

import routes from "./routes";

const AuthRoutes: FC = () => {
  return (
    <Routes>
      <Route path={routes.orders.path} element={<routes.orders.component />} />

      <Route
        path={routes.profileOrder.path}
        element={<routes.profileOrder.component />}
      />

      <Route
        path={routes.ordersArchive.path}
        element={<routes.ordersArchive.component />}
      />

      <Route
        path={routes.productsManagement.path}
        element={<routes.productsManagement.component />}
      />

      <Route
        path={routes.editProduct.path}
        element={<routes.editProduct.component />}
      />

      <Route
        path={routes.availabilityNotification.path}
        element={<routes.availabilityNotification.component />}
      />

      <Route
        path={routes.createProduct.path}
        element={<routes.createProduct.component />}
      />

      <Route
        path={routes.statistics.path}
        element={<routes.statistics.component />}
      />

      <Route
        path={routes.feedback.path}
        element={<routes.feedback.component />}
      />

      <Route
        path={routes.feedbackProfile.path}
        element={<routes.feedbackProfile.component />}
      />

      <Route
        path={routes.bonusSystem.path}
        element={<routes.bonusSystem.component />}
      />

      <Route
        path={routes.catalog.path}
        element={<routes.catalog.component />}
      />

      {/* <Route
        path={routes.catalogSections.path}
        element={<routes.catalogSections.component />}
      /> */}
      <Route
        path={routes.catalogCategories.path}
        element={<routes.catalogCategories.component />}
      />
      <Route
        path={routes.catalogSubcategories.path}
        element={<routes.catalogSubcategories.component />}
      />

      <Route
        path={routes.catalogBrand.path}
        element={<routes.catalogBrand.component />}
      />

      <Route
        path={routes.delivery.path}
        element={<routes.delivery.component />}
      />

      {/* <Route path={routes.shop.path} element={<routes.shop.component />} /> */}

      <Route
        path={routes.support.path}
        element={<routes.support.component />}
      />

      <Route path={routes.banner.path} element={<routes.banner.component />} />

      <Route path={routes.role.path} element={<routes.role.component />} />

      <Route
        path={routes.editRole.path}
        element={<routes.editRole.component />}
      />

      <Route
        path={routes.settings.path}
        element={<routes.settings.component />}
      />

      <Route
        path={routes.customers.path}
        element={<routes.customers.component />}
      />

      <Route
        path={routes.createRole.path}
        element={<routes.createRole.component />}
      />

      <Route
        path={routes.customerProfile.path}
        element={<routes.customerProfile.component />}
      />

      <Route
        path={routes.editClientHistory.path}
        element={<routes.editClientHistory.component />}
      />

      <Route
        path={routes.notFoundedAuth.path}
        element={<routes.notFoundedAuth.component />}
      />

      <Route
        path={routes.notAvailable.path}
        element={<routes.notAvailable.component />}
      />
    </Routes>
  );
};

export default AuthRoutes;
