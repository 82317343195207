import styled from 'styled-components'
import { TStyles } from './types'
import { colors } from "../../styles";

export const Styles = styled.div<TStyles>`
   width: 318px;

   .css-1s2u09g-control,
   .css-1pahdxg-control {
      height: 45px;
      border-radius: 8px;
      border-color: ${({ isValid }) => (isValid ? colors.gray : colors.red)};
   }

   .css-1pahdxg-control {
      border-color: ${({ color }) => `${color}`} !important;
      box-shadow: 0 0 0 1px ${({ color }) => `${color}`} ;
   }

   .css-1okebmr-indicatorSeparator {
      display: none;
   }

  @media (max-width: 1600px) {
    width: 270px;
  }
`
