import { call, put, takeLatest } from 'redux-saga/effects';

import { TDataWrapper, TResponse } from '../types';
import { getAccessToken } from '../user';

import { productActions } from './actions';
import { ApiProductService } from './api.service';
import {
   TCreateProductPayload,
   TGetProductPayload,
   TGetProductsPayload,
   TProductResponse,
   TCreateGroupPayload,
   TGetGroupsPayload,
   TEditProductPayload,
   TEditGroupPayload,
   TRemoveProductRequestPayload,
   TCreateVariationBulkPayload,
   TCreateVariationValuePayload,
   TRemoveVariationRequestPayload,
   TCreatePhotoProductRequestPayload,
   TGetProductsGroupPayload,
   TGetDescriptionPayload, TGetVariationValueRequestPayload,
} from './types';
import { RESPONSE } from './consts';
import { showToastAction } from "../toasts";
import { generateErrorToast, generateSuccessToast } from "../../common";
import { i18n } from "../../common/config";

function* getProductsWorker({ payload }: TDataWrapper<TGetProductsPayload>) {
   yield put(productActions.setProductState({ loading: true }))

   const token: string = yield call(getAccessToken)

   try {
      const response: TResponse = yield call([ApiProductService, ApiProductService.getProducts], { ...payload, token })
      if (response.data) yield put(productActions.setProducts(response.data))
   } catch (e) {
      yield put(productActions.setProducts({data: [], meta: null}))
      yield put(showToastAction.request(generateErrorToast(i18n.t("error.getting.products") as string)));
   }
   yield put(productActions.setProductState({ loading: false }))
}

function* getProductsGroupWorker({ payload }: TDataWrapper<TGetProductsGroupPayload>) {
   yield put(productActions.setProductState({ loading: true }))
   const token: string = yield call(getAccessToken)

   try {
      const response: TResponse = yield call([ApiProductService, ApiProductService.getProductsGroup], { ...payload, token })
      if (response.data) yield put(productActions.setProductsGroup(response.data))
   } catch (e) {
      yield put(productActions.setProductsGroup(null))
   }
   yield put(productActions.setProductState({ loading: false }))
}

function* getProductWorker({payload}: TDataWrapper<TGetProductPayload>) {
   yield put(productActions.setProductState({loading: true}))

   const {_id} = payload
   const token: string = yield call(getAccessToken)

   try {
      const response: TResponse = yield call([ApiProductService, ApiProductService.getProduct], {_id, token})
      if (response.data.data) {
         yield put(productActions.setProduct({...response.data.data}))
         yield put(productActions.setProductState({loading: false}))
      } else {
         yield put(productActions.setProductState({loading: false, response: RESPONSE.NOT_FOUNDED as TProductResponse}))
      }
   } catch (e) {
      yield put(productActions.setProductState({loading: false, response: RESPONSE.NOT_FOUNDED as TProductResponse}))
      yield put(showToastAction.request(generateErrorToast(i18n.t("error.getting.product") as string)));
   }
}

function* getVariationValueWorker({payload}: TDataWrapper<TGetVariationValueRequestPayload>) {
   yield put(productActions.setProductState({loading: true}))

   const {_id} = payload
   const token: string = yield call(getAccessToken)

   try {
      const response: TResponse = yield call([ApiProductService, ApiProductService.getVariationValue], {_id, token})
      if (response.data.data) {
         yield put(productActions.setVariationValue({...response.data.data}))
         yield put(productActions.setProductState({loading: false}))
      } else {
         yield put(productActions.setProductState({loading: false, response: RESPONSE.NOT_FOUNDED as TProductResponse}))
      }
   } catch (e) {
      yield put(productActions.setProductState({loading: false, response: RESPONSE.NOT_FOUNDED as TProductResponse}))
   }
}


function* getDescriptionWorker({payload}: TDataWrapper<TGetDescriptionPayload>) {
   yield put(productActions.setProductState({loading: true}))

   const {_id} = payload
   const token: string = yield call(getAccessToken)

   try {
      const response: TResponse = yield call([ApiProductService, ApiProductService.getDescription], {_id, token})
      if (response.data) {
         yield put(productActions.setDescription(response.data))
         yield put(productActions.setProductState({loading: false}))
      } else {
         yield put(productActions.setProductState({loading: false }))
      }
   } catch (e) {
      yield put(productActions.setProductState({loading: false }))
   }
}

function* getGroupWorker({payload}: TDataWrapper<TGetGroupsPayload>) {
   yield put(productActions.setProductState({loading: true}))

   const {_id} = payload
   const token: string = yield call(getAccessToken)

   try {
      const response: TResponse = yield call([ApiProductService, ApiProductService.getGroup], {_id, token})
      if (response.data.data) {
         yield put(productActions.setGroup({...response.data.data}))
         yield put(productActions.setProductState({loading: false}))
      } else {
         yield put(productActions.setProductState({loading: false, response: RESPONSE.NOT_FOUNDED as TProductResponse}))
      }
   } catch (e) {
      yield put(productActions.setProductState({loading: false, response: RESPONSE.NOT_FOUNDED as TProductResponse}))
   }
}

function* createProductWorker({ payload }: TDataWrapper<TCreateProductPayload>) {
   yield put(productActions.setProductState({ loading: true }))

   const token: string = yield call(getAccessToken)

   try {
      const response: TResponse = yield call([ApiProductService, ApiProductService.createProduct], { ...payload, token })
      if (response.data.data){
         yield put(productActions.setProduct({ ...response.data.data }))
         yield put(productActions.setProductState({loading: false}))
         yield put(
           showToastAction.request(
             generateSuccessToast(i18n.t("product.was.created.successfully") as string),
           ),
         )
      }
   } catch (e) {
      yield put(productActions.setProduct(null))
      yield put(
        showToastAction.request(
          generateErrorToast(i18n.t("product.creating.error") as string),
        ),
      )
   }

   yield put(productActions.setProductState({ loading: false, response: RESPONSE.CREATED as TProductResponse }))
}

function* createVariationValueWorker({ payload }: TDataWrapper<TCreateVariationValuePayload>) {

   const token: string = yield call(getAccessToken)

   try {
      const response: TResponse = yield call([ApiProductService, ApiProductService.createVariationValue], { ...payload, token })
      if (response.data){
         yield put(productActions.setVariationsValue(response.data))
         yield put(productActions.setProductState({response: RESPONSE.CREATED_VARIATION_VALUE as TProductResponse}))

      }
   } catch (e) {
      yield put(productActions.setVariationsValue(null))
   }

   yield put(productActions.setProductState({ response: RESPONSE.CREATED_VARIATION_VALUE as TProductResponse}))
}

function* createVariationBulkWorker({ payload }: TDataWrapper<TCreateVariationBulkPayload>) {
   yield put(productActions.setProductState({ loading: true }))

   const token: string = yield call(getAccessToken)

   try {
      const response: TResponse = yield call([ApiProductService, ApiProductService.createVariationBulk], { ...payload, token })
      if (response.data) yield put(productActions.setVariationsBulk(response.data))
   } catch (e) {
      yield put(productActions.setVariationsBulk(null))
   }

   yield put(productActions.setProductState({ loading: false, response: RESPONSE.CREATED_VARIATION_BULK as TProductResponse}))
}

function* createPhotoProductWorker({ payload }: TDataWrapper<TCreatePhotoProductRequestPayload>) {
   const token: string = yield call(getAccessToken)

   try {
      const response: TResponse = yield call([ApiProductService, ApiProductService.createPhotoProduct], { ...payload, token })
      if (response.data) yield put(productActions.setPhotoProduct(response.data))
   } catch (e) {
      yield put(productActions.setPhotoProduct(null))
   }

   yield put(productActions.setProductState({response: RESPONSE.CREATED_PHOTO as TProductResponse}))
}

function* getGroupsWorker({ payload }: TDataWrapper<TGetGroupsPayload>) {
   const token: string = yield call(getAccessToken)

   try {
      const response: TResponse = yield call([ApiProductService, ApiProductService.getGroups], { ...payload, token })

      if (response.data) yield put(productActions.setGroups(response.data))
   } catch (e) {
      yield put(productActions.setGroups(null))
   }
}

function* createGroupWorker({ payload }: TDataWrapper<TCreateGroupPayload>) {
   yield put(productActions.setProductState({ loading: true }))

   const token: string = yield call(getAccessToken)

   try {
      const response: TResponse = yield call([ApiProductService, ApiProductService.createGroup], { ...payload, token })
      if (response.data.data) yield put(productActions.setGroup({...response.data.data}))
      yield put(
        showToastAction.request(
          generateSuccessToast(i18n.t("group.was.created.successfully") as string),
        ),
      )
   } catch (e) {
      yield put(productActions.setGroup(null))
   }

   yield put(productActions.setProductState({ loading: false, response: RESPONSE.CREATED_GROUP as TProductResponse }))
}

function* editProductWorker({ payload }: TDataWrapper<TEditProductPayload>) {
   const { _id, data } = payload
   const token: string = yield call(getAccessToken)

   try {
      yield call([ApiProductService, ApiProductService.editProduct], { _id, data, token })
      yield put(showToastAction.request(generateSuccessToast(i18n.t("product.updated.successfully") as string)));
   } catch (e) {
      yield put(showToastAction.request(generateErrorToast(i18n.t("failed.to.update.product") as string)));
   }
}

function* editVariationWorker({ payload }: TDataWrapper<TEditProductPayload>) {
   const { _id, data } = payload
   const token: string = yield call(getAccessToken)

   try {
      yield call([ApiProductService, ApiProductService.editVariation], { _id, data, token })
   } catch (e) {}
}

function* editVariationValueWorker({ payload }: TDataWrapper<TEditProductPayload>) {
   const { _id, data } = payload
   const token: string = yield call(getAccessToken)

   try {
      yield call([ApiProductService, ApiProductService.editVariationValue], { _id, data, token })
   } catch (e) {}
}

function* editGroupWorker({ payload }: TDataWrapper<TEditGroupPayload>) {
   const { _id, data } = payload
   const token: string = yield call(getAccessToken)

   try {
      yield call([ApiProductService, ApiProductService.editGroup], { _id, data, token })
   } catch (e) {}

   yield put(productActions.setProductState({ response: RESPONSE.EDITED_GROUP as TProductResponse }))
}

function* removeProductWorker({ payload }: TDataWrapper<TRemoveProductRequestPayload>) {
   yield put(productActions.setProductState({ loading: true }))
   const token: string = yield call(getAccessToken)

   const { _id } = payload

   try {
      yield call([ApiProductService, ApiProductService.removeProduct], { token, _id })
      yield put(productActions.setProducts({ data: [], meta: { needReload: true } }))
      yield put(showToastAction.request(generateSuccessToast(i18n.t("product.removed.successfully") as string)));
   } catch (e) {
      yield put(showToastAction.request(generateErrorToast(i18n.t("failed.to.remove.product") as string)));
   }

   yield put(productActions.setProductState({ loading: false, response: RESPONSE.REMOVED as TProductResponse }))
}

function* removeVariationWorker({ payload }: TDataWrapper<TRemoveVariationRequestPayload>) {
   yield put(productActions.setProductState({ loading: true }))

   const { _id } = payload
   const token: string = yield call(getAccessToken)

   try {
      yield call([ApiProductService, ApiProductService.removeVariation], { token, _id })
   } catch (e) {}

   yield put(productActions.setProductState({ loading: false }))
}

export function* productWatcher() {
   yield takeLatest(productActions.getProducts, getProductsWorker)
   yield takeLatest(productActions.createProduct, createProductWorker)
   yield takeLatest(productActions.getProduct, getProductWorker)
   yield takeLatest(productActions.createGroup, createGroupWorker)
   yield takeLatest(productActions.getGroups, getGroupsWorker)
   yield takeLatest(productActions.editProduct, editProductWorker)
   yield takeLatest(productActions.editGroup, editGroupWorker )
   yield takeLatest(productActions.removeProduct, removeProductWorker)
   yield takeLatest(productActions.createVariationBulk, createVariationBulkWorker)
   yield takeLatest(productActions.getGroup, getGroupWorker)
   yield takeLatest(productActions.editVariation, editVariationWorker)
   yield takeLatest(productActions.editVariationValue, editVariationValueWorker)
   yield takeLatest(productActions.createVariationValue, createVariationValueWorker)
   yield takeLatest(productActions.removeVariation, removeVariationWorker)
   yield takeLatest(productActions.createPhotoProduct, createPhotoProductWorker)
   yield takeLatest(productActions.getProductsGroup, getProductsGroupWorker)
   yield takeLatest(productActions.getDescription, getDescriptionWorker)
   yield takeLatest(productActions.getVariationValue, getVariationValueWorker)
}
