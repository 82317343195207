import { all } from 'redux-saga/effects'

import { toastsSaga } from "./toasts";
import { adminWatcher } from "./admin";
import { shopWatcher } from "./shop";
import { supportWatcher } from "./support";
import { bannerWatcher } from "./banner";
import { productWatcher } from "./product";
import { orderWatcher } from "./order";
import { bonusWatcher } from "./bonus";
import { categoryWatcher } from "./category";
import { sectionWatcher } from "./section";
import { clientWatcher } from "./client";
import { availabilityWatcher } from "./availabilityNottification";
import { feedbackWatcher } from "./feedback";
import { settingWatcher } from "./setting";
import { userWatcher } from "./user";
import { subcategoryWatcher } from './subCategory';
import { brandWatcher } from "./brand";
import { deliveryWatcher } from "./delivery";

function* rootSaga() {
	yield all([
		toastsSaga(),
		adminWatcher(),
		shopWatcher(),
		categoryWatcher(),
		supportWatcher(),
		bonusWatcher(),
		bannerWatcher(),
		clientWatcher(),
		productWatcher(),
		orderWatcher(),
		sectionWatcher(),
		availabilityWatcher(),
		feedbackWatcher(),
		settingWatcher(),
		userWatcher(),
		subcategoryWatcher(),
		brandWatcher(),
		deliveryWatcher(),
	])
}

export default rootSaga
