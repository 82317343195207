import { createReducer } from '@reduxjs/toolkit'
import {availabilityActions} from './actions'
import { TInitialState } from './types'

const InitialState: TInitialState = {
   availabilitys: {
      data: [],
      meta: null
   },
   loading: false,
   response: null
}

const reducer = createReducer<TInitialState>(InitialState, (builder) => {
   builder
      .addCase(availabilityActions.setAvailabilitys, (state, { payload }) => ({ ...state, availabilitys: payload }))
      .addCase(availabilityActions.setAvailabilityState, (state, { payload }) => ({ ...state, response: null, ...payload }))
})

export default reducer
