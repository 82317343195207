import { createAction } from '@reduxjs/toolkit'
import {
   CREATE_CATEGORY,
   EDIT_CATEGORY,
   GET_CATEGORIES,
   REMOVE_CATEGORIES,
   SET_CATEGORIES,
   SET_CATEGORY_STATE,
   SET_CATEGORY_BY_SECTION,
   GET_CATEGORY_BY_SECTION,
   GET_DESCRIPTION_CATEGORY,
   SET_DESCRIPTION_CATEGORY
} from './consts'
import {
   TCreateCategoryPayload,
   TEditCategoryPayload,
   TGetCategoriesPayload,
   TRemoveCategoriesPayload,
   TSetCategoryState
} from './types'

export const categoryActions = {
   getCategories: createAction(GET_CATEGORIES, (payload: TGetCategoriesPayload) => ({ payload })),
   setCategories: createAction(SET_CATEGORIES, (payload) => ({ payload })),
   setCategoryBySection: createAction(SET_CATEGORY_BY_SECTION, (payload) => ({ payload })),
   getCategoryBySection: createAction(GET_CATEGORY_BY_SECTION, (payload) => ({payload})),
   setCategoriesState: createAction(SET_CATEGORY_STATE, (payload: TSetCategoryState) => ({ payload })),
   createCategory: createAction(CREATE_CATEGORY, (payload: TCreateCategoryPayload) => ({ payload })),
   editCategory: createAction(EDIT_CATEGORY, (payload: TEditCategoryPayload) => ({ payload })),
   removeCategories: createAction(REMOVE_CATEGORIES, (payload: TRemoveCategoriesPayload) => ({ payload })),
   getDescriptionCategory: createAction(GET_DESCRIPTION_CATEGORY, (payload) => ({payload})),
   setDescriptionCategory: createAction(SET_DESCRIPTION_CATEGORY, (payload) => ({payload})),
}
