import React from 'react';
import {useTranslation} from 'react-i18next';

import {
	Assets,
	FlexContainer,
	Input
} from "../../../../common";

import {ColorPickerProduct} from '../ColorPickerProduct';

import {
	ColorPickerBlock, Delete, Image,
	Label,
	Name,
	VariationBlock,
	VariationsHeader,
	VariationsMain
} from "../../styled";
import {TValues, TVariationsData} from "../../types";

const VariationValue = (
	{
		variationsData,
		setVariationData,
		formVariations,
		EventsVariations,
		variationTitle,
		selectColor,
	}: any) => {
	const { t } = useTranslation();

	const removeVariationValue = (mainId:number, valueId:number) => {
		let temp = variationTitle.values.filter((el:TValues) => el.order !== valueId)
		setVariationData(variationsData.map((el:TVariationsData) => el.order == mainId ? {...el, values:temp}: el ))
	}

	return (
		<VariationBlock className={'variationValue_form_container'} key={formVariations.order}>
			<VariationsHeader>
				<Name>{t('value.for.variation')}</Name>
			</VariationsHeader>
			<VariationsMain>
				<FlexContainer gap={'62px'} style={{padding: '10px 0'}} align={'center'}>
					<Input
						name="title"
						placeholder={t('enter.title')}
						value={formVariations.title[0].title}
						onChange={(event) => EventsVariations.inputChangeHandler(event, variationTitle.order, formVariations.order)}
					/>
					{
						variationTitle.type === 'color' ?
							<ColorPickerBlock>
								<Label>{t('display.type')}</Label>
								<ColorPickerProduct
									selectColor={selectColor}
									formVariations={formVariations}
									variationTitle={variationTitle}
									EventsVariations={EventsVariations}
								/>
							</ColorPickerBlock> : ''
					}
					<Delete onClick={() => removeVariationValue(variationTitle.order, formVariations.order)}><Image src={Assets.CROSS}/>{t('delete')}</Delete>
				</FlexContainer>
			</VariationsMain>
		</VariationBlock>
	);
};

export default VariationValue;