import { FC } from "react";
import { AsyncPaginate } from "react-select-async-paginate";

import { Styles } from "./styles";
import { TPaginateDropDownList } from "./types";
import { useTypedSelector } from "../../hooks";
import { getMainSettingSelector } from "../../../store";

const PaginateDropDownList: FC<TPaginateDropDownList> = ({
  loadOptions,
  onChange,
  placeholder,
  value = { value: "" },
  isValid = true,
  onBlur,
  isClearable = false,
}) => {
  const { logo } = useTypedSelector(getMainSettingSelector);
  return (
    <Styles color={logo?.data?.mainColor} isValid={isValid}>
      <AsyncPaginate
        isClearable={isClearable}
        loadOptions={loadOptions}
        onChange={onChange}
        placeholder={placeholder}
        value={value.value && value}
        additional={{
          page: 0,
        }}
        onBlur={onBlur && onBlur}
      />
    </Styles>
  );
};

export default PaginateDropDownList;
