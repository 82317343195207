import React, { SyntheticEvent, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import {
  DropDownList,
  FlexContainer,
  useTypedSelector,
} from "../../../../common";

import {
  brandActions,
  categoryActions,
  getMainBrandSelector,
  getMainCategorySelector,
  //   getMainSectionSelector,
  getMainSubcategorySelector,
  //   sectionActions,
  subcategoryActions,
} from "../../../../store";
import { getLocale } from "../../../../types";

const Catalog = ({ form, setForm }: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const local = getLocale();

  //   const { sections } = useTypedSelector(getMainSectionSelector);
  const { brands } = useTypedSelector(getMainBrandSelector);
  const { categories } = useTypedSelector(getMainCategorySelector);
  const { subCategoriesByCategory } = useTypedSelector(
    getMainSubcategorySelector
  );

  //   const sectionOptions = useMemo(
  //     () =>
  //       sections.data.map((section) => ({
  //         name: section.title,
  //         value: section._id,
  //       })),
  //     [sections]
  //   );
  const categoryOptions = useMemo(
    () =>
      categories?.data?.map((category: any) => ({
        name: category?.title,
        value: category?._id,
      })),
    [categories]
  );
  const brandOptions = () =>
    brands?.data?.map((brand: any) => ({
      name: brand?.title,
      value: brand?._id,
    }));

  const subCategoryOption = useMemo(
    () =>
      subCategoriesByCategory.data.map((subCategory: any) => ({
        name: subCategory.title,
        value: subCategory._id,
      })),
    [subCategoriesByCategory]
  );

  const Events = {
    inputChangeHandler: (e: SyntheticEvent) => {
      const { name, value } = e.target as HTMLInputElement;
      setForm((props: any) => ({ ...props, [name]: value }));
    },
  };

  useEffect(() => {
    dispatch(categoryActions.getCategories({ lang: local, limit: 300 }));
  }, []);

  useEffect(() => {
    if (form.category) {
      dispatch(
        subcategoryActions.getSubcategoriesByCategory({
          _id: form.category,
          lang: local,
        })
      );
    }
  }, [form.category]);

  useEffect(() => {
    dispatch(brandActions.getBrands({}));
    // dispatch(sectionActions.getSections({limit: 30, lang: local}))
  }, [form.subcategory]);

  return (
    <FlexContainer
      direction="column"
      gap="30px"
      style={{ marginTop: "8px", padding: "24px 0 24px 30px" }}
    >
      {/* <DropDownList
        name="section"
        label={t("sections")}
        options={sectionOptions}
        value={form.section}
        placeholder={t("choose.section")}
        width="100%"
        onChange={Events.inputChangeHandler}
      /> */}
      <DropDownList
        name="category"
        label={t("category")}
        options={categoryOptions}
        value={form.category}
        placeholder={t("choose.category")}
        width="100%"
        onChange={Events.inputChangeHandler}
      />
      <DropDownList
        name="subcategory"
        label={t("subcategory")}
        options={subCategoryOption}
        value={form.subcategory}
        placeholder={t("choose.subcategory")}
        width="100%"
        onChange={Events.inputChangeHandler}
      />
      <DropDownList
        name="brand"
        label={t("brand")}
        options={brandOptions()}
        value={form.brand}
        placeholder={t("choose.brand")}
        width="100%"
        onChange={Events.inputChangeHandler}
      />
    </FlexContainer>
  );
};

export default Catalog;
