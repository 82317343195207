import React from "react";
import { useTranslation } from "react-i18next";

import { useTypedSelector } from "../../../../common";
import { getMainSettingSelector } from "../../../../store";

import { SubBarBlock, Button } from "./styled";

const ProductSubBar = ({ setActivePage, activePage }: any) => {
  const { t } = useTranslation();

  const { logo } = useTypedSelector(getMainSettingSelector);

  const menuSections = [
    {
      title: t("main.data"),
      type: "mainData",
      active: false,
    },
    {
      title: t("characteristics"),
      type: "characteristics",
      active: false,
    },
    // {
    // 	title: t('variations'),
    // 	type: 'variations',
    // 	active: false
    // }
  ];

  return (
    <SubBarBlock>
      {menuSections.map((section, key) => (
        <Button
          color={logo?.data?.mainColor}
          key={key}
          value={activePage}
          onClick={() => {
            setActivePage(section.type);
          }}
          active={section.type === activePage && true}
        >
          {section.title}
        </Button>
      ))}
    </SubBarBlock>
  );
};

export default ProductSubBar;
